import { CardUsageScope } from 'lib/types';

import { BrandNameType } from './types';

export const cardNumberId = 'cardNumberCont';
export const cardExpiryId = 'cardExpiryCont';
export const cardCvcId = 'cardCvcCont';
export const cardCvcOnlyId = 'cardCvcOnlyCont';

/**
 * convert MasterCard and AMEX
 */
export const BrandName: BrandNameType = {
  MasterCard: 'Mastercard',
  'American Express': 'Amex',
};

export const B2BSaveCardSelectionProps = {
  options: [
    {
      text: 'My user profile',
      value: CardUsageScope.delegate,
    },
    {
      text: 'All users',
      value: CardUsageScope.organisation,
    },
  ],
  label: 'Save card for...',
  showSearch: false,
  testId: 'b2bSaveCardSelection',
};
