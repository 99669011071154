import 'whatwg-fetch';

import { getLimepayApiHost } from 'lib/env';
import { ApiError } from 'lib/errorMessages';
import { logRocketTrack } from 'lib/logrocket';
import { Params } from 'lib/toParams';
import { nanoid } from 'nanoid';

import {
  Config,
  CreateAppleWebPaymentSessionRequest,
  CreateOrGetCustomerRequest,
  ErrorResponse,
  GetCustomerResponse,
  QueryCardPaymentSourcesResponse,
  SendCustomerVerification,
  SendCustomerVerificationRequest,
  VerifyCustomer,
  VerifyCustomerRequest,
} from './apiTypes';

export const sessionId = nanoid();

export type DefaultHeaders = Partial<{
  'Content-Type': string;
  Accept: string;
  'Session-Correlation-Id': string;
  'Checkout-Version': string;
  Platform: string;
  'Platform-Version': string;
  'Platform-Plugin-Version': string;
  'Logrocket-Session-Url': string;
}>;

export let defaultHeaders: DefaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Session-Correlation-Id': sessionId,
};

export const setDefaultHeaders = (params: Params) => {
  defaultHeaders = {
    ...defaultHeaders,
    'Checkout-Version': params.version,
    Platform: params.platform,
    'Platform-Version': params.platformVersion,
    'Platform-Plugin-Version': params.platformPluginVersion,
  };
};

export const handleResponse = async <T = any>(
  res: Response,
  getApiErrorMessage?: (error: ErrorResponse) => string,
): Promise<T> => {
  const requestId = res.headers.get('request-id') || undefined;
  logRocketTrack({
    requestId,
  });

  const body = await res.text();

  if (res.ok) {
    // success response
    return body.length ? JSON.parse(body) : body;
  }

  // error response
  let payload: ErrorResponse | null = null;

  try {
    // attempt to parse json error
    payload = JSON.parse(body);
  } catch (error) {}

  if (payload) {
    logRocketTrack({
      statusCode: payload.statusCode,
      errorCode: payload.errorCode,
      message: payload.message,
      tracer: payload.tracer,
    });
    throw new ApiError(getApiErrorMessage?.(payload) ?? payload.message, payload);
  } else {
    throw Error(body);
  }
};

export const getCheckoutConfig = async (publicKey: string): Promise<Config> => {
  const apiHost = await getLimepayApiHost();

  return fetch(`${apiHost}/config/checkout`, {
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);
};

/**
 * only return cards with isDeleted === false
 */
export const queryCardPaymentSources = (
  host: string,
  customerId: string,
  customerIdToken: string,
): Promise<QueryCardPaymentSourcesResponse> =>
  fetch(`${host}/customers/${customerId}/payments/sources/card?sort=-updatedAt`, {
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
    }),
  })
    .then(handleResponse)
    .then((response: QueryCardPaymentSourcesResponse) => response.filter((card) => card.isDeleted === false));

export const createOrGetCustomer = (
  host: string,
  publicKey: string,
  payload: CreateOrGetCustomerRequest,
): Promise<string> =>
  fetch(`${host}/customers`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const getCustomer = (host: string, customerId: string, customerIdToken: string): Promise<GetCustomerResponse> =>
  fetch(`${host}/customers/${customerId}`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
    }),
  }).then(handleResponse);

export const sendCustomerVerification = (
  host: string,
  publicKey: string,
  customerId: string,
  payload: SendCustomerVerificationRequest,
): Promise<SendCustomerVerification> =>
  fetch(`${host}/customers/${customerId}/verification`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const verifyCustomer = (
  host: string,
  publicKey: string,
  payload: VerifyCustomerRequest,
): Promise<VerifyCustomer> =>
  fetch(`${host}/customers/${payload.customerId}/verification/codes`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const createAppleWebPaymentSession = async (
  host: string,
  publicKey: string,
  merchantId: string,
  payload: CreateAppleWebPaymentSessionRequest,
): Promise<any> =>
  fetch(`${host}/payments/apple/web-payment-session/${merchantId}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);
