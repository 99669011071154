import { PaymentData, PaymentSource } from './types';

export interface Message {
  service: 'Limepay';
  elementId: string;
}

/**
 * as customer cannot change first instalment, removed below from v2
 * FirstPaymentAmountChanged = 'limepay_first_payment_amount_changed'
 */
export enum EventName {
  Ready = 'limepay_checkout_ready_event',
  Init = 'limepay_checkout_init_event',
  Submit = 'limepay_checkout_submit_event',
  ToggledPaymentType = 'limepay_toggled_payment_type',
  PaymentPlanTermsAccepted = 'limepay_payment_plan_terms_accepted',
}

export interface EventMessage extends Message {
  event: {
    eventName: EventName;
    eventData?: any;
  };
}

export interface ErrorMessage extends Message {
  error: {
    message: string;
  };
}

export interface PaymentTokenMessage extends Message {
  paymentToken: string;
  paymentData: PaymentData;
}

export interface PaymentSourceMessage extends Message {
  paymentSource: PaymentSource;
}

export interface ResizeMessage extends Message {
  windowHeight: number;
}

export interface ApplePayMessage extends Message {
  applePayMessage:
    | {
        event: 'begin';
        payload: ApplePayJS.ApplePayPaymentRequest;
      }
    | {
        event: 'onValidateMerchant';
        payload: {
          validationURL: string;
          merchantDomain: string;
        };
      }
    | {
        event: 'completeMerchantValidation';
        payload: any;
      }
    | {
        event: 'onPaymentAuthorized';
        payload: ApplePayJS.ApplePayPaymentToken;
      }
    | {
        event: 'completePayment';
        payload: ApplePayJS.ApplePayPaymentAuthorizationResult;
      }
    | {
        event: 'abort';
        payload: string;
      };
}

export type AnyMessage =
  | EventMessage
  | ErrorMessage
  | PaymentTokenMessage
  | PaymentSourceMessage
  | ResizeMessage
  | ApplePayMessage;

export const toEventMessage = (eventName: EventName, elementId: string, eventData?: any): EventMessage => ({
  event: { eventName, eventData },
  service: 'Limepay',
  elementId,
});

export const toErrorMessage = (message: string, elementId: string): ErrorMessage => ({
  error: { message },
  service: 'Limepay',
  elementId,
});

export const toPaymentTokenMessage = (
  paymentToken: string,
  paymentData: PaymentData,
  elementId: string,
): PaymentTokenMessage => ({
  paymentToken,
  paymentData,
  service: 'Limepay',
  elementId,
});

export const toPaymentSourceMessage = (paymentSource: PaymentSource, elementId: string): PaymentSourceMessage => ({
  paymentSource,
  service: 'Limepay',
  elementId,
});

export const toResizeMessage = (windowHeight: number, elementId: string): ResizeMessage => ({
  windowHeight,
  service: 'Limepay',
  elementId,
});

export const toApplePayMessage = (
  applePayMessage: ApplePayMessage['applePayMessage'],
  elementId: string,
): ApplePayMessage => ({
  applePayMessage,
  service: 'Limepay',
  elementId,
});
