import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';

export type TriggerCustomerOtpRequest =
  | {
      TriggerB2bProspectOtp: {
        emailAddress: string;
      };
    }
  | {
      TriggerConsumerOtp: {
        emailAddress: string;
      };
    };

export type TriggerCustomerOtpResponse =
  | {
      B2bProspectOtpResult: {};
    }
  | {
      ConsumerOtpResult: {
        phoneNumberLast4: string;
      };
    };

export const triggerCustomerOtp = async (
  token: string,
  payload: TriggerCustomerOtpRequest,
): Promise<TriggerCustomerOtpResponse> =>
  fetch(`${await getAprilApiHost()}/auth/otp`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<TriggerCustomerOtpResponse>(handleResponse);
