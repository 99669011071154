import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';
import { PayPlanVariant } from 'lib/types';

import { Amount, PayPlanParameters } from './types';

export type CreatePayPlanOfferRequest = {
  amount: Amount;
  initialPayment?: number;
  payPlanVariant: PayPlanVariant;
  metadata?: Object;
};

export type CreatePayPlanOfferResponse = {
  offerDetails: {
    merchantId: string;
    customerId: string;
    isCounterOffer: boolean;
    threeDSecureRequired: boolean;
    payPlanParameters?: PayPlanParameters;
    createdAt: string;
  };
  offerVerificationToken: string;
  message?: string;
};

export const createPayPlanOffer = async (
  token: string,
  payload: CreatePayPlanOfferRequest,
): Promise<CreatePayPlanOfferResponse> =>
  fetch(`${await getAprilApiHost()}/payplans/offer`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  })
    .then<CreatePayPlanOfferResponse>(handleResponse)
    .then((response) => {
      const { offerDetails, message } = response;

      if (!offerDetails.payPlanParameters) {
        return Promise.reject(message || "Sorry, we weren't able to determine your eligibility");
      }

      return response;
    });
