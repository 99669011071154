import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';

export type AuthenticateCustomerOtpRequest = {
  AuthenticateB2bProspectOtp: {
    emailAddress: string;
    emailVerificationCode: string;
  };
};

export type AuthenticateCustomerOtpResponse =
  | {
      B2bProspectOtpResultExisting: {
        customToken: string;
      };
    }
  | {
      B2bProspectOtpResultNew: {
        b2bProspectToken: string;
      };
    };

export const authenticateCustomerOtp = async (
  token: string,
  payload: AuthenticateCustomerOtpRequest,
): Promise<AuthenticateCustomerOtpResponse> =>
  fetch(`${await getAprilApiHost()}/auth/otp`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<AuthenticateCustomerOtpResponse>(handleResponse);
