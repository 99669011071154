import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading,
  .neutral-600 {
    color: rgb(var(--lp-colors-neutral-600));
  }
  .cardBackIcon {
    margin-left: 10px;
    margin-right: 6px;
    min-width: 35px;
  }
`;

export const ApplePayButton = styled.button`
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  height: 56px;
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 4px;
`;

export const CardLoading = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--lp-colors-neutral-white));
  opacity: 0.9;
  z-index: 8;
`;

export const Brands = styled.div`
  display: flex;
`;

export const ChevronDown = styled.div`
  margin-left: auto;
  transition: transform 0.3s ease-in-out 0s;
  &.up {
    transform: rotate(180deg);
  }
`;

export const CardInput = styled.div`
  height: 56px;
  width: 100%;
  color: #191e33;
  background: rgb(var(--lp-colors-neutral-white));
  border-bottom: 1px solid rgb(var(--lp-colors-neutral-200));
  position: relative;
  z-index: 4;
  padding: 0 16px;
  display: flex;
  align-items: center;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const CardRow = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 14px;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const PaymentMethodWrapper = styled.div<{ isOpen: boolean }>`
  background: rgb(var(--lp-colors-neutral-white));
  border: ${({ isOpen }) => `1px solid rgb(var(${isOpen ? '--lp-colors-neutral-700' : '--lp-colors-neutral-300'}))`};
  border-radius: 4px;
  transition: border 0.3s ease;
  position: relative;
  z-index: 0;

  &:hover,
  &:active {
    border: 1px solid rgb(var(--lp-colors-neutral-700));
  }

  .neutral-600 {
    color: rgb(var(--lp-colors-neutral-600));
  }
`;

export const PaymentMethodButton = styled.button`
  height: 56px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  :focus {
    outline: none;
  }
`;

export const PaymentMethodDetailsWrapper = styled.div<{ show: boolean }>`
  height: ${({ show }) => (show ? 'auto' : '0')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition-property: height, opacity;
  transition: all 0.3s ease;
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  overflow: hidden;
`;

export const PaymentMethodDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  padding-top: 0;
`;

export const CvcRow = styled.div`
  margin-top: 11px;
  display: flex;
  align-items: center;
`;

export const CardCvcInput = styled.div`
  border: 1px solid rgb(var(--lp-colors-neutral-200));
  box-sizing: border-box;
  border-radius: 4px;
  width: 48px;
  min-width: 48px;
  height: 32px;
  padding: 0 8px;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const B2bSaveCardWrapper = styled.div`
  .MuiFormControl-root {
    background: rgb(var(--lp-colors-neutral-white));

    .MuiFormLabel-root {
      left: 16px;
      &.Mui-focused {
        color: rgb(var(--lp-colors-neutral-600));
      }
    }

    > div:first-of-type {
      padding: 0 16px;
    }

    .MuiSelect-root.MuiSelect-select .option-text {
      color: rgb(var(--lp-colors-neutral-600));
    }
    .select-error-msg {
      padding-left: 16px;
    }
  }
`;
