import React from 'react';

import { Message, Text } from '@limepayments/cosmic';

export type ErrorMessageType = {
  messageHeader?: string;
  messageBody: string;
};

const ErrorMessage = ({ messageHeader, messageBody }: ErrorMessageType) => {
  if (!messageBody) {
    return <></>;
  }

  return (
    <Message type="inline" variant="error" dismissalType="none">
      <Text variant="body-3" isEmphasised>
        {messageHeader || 'Something went wrong'}
      </Text>
      <Text variant="caption" testId="errorMessageText">
        {messageBody}
      </Text>
    </Message>
  );
};

export default ErrorMessage;
