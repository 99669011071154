import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';
import { PayPlanVariant } from 'lib/types';

import { Amount, PayPlanParameters } from './types';

export type CalculatePayPlanParametersRequest = {
  amount: Amount;
  payPlanVariants?: PayPlanVariant[];
  payInFullAmount?: number;
};

export type CalculatePayPlanParametersResponse = {
  parameters: PayPlanParameters[];
};

export const calculatePayPlanParameters = async (
  token: string,
  payload: CalculatePayPlanParametersRequest,
): Promise<CalculatePayPlanParametersResponse> =>
  fetch(`${await getAprilApiHost()}/payplans/parameters`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<CalculatePayPlanParametersResponse>(handleResponse);
