import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .heading {
    margin-top: 16px;
    color: rgb(var(--lp-colors-neutral-600));
  }
  .description {
    margin-top: 8px;
    margin-bottom: 16px;
    color: rgb(var(--lp-colors-neutral-600));
  }
  .confirmPlanBtn {
    margin-top: 16px;
  }
`;
