import styled from '@emotion/styled';
import { Text } from '@limepayments/cosmic';

export const LpRadio = styled.label<{ isActive: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid rgb(var(${({ isActive }) => (isActive ? '--lp-colors-neutral-700' : '--lp-colors-neutral-300')}));
  border-radius: 4px;
  transition: border 0.3s ease;

  &:hover,
  &:active {
    border: 1px solid rgb(var(--lp-colors-neutral-700));
  }
`;

export const PayOptionWrapper = styled.div<{ isActive: boolean; isPayPlan?: boolean }>`
  background: ${({ isActive }) =>
    isActive ? 'rgb(var(--lp-colors-neutral-800))' : 'rgb(var(--lp-colors-neutral-white))'};
  box-shadow: ${({ isActive }) => (isActive ? '0px 8px 24px -4px rgba(0, 0, 0, 0.08)' : 'none')};
  border-radius: 4px;
  border-bottom-left-radius: ${({ isPayPlan, isActive }) => (isPayPlan && isActive ? '0' : '4px')};
  border-bottom-right-radius: ${({ isPayPlan, isActive }) => (isPayPlan && isActive ? '0' : '4px')};
  .pay-option-text,
  .pay-option-heading {
    color: ${({ isActive }) => (isActive ? 'rgb(var(--lp-colors-neutral-100))' : 'rgb(var(--lp-colors-neutral-600))')};
  }
  .pay-option-text {
    margin-top: 4px;
  }
  .surcharge {
    margin-top: 4px;
    color: ${({ isActive }) => (isActive ? 'rgb(var(--lp-colors-neutral-300))' : 'rgb(var(--lp-colors-neutral-500))')};
  }
  .icon {
    border: solid
      ${({ isActive }) =>
        isActive ? '2px rgb(var(--lp-colors-neutral-100))' : '1px rgb(var(--lp-colors-neutral-300))'};
  }
`;

export const RadioIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-left: auto;
  position: relative;

  .tick-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    left: -2px;
    fill: rgb(var(--lp-colors-neutral-100));
  }

  .radio-input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }
`;

export const PayPlanEligibilityText = styled(Text)`
  color: rgb(var(--lp-colors-neutral-600));
`;
