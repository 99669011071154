import { PaymentSurchargeRequest } from 'app/api.april';
import { ReactComponent as CardBackIcon } from 'assets/card-back.svg';
import checkPayPlanAmountEligibility from 'lib/checkPayPlanAmountEligibility';
import { useCalculateSurcharge, useCardinalDdcSubmit, useError, useSendMessage } from 'lib/hooks';
import { toApplePayMessage } from 'lib/messages';
import {
  CardPaymentSourceBrand,
  CardUsageScope,
  MerchantCountry,
  SavedCardPaymentSource,
  SelectedPaymentSource,
} from 'lib/types';
import { fieldStyles, getVaultHost, onVaultError, startVaultTimeout } from 'lib/vault';
import numeral from 'numeral';
import React, { MouseEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setB2bCardUsageScope,
  setGooglePayPaymentSource,
  setSelectedPaymentSource,
  setSurchargeQuote,
  setVaultCard,
  setVaultCvc,
} from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

import { Vault, createVault } from '@april/lib-ui';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import GooglePayButton from '@google-pay/button-react';
import { Select, Spinner, Text } from '@limepayments/cosmic';

import CardBrand from './CardBrand';
import { DirectDebitForm } from './DirectDebitForm';
import { PayToForm } from './PayToForm';
import PaymentMethod from './PaymentMethod';
import {
  B2BSaveCardSelectionProps,
  BrandName,
  cardCvcId,
  cardCvcOnlyId,
  cardExpiryId,
  cardNumberId,
} from './constants';
import getCard from './getCard';
import * as s from './styles';
import { PaymentSourceType } from './types';

const styles = {
  walletDetail: css({ display: 'flex', alignItems: 'center' }),
};

const PaymentSource = ({
  isActive,
  savedCards,
  showWallets,
  applePayPaymentSource,
  googlePayPaymentSource,
  directDebitForm,
  payToForm,
  showB2bSaveCard,
  b2bSaveCardError,
  setIsCardReady,
  setIsCvcReady,
  onGooglePaySubmit,
  submitDisabled,
  submitLabel,
  error,
  onChange,
  onSubmit,
}: PaymentSourceType) => {
  const [hidePaymentSource, setHidePaymentSource] = useState<boolean | null>(null);
  const [savedCard, setSavedCard] = useState<SavedCardPaymentSource | null>(null);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [isGooglePayReady, setIsGooglePayReady] = useState(false);
  const [surchargeQuoteError, setSurchargeQuoteError] = useState('');

  const didMountCardRef = useRef(false);
  const didMountCvcRef = useRef(false);
  const dispatch = useDispatch();
  const sendMessage = useSendMessage();
  const onCardinalDdcSubmit = useCardinalDdcSubmit();
  const onCalculateSurcharge = useCalculateSurcharge();
  const onError = useError();

  const { config, params, selectedPaymentSource, b2bCardUsageScope, getVaultCard } = useSelector(
    (state: ReduxState) => ({
      config: state.config,
      params: state.params,
      selectedPaymentSource: state.selectedPaymentSource,
      b2bCardUsageScope: state.b2bCardUsageScope,
      getVaultCard: state.getVaultCard,
    }),
  );

  const surchargeQuoteRequired = !params?.paymentSourceOnly && !!config?.surchargeQuoteRequired;

  const paymentMethodError = surchargeQuoteError || error;

  const showSavedCardCvc = !params?.paymentSourceOnly && !!setIsCvcReady;

  const tags = useMemo(() => [...(config?.merchantTags ?? []), ...(config?.marketplaceTags ?? [])], [config]);

  const showDirectDebit = useMemo(() => {
    return tags.includes('DirectDebit') && !!directDebitForm && config?.merchantTradingCountry === MerchantCountry.AU;
  }, [config, tags, directDebitForm]);

  const showPayTo = useMemo(() => {
    return tags.includes('PayTo') && !!payToForm && config?.merchantTradingCountry === MerchantCountry.AU;
  }, [config, tags, payToForm]);

  const defaultPaymentSource = useMemo(() => {
    if (showDirectDebit || showPayTo) return null;

    return savedCard ? SelectedPaymentSource.savedCard : SelectedPaymentSource.newCard;
  }, [savedCard, showDirectDebit, showPayTo]);

  useLayoutEffect(() => {
    if (!config || !params) return setHidePaymentSource(true);

    const { payPlanAmountEligibility } = checkPayPlanAmountEligibility(params, config);
    const hide = !payPlanAmountEligibility && !!config.allowHideFullPay && !!params.hideFullPayOption;
    setHidePaymentSource(hide);
  }, [config, params]);

  useEffect(() => {
    setSavedCard(getCard(savedCards));
  }, [savedCards, dispatch]);

  useEffect(() => {
    applePayPaymentSource && dispatch(setSelectedPaymentSource(SelectedPaymentSource.applePay));
  }, [applePayPaymentSource, dispatch]);

  useEffect(() => {
    googlePayPaymentSource && dispatch(setSelectedPaymentSource(SelectedPaymentSource.googlePay));
  }, [googlePayPaymentSource, dispatch]);

  useEffect(() => {
    isActive && dispatch(setSelectedPaymentSource(defaultPaymentSource));
  }, [isActive, defaultPaymentSource, dispatch]);

  const onSurchargeQuote = useCallback(
    async (vault: Vault | null, method: PaymentSurchargeRequest['PaymentSurchargeRequest']['payment']['method']) => {
      if (!surchargeQuoteRequired) return;

      setSurchargeQuoteError('');
      dispatch(setSurchargeQuote(null));

      try {
        const response = await onCalculateSurcharge(vault, method);
        dispatch(setSurchargeQuote(response.PaymentSurchargeResponse.surchargeQuote));
      } catch (error) {
        setSurchargeQuoteError(onError(error));
      }
    },
    [surchargeQuoteRequired, onCalculateSurcharge, onError, dispatch],
  );

  const onActivePaymentChanged = useCallback(
    async (activePayment: SelectedPaymentSource | null) => {
      if (!surchargeQuoteRequired) return;

      setSurchargeQuoteError('');
      dispatch(setSurchargeQuote(null));

      if (activePayment === SelectedPaymentSource.newCard && getVaultCard?.().fields.cardNumber?.state.isValid) {
        // new card
        onSurchargeQuote(getVaultCard(), { Card: { cardNumber: '{{cardNumber}}' } });
      } else if (activePayment === SelectedPaymentSource.savedCard && savedCard) {
        // saved card
        onSurchargeQuote(null, { SavedPaymentSource: { paymentSourceId: savedCard.cardPaymentSourceId } });
      } else if (activePayment === SelectedPaymentSource.directDebit) {
        // direct debit
        onSurchargeQuote(null, { DirectDebit: {} });
      } else if (activePayment === SelectedPaymentSource.payTo) {
        // pay to
        onSurchargeQuote(null, { PayTo: {} });
      } else if (activePayment === SelectedPaymentSource.applePay && applePayPaymentSource) {
        // apple pay
        onSurchargeQuote(null, { ApplePay: { applePay: applePayPaymentSource } });
      } else if (activePayment === SelectedPaymentSource.googlePay && googlePayPaymentSource) {
        // google pay
        onSurchargeQuote(null, { GooglePay: { googlePay: googlePayPaymentSource } });
      }
    },
    [
      surchargeQuoteRequired,
      getVaultCard,
      savedCard,
      applePayPaymentSource,
      googlePayPaymentSource,
      onSurchargeQuote,
      dispatch,
    ],
  );

  useEffect(() => {
    onActivePaymentChanged(selectedPaymentSource);
  }, [selectedPaymentSource, dispatch, onActivePaymentChanged]);

  useEffect(() => {
    if (selectedPaymentSource === SelectedPaymentSource.savedCard && savedCard?.cardBin) {
      onCardinalDdcSubmit(savedCard.cardBin);
    }
  }, [selectedPaymentSource, savedCard, onCardinalDdcSubmit]);

  const loadVaultCvc = useCallback(async () => {
    if (!showSavedCardCvc) return;

    const cardVault = await createVault({
      vaultHost: await getVaultHost(),
      fields: {
        cardCvc: {
          elementId: cardCvcOnlyId,
          fieldStyles: { ...fieldStyles, fontSize: '14px' },
        },
      },
      onEvent: ({ eventName, vault }) => {
        if (eventName === 'Change') {
          const { isValid } = vault.validate();
          setIsCvcReady(isValid);
        }
      },
      onError: onVaultError,
    });
    dispatch(setVaultCvc(cardVault));
  }, [dispatch, setIsCvcReady, showSavedCardCvc]);

  useEffect(() => {
    (async () => {
      if (!config || !savedCard || !showSavedCardCvc || didMountCvcRef.current) return;
      didMountCvcRef.current = true;

      await loadVaultCvc();
    })();
  }, [config, loadVaultCvc, showSavedCardCvc, savedCard]);

  const loadVaultCard = useCallback(async () => {
    setCardLoading(true);
    const timeout = startVaultTimeout();
    const cardVault = createVault({
      vaultHost: await getVaultHost(),
      fields: {
        cardNumber: { elementId: cardNumberId, fieldStyles },
        expiryDate: { elementId: cardExpiryId, fieldStyles },
        cardCvc: { elementId: cardCvcId, fieldStyles },
      },
      onReady: () => {
        clearTimeout(timeout);
        setCardLoading(false);
      },
      onEvent: ({ field, eventName, vault }) => {
        if (eventName === 'Change') {
          if (field.type === 'cardNumber') {
            dispatch(setSurchargeQuote(null));
            field.state.bin && onCardinalDdcSubmit(field.state.bin);
            field.state.isValid && onSurchargeQuote(vault, { Card: { cardNumber: '{{cardNumber}}' } });
          }
          const { isValid } = vault.validate();
          setIsCardReady(isValid);
        }
      },
      onError: onVaultError,
    });
    dispatch(setVaultCard(cardVault));
  }, [dispatch, setIsCardReady, onCardinalDdcSubmit, onSurchargeQuote]);

  useEffect(() => {
    (async () => {
      if (!config || didMountCardRef.current) return;
      didMountCardRef.current = true;

      await loadVaultCard();
    })();
  }, [config, loadVaultCard]);

  const toggleActivePayment = useCallback(
    (paymentSource: SelectedPaymentSource) => {
      onChange?.(selectedPaymentSource === paymentSource ? null : paymentSource);
    },
    [onChange, selectedPaymentSource],
  );

  const handleChangeB2BSaveCard = (value: string) => {
    dispatch(setB2bCardUsageScope(value as CardUsageScope));
  };

  const allowedCardBrandsFilter = useCallback(
    (brand: string) => {
      if (!config?.allowedCardBrands) return true;

      const toCardPaymentSourceBrand: { [network: string]: CardPaymentSourceBrand } = {
        visa: 'Visa',
        mastercard: 'MasterCard',
        amex: 'American Express',
      };

      return config.allowedCardBrands.includes(toCardPaymentSourceBrand[brand.toLowerCase()]);
    },
    [config],
  );

  const handleApplePayClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!params || !config) return;
    sendMessage(
      toApplePayMessage(
        {
          event: 'begin',
          payload: {
            countryCode: config.merchantTradingCountry,
            currencyCode: params.currency,
            merchantCapabilities: ['supports3DS'],
            supportedNetworks: ['visa', 'masterCard', 'amex'].filter(allowedCardBrandsFilter),
            total: {
              label: config.merchantBusinessDisplayName,
              type: surchargeQuoteRequired ? 'pending' : 'final',
              amount: numeral(params.paycardAmount).divide(100).format('0.00'),
            },
          },
        },
        params.elementId,
      ),
    );
  };

  const googlePayPaymentDataRequest = useMemo((): google.payments.api.PaymentDataRequest | null => {
    if (!params || !config) return null;

    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX'].filter(
              allowedCardBrandsFilter,
            ) as google.payments.api.CardNetwork[],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'limepay',
              gatewayMerchantId: config.merchantId,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: config.walletSupport.googlePayMerchantId,
        merchantName: config.merchantBusinessDisplayName,
      },
      transactionInfo: {
        totalPriceStatus: surchargeQuoteRequired ? 'ESTIMATED' : 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: numeral(params.paycardAmount).divide(100).format('0.00'),
        currencyCode: params.currency,
      },
    };
  }, [params, config, surchargeQuoteRequired, allowedCardBrandsFilter]);

  const isAmex = useMemo(() => {
    if (!savedCard) {
      return false;
    }
    return ['american express', 'amex'].includes((savedCard.brand ?? '').toLowerCase());
  }, [savedCard]);

  if (hidePaymentSource === null || hidePaymentSource === true) {
    return <></>;
  }

  const showApplePayButton = config?.walletSupport.applePaySupported && params?.showApplePay;
  const showGooglePayButton = config?.walletSupport.googlePaySupported && googlePayPaymentDataRequest;

  return (
    <s.Wrapper>
      {!!savedCard && (
        <PaymentMethod
          brand={savedCard.brand ?? ''}
          label={`${BrandName[savedCard.brand ?? ''] || savedCard.brand} ****${savedCard.last4}`}
          isMounted={true}
          isOpen={selectedPaymentSource === SelectedPaymentSource.savedCard}
          submitDisabled={submitDisabled}
          submitLabel={submitLabel}
          error={paymentMethodError}
          testId={SelectedPaymentSource.savedCard}
          onToogle={() => toggleActivePayment(SelectedPaymentSource.savedCard)}
          onSubmit={onSubmit}
        >
          {showSavedCardCvc && (
            <div>
              <Text variant="caption" isEmphasised className="neutral-600" testId="securityNumber">
                Security number
              </Text>
              <s.CvcRow>
                <s.CardCvcInput id={cardCvcOnlyId} />
                <CardBackIcon className="cardBackIcon" />
                <Text variant="legal" className="neutral-600" testId="cardBackDescription">
                  The {isAmex ? 4 : 3} digit number on the <b>{isAmex ? 'front' : 'back'}</b> of your card
                </Text>
              </s.CvcRow>
            </div>
          )}
        </PaymentMethod>
      )}

      <PaymentMethod
        brand="card"
        label="Credit / debit card"
        isMounted={true}
        isOpen={selectedPaymentSource === SelectedPaymentSource.newCard}
        submitDisabled={submitDisabled}
        submitLabel={submitLabel}
        error={paymentMethodError}
        testId={SelectedPaymentSource.newCard}
        onToogle={() => toggleActivePayment(SelectedPaymentSource.newCard)}
        onSubmit={onSubmit}
      >
        {cardLoading && (
          <s.CardLoading>
            <Spinner variant="simple" isVisible />
          </s.CardLoading>
        )}
        <div>
          <s.CardRow>
            <s.CardInput id={cardNumberId} />
          </s.CardRow>
          <s.CardRow>
            <s.CardInput id={cardExpiryId} />
            <s.CardInput id={cardCvcId} />
          </s.CardRow>
        </div>
        {showB2bSaveCard && (
          <s.B2bSaveCardWrapper>
            <Select
              {...B2BSaveCardSelectionProps}
              errorMessage={b2bSaveCardError && !b2bCardUsageScope ? 'Please select an option' : ''}
              value={(b2bCardUsageScope || '') as string}
              onChange={handleChangeB2BSaveCard}
            />
          </s.B2bSaveCardWrapper>
        )}
      </PaymentMethod>

      {showDirectDebit && !!directDebitForm && (
        <PaymentMethod
          brand="bank"
          label="Direct debit"
          isOpen={selectedPaymentSource === SelectedPaymentSource.directDebit}
          submitDisabled={submitDisabled}
          submitLabel={submitLabel}
          error={paymentMethodError}
          testId={SelectedPaymentSource.directDebit}
          onToogle={() => toggleActivePayment(SelectedPaymentSource.directDebit)}
          onSubmit={onSubmit}
        >
          <DirectDebitForm form={directDebitForm} />
        </PaymentMethod>
      )}

      {showPayTo && !!payToForm && (
        <PaymentMethod
          brand="payto"
          label="PayTo"
          isOpen={selectedPaymentSource === SelectedPaymentSource.payTo}
          submitDisabled={submitDisabled}
          submitLabel="Continue"
          error={paymentMethodError}
          testId={SelectedPaymentSource.payTo}
          onToogle={() => toggleActivePayment(SelectedPaymentSource.payTo)}
          onSubmit={onSubmit}
        >
          <PayToForm form={payToForm} />
        </PaymentMethod>
      )}

      {showWallets && (
        <>
          {showGooglePayButton && (
            <div style={{ display: isGooglePayReady ? 'block' : 'none' }}>
              <PaymentMethod
                brand="google-pay"
                label="Google Pay"
                isMounted={!isGooglePayReady}
                isOpen={selectedPaymentSource === SelectedPaymentSource.googlePay}
                hidePaymentTotal={!googlePayPaymentSource}
                submitDisabled={submitDisabled}
                submitLabel={submitLabel}
                error={paymentMethodError}
                testId={SelectedPaymentSource.googlePay}
                onToogle={() => toggleActivePayment(SelectedPaymentSource.googlePay)}
                onSubmit={googlePayPaymentSource ? onSubmit : undefined}
              >
                <GooglePayButton
                  buttonType="plain"
                  buttonSizeMode="fill"
                  style={{ width: '100%', height: 60 }}
                  environment={
                    config.walletSupport.googlePayMerchantId === '12345678901234567890' ? 'TEST' : 'PRODUCTION'
                  }
                  onReadyToPayChange={({ isReadyToPay }) => setIsGooglePayReady(isReadyToPay)}
                  paymentRequest={googlePayPaymentDataRequest}
                  onLoadPaymentData={(paymentData) => {
                    console.log('google pay token:');
                    console.log(JSON.stringify(paymentData.paymentMethodData));
                    dispatch(setGooglePayPaymentSource(paymentData.paymentMethodData));
                    onGooglePaySubmit?.(paymentData.paymentMethodData);
                  }}
                />
                {!!googlePayPaymentSource && (
                  <div css={styles.walletDetail}>
                    <CardBrand brand="google-pay" isOpen />
                    <div>
                      <Text tagName="span" variant="caption" className="cardBrand">
                        {googlePayPaymentSource.description}
                      </Text>
                    </div>
                  </div>
                )}
              </PaymentMethod>
            </div>
          )}

          {showApplePayButton && (
            <PaymentMethod
              brand="apple-pay"
              label="Apple Pay"
              isOpen={selectedPaymentSource === SelectedPaymentSource.applePay}
              hidePaymentTotal={!applePayPaymentSource}
              submitDisabled={submitDisabled}
              submitLabel={submitLabel}
              error={paymentMethodError}
              testId={SelectedPaymentSource.applePay}
              onToogle={() => toggleActivePayment(SelectedPaymentSource.applePay)}
              onSubmit={applePayPaymentSource ? onSubmit : undefined}
            >
              <s.ApplePayButton onClick={handleApplePayClick} />
              {!!applePayPaymentSource && (
                <div css={styles.walletDetail}>
                  <CardBrand brand="apple-pay" isOpen />
                  <div>
                    <Text tagName="span" variant="caption" className="cardBrand">
                      {applePayPaymentSource.paymentMethod.displayName}
                    </Text>
                  </div>
                </div>
              )}
            </PaymentMethod>
          )}
        </>
      )}
    </s.Wrapper>
  );
};

export default PaymentSource;
