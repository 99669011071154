import {
  CreatePayPlanOfferResponse,
  PayPlanParameters,
  PayToSendAgreementRequest,
  SurchargeQuote,
} from 'app/api.april';
import { Config, GetCustomerResponse, SendCustomerVerification } from 'app/apiTypes';
import { Params } from 'lib/toParams';
import { CardUsageScope, PaymentType, SavedCardPaymentSource, SelectedPaymentSource, Step } from 'lib/types';
import { InitParams, initialState } from 'redux/reduxTypes';

import { Vault } from '@april/lib-ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<Config>) => {
      state.config = action.payload;
    },
    setInitParams: (state, action: PayloadAction<InitParams>) => {
      state.initParams = action.payload;
    },
    setParams: (state, action: PayloadAction<Params>) => {
      /**
       * https://github.com/LimePayments/checkout-v2-ui/blob/master/src/lib/reducers.ts#L7
       * need to set paymentType by params.paymentType
       */
      state.params = action.payload;
      state.paymentType = action.payload.paymentType || 'paycard';
    },
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.paymentType = action.payload;
    },
    setCheckoutStep: (state, action: PayloadAction<Step>) => {
      state.checkoutStep = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<GetCustomerResponse>) => {
      state.currentUser = action.payload;
    },
    setCardPaymentSources: (state, action: PayloadAction<SavedCardPaymentSource[]>) => {
      state.cardPaymentSources = action.payload;
    },
    setSelectedCardPaymentSource: (state, action: PayloadAction<SavedCardPaymentSource>) => {
      state.selectedCardPaymentSource = action.payload;
    },
    setApplePayPaymentSource: (state, action: PayloadAction<ApplePayJS.ApplePayPaymentToken>) => {
      state.applePayPaymentSource = action.payload;
    },
    setGooglePayPaymentSource: (state, action: PayloadAction<google.payments.api.PaymentMethodData>) => {
      state.googlePayPaymentSource = action.payload;
    },
    setVaultCard: (state, action: PayloadAction<Vault>) => {
      state.getVaultCard = () => action.payload;
    },
    setVaultCvc: (state, action: PayloadAction<Vault>) => {
      state.getVaultCvc = () => action.payload;
    },
    setContactDetails: (state, action: PayloadAction<{ phone: string; email: string }>) => {
      state.contactDetailsPhone = action.payload.phone;
      state.contactDetailsEmail = action.payload.email;
    },
    setVerification: (
      state,
      action: PayloadAction<{
        phoneVerification: SendCustomerVerification['phoneVerification'];
        emailVerification: SendCustomerVerification['emailVerification'];
      }>,
    ) => {
      state.phoneVerification = action.payload.phoneVerification;
      state.emailVerification = action.payload.emailVerification;
    },
    setCustomToken: (state, action: PayloadAction<string>) => {
      state.customToken = action.payload;
    },
    setPayPlanEligibility: (state, action: PayloadAction<CreatePayPlanOfferResponse>) => {
      state.payPlanEligibility = action.payload;
    },
    setPayPlanVariants: (state, action: PayloadAction<PayPlanParameters[]>) => {
      state.payPlanVariants = action.payload;
    },
    setSelectedPayPlanVariant: (state, action: PayloadAction<PayPlanParameters | null>) => {
      state.selectedPayPlanVariant = action.payload;
    },
    setSelectedPaymentSource: (state, action: PayloadAction<SelectedPaymentSource | null>) => {
      state.selectedPaymentSource = action.payload;
    },
    setSurchargeQuote: (state, action: PayloadAction<SurchargeQuote | null>) => {
      state.surchargeQuote = action.payload;
    },
    setPayToAgreementRequest: (state, action: PayloadAction<PayToSendAgreementRequest | null>) => {
      state.payToAgreementRequest = action.payload;
    },
    setPayToToken: (state, action: PayloadAction<string | null>) => {
      state.payToToken = action.payload;
    },
    setB2bCardUsageScope: (state, action: PayloadAction<CardUsageScope>) => {
      state.b2bCardUsageScope = action.payload;
    },
    setB2bProspectToken: (state, action: PayloadAction<string | null>) => {
      state.b2bProspectToken = action.payload;
    },
  },
});

export const {
  setConfig,
  setInitParams,
  setParams,
  setPaymentType,
  setCheckoutStep,
  setCustomerId,
  setCurrentUser,
  setCardPaymentSources,
  setSelectedCardPaymentSource,
  setApplePayPaymentSource,
  setGooglePayPaymentSource,
  setVaultCard,
  setVaultCvc,
  setContactDetails,
  setVerification,
  setCustomToken,
  setPayPlanEligibility,
  setPayPlanVariants,
  setSelectedPayPlanVariant,
  setSelectedPaymentSource,
  setSurchargeQuote,
  setPayToAgreementRequest,
  setPayToToken,
  setB2bCardUsageScope,
  setB2bProspectToken,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
