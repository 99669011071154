import ErrorMessage from 'components/ErrorMessage';
import PaymentSource from 'components/PaymentSource';
import { SpinnerWrapper } from 'lib/commonStyles';
import { STATE_ERROR_MESSAGE } from 'lib/errorMessages';
import {
  useError,
  usePaymentSourceSubmit,
  useQuerySavedCards,
  useSendMessage,
  useShowB2bSaveCard,
  useSubmitMessageCallback,
} from 'lib/hooks';
import { toPaymentSourceMessage } from 'lib/messages';
import { SelectedPaymentSource } from 'lib/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedPaymentSource } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Spinner } from '@limepayments/cosmic';

export interface PaymentSelectionStepType {}

const PaymentSelectionStep = (props: PaymentSelectionStepType) => {
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(true);
  const [isCardReady, setIsCardReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [b2bSaveCardError, setB2bSaveCardError] = useState<boolean>(false);

  const { config, params, customerId, cardPaymentSources, b2bCardUsageScope, selectedPaymentSource } = useSelector(
    (state: ReduxState) => ({
      config: state.config,
      params: state.params,
      customerId: state.customerId,
      cardPaymentSources: state.cardPaymentSources,
      applePayPaymentSource: state.applePayPaymentSource,
      googlePayPaymentSource: state.googlePayPaymentSource,
      b2bCardUsageScope: state.b2bCardUsageScope,
      selectedPaymentSource: state.selectedPaymentSource,
    }),
  );

  const showB2bSaveCard = useShowB2bSaveCard();

  useEffect(() => {
    let enabled =
      (selectedPaymentSource === SelectedPaymentSource.newCard && isCardReady) ||
      selectedPaymentSource === SelectedPaymentSource.savedCard ||
      selectedPaymentSource === SelectedPaymentSource.applePay ||
      selectedPaymentSource === SelectedPaymentSource.googlePay;
    setDisabledSubmitBtn(loading || !enabled);
  }, [loading, isCardReady, selectedPaymentSource]);

  useEffect(() => {
    setErrorMessage('');
  }, [selectedPaymentSource]);

  const dispatch = useDispatch();
  const onError = useError();
  const sendMessage = useSendMessage();
  const onQuerySavedCards = useQuerySavedCards();
  const onPaymentSourceSubmit = usePaymentSourceSubmit();

  /**
   * if query saved cards failed, will not display error message
   * user can still continue to pay by new credit card details
   */
  useEffect(() => {
    const fetchSavedCards = async () => {
      try {
        if (!customerId) {
          return;
        }
        setLoading(true);
        await onQuerySavedCards();
      } catch (e) {
        onError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedCards();
  }, [customerId, onError, onQuerySavedCards]);

  const handleSubmitPay = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      if (!params) throw Error(STATE_ERROR_MESSAGE);

      if (!selectedPaymentSource) {
        throw Error('Please select a payment source');
      }
      if (selectedPaymentSource === SelectedPaymentSource.newCard && !isCardReady) {
        throw Error('Please enter valid credit card details');
      }
      if (showB2bSaveCard && SelectedPaymentSource.newCard === selectedPaymentSource) {
        if (!b2bCardUsageScope) {
          setB2bSaveCardError(true);
          return;
        }
        setB2bSaveCardError(false);
      }

      const paymentSource = await onPaymentSourceSubmit();
      paymentSource && sendMessage(toPaymentSourceMessage(paymentSource, params.elementId));
    } catch (e) {
      setErrorMessage(onError(e));
    } finally {
      setLoading(false);
    }
  }, [
    params,
    isCardReady,
    showB2bSaveCard,
    selectedPaymentSource,
    b2bCardUsageScope,
    onError,
    onPaymentSourceSubmit,
    sendMessage,
  ]);

  const handleSubmitMessage = useCallback(
    () => (loading ? onError('Please wait, processing your request.') : handleSubmitPay()),
    [loading, handleSubmitPay, onError],
  );

  useSubmitMessageCallback(handleSubmitMessage);

  if (!params || !config) {
    return <></>;
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: 16, position: 'relative' }}>
      {loading && (
        <SpinnerWrapper>
          <Spinner variant="simple" isVisible />
        </SpinnerWrapper>
      )}
      <PaymentSource
        isActive={true}
        savedCards={cardPaymentSources}
        applePayPaymentSource={null}
        googlePayPaymentSource={null}
        directDebitForm={null}
        payToForm={null}
        showB2bSaveCard={showB2bSaveCard}
        error={errorMessage}
        b2bSaveCardError={b2bSaveCardError}
        setIsCardReady={setIsCardReady}
        submitDisabled={disabledSubmitBtn}
        submitLabel="Submit"
        onChange={(paymentSource) => dispatch(setSelectedPaymentSource(paymentSource))}
        onSubmit={params.showSubmit ? handleSubmitPay : undefined}
      />
      {!selectedPaymentSource && <ErrorMessage messageBody={errorMessage} />}
    </div>
  );
};

export default PaymentSelectionStep;
