import React, { ReactNode } from 'react';

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon } from '@limepayments/cosmic';

const styles = {
  wrapper: css({
    display: 'flex',
    padding: '16px 8px',
    borderRadius: 4,
    boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.08)',
  }),
  contentWrapper: css({ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, marginLeft: 8 }),
  content: css({ fontSize: 12, lineHeight: '16px' }),
};

export type AlertProps = {
  status: 'error' | 'warning' | 'success';
  message?: ReactNode;
  children?: ReactNode;
};
export const Alert = ({ status = 'warning', message, children }: AlertProps) => {
  const color = status === 'warning' ? 'sunrise' : status;

  return (
    <div
      data-testid={`alert-${status}`}
      css={styles.wrapper}
      style={{
        color: `rgb(var(--lp-colors-${color}-600))`,
        backgroundColor: `rgb(var(--lp-colors-${color}-50))`,
        border: `1px solid rgb(var(--lp-colors-${color}-600))`,
      }}
    >
      <Icon name={status === 'success' ? 'Tick' : 'Error'} />
      <div css={styles.contentWrapper}>
        <div
          data-testid="alert-message"
          css={styles.content}
          style={{ color: `rgb(var(--lp-colors-${color}-${status === 'warning' ? '900' : '700'}))` }}
        >
          {message ?? children}
        </div>
      </div>
    </div>
  );
};
