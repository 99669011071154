import { deleteApp, getApp, initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth, getIdToken, setPersistence, signInWithCustomToken } from 'firebase/auth';
import { logRocketIdentify } from 'lib/logrocket';

const initialize = async (options: Object) => {
  try {
    const app = getApp();
    await deleteApp(app);
  } catch (error) {}
  return initializeApp(options);
};

export const initializeFB = async (apiKey: string, authDomain: string) => {
  const [fb] = await Promise.all([
    initialize({
      apiKey,
      authDomain,
    }),
  ]);
  return fb;
};

export const fbSignInWithCustomToken = async (tenantId: string, token: string) => {
  const auth = getAuth();
  auth.tenantId = tenantId;

  await setPersistence(auth, browserSessionPersistence);
  const userCredential = await signInWithCustomToken(auth, token);
  logRocketIdentify();
  return userCredential;
};

export const getCurrentUserToken = async (): Promise<string> => {
  const auth = getAuth();
  const { currentUser } = auth;
  if (!currentUser) {
    return '';
  }
  const token = await getIdToken(currentUser);
  return token;
};
