import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 880px;
  min-height: 90px;
  position: relative;
  padding: 16px;
  margin: 0 auto;
  background-color: rgba(var(--lp-colors-primary), 0.04);
`;
