import { PayPlanParameters } from 'app/api.april';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

import { Stack } from '@limepayments/cosmic';

import { PayPlanVariant } from './PayPlanVariant';

export type PayPlanSelectionProps = {
  onChange?(payPlanVariant: PayPlanParameters): void;
};

const PayPlanSelection = ({ onChange }: PayPlanSelectionProps) => {
  const { payPlanVariants, selectedPayPlanVariant } = useSelector((state: ReduxState) => ({
    payPlanVariants: state.payPlanVariants,
    selectedPayPlanVariant: state.selectedPayPlanVariant,
  }));

  const handlePayPlanVariantSelected = useCallback(
    (payPlanParameters: PayPlanParameters) => {
      if (selectedPayPlanVariant?.payPlanVariant === payPlanParameters.payPlanVariant) return;

      onChange?.(payPlanParameters);
    },
    [onChange, selectedPayPlanVariant],
  );

  return (
    <Stack spacing="xxs">
      {payPlanVariants?.map((payPlanParameters) => (
        <PayPlanVariant
          key={payPlanParameters.payPlanVariant}
          isActive={payPlanParameters.payPlanVariant === selectedPayPlanVariant?.payPlanVariant}
          payPlanParameters={payPlanParameters}
          surcharge={0}
          onClick={() => handlePayPlanVariantSelected(payPlanParameters)}
        />
      ))}
    </Stack>
  );
};

export default PayPlanSelection;
