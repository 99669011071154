import { administerCustomer } from 'app/api.april/customers/administerCustomer';
import { KybResult, administerOrganisation } from 'app/api.april/customers/administerOrganisation';
import { fbSignInWithCustomToken } from 'app/identity';
import { CUSTOMER_TOKEN_ERROR_MESSAGE, STATE_ERROR_MESSAGE, getErrorMessage } from 'lib/errorMessages';
import { Step } from 'lib/types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setB2bProspectToken, setCheckoutStep, setCustomToken } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

import { CreateStep, CreateStepProps } from './CreateStep';
import { JoinStep, JoinStepProps } from './JoinStep';
import { SearchStep, SearchStepProps } from './SearchStep';

export const KybStep = () => {
  const [step, setStep] = useState<'Search' | 'Create' | 'Join'>('Search');
  const [kybResult, setKybResult] = useState<KybResult | null>(null);
  const [org, setOrg] = useState<{ organisationCustomerId: string; businessName: string } | null>(null);

  const dispatch = useDispatch();
  const { params, config, b2bProspectToken } = useSelector((state: ReduxState) => ({
    params: state.params,
    config: state.config,
    b2bProspectToken: state.b2bProspectToken,
  }));

  const handleSearch = useCallback<NonNullable<SearchStepProps['onSearch']>>(
    async (abn) => {
      try {
        if (!params || !b2bProspectToken) throw STATE_ERROR_MESSAGE;

        const response = await administerOrganisation(params.publicKey, {
          PerformKyb: {
            businessIdentifier: { Abn: { abn } },
            b2bProspectToken,
          },
        });

        if ('KybNewOrganisationCustomer' in response) {
          const { result, b2bProspectToken } = response.KybNewOrganisationCustomer;
          dispatch(setB2bProspectToken(b2bProspectToken));
          setKybResult(result);
        }
        if ('KybExistingOrganisationCustomer' in response) {
          const { organisationCustomerId, businessName, b2bProspectToken } = response.KybExistingOrganisationCustomer;
          dispatch(setB2bProspectToken(b2bProspectToken));
          setOrg({ organisationCustomerId, businessName });
          setStep('Join');
        }
      } catch (error) {
        const message = getErrorMessage(error);
        return { status: 'error', message };
      }
    },
    [params, b2bProspectToken, dispatch],
  );

  const handleConnect = useCallback(() => {
    setStep('Create');
  }, []);

  const handleCreate = useCallback<NonNullable<CreateStepProps['onSubmit']>>(
    async ({ uboIndex }) => {
      try {
        if (!params || !config || !b2bProspectToken) throw STATE_ERROR_MESSAGE;

        const response = await administerOrganisation(params.publicKey, {
          CreateOrganisationCustomerAndDelegate: {
            uboIndex,
            b2bProspectToken,
          },
        });

        if ('CreateOrganisationCustomerAndDelegateResponse' in response) {
          const { customToken } = response.CreateOrganisationCustomerAndDelegateResponse;

          const userCredential = await fbSignInWithCustomToken(config.tenantId, customToken);
          if (!userCredential) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

          dispatch(setCustomToken(customToken));
          dispatch(setCheckoutStep(Step.Kyc));
        }
      } catch (error) {
        const message = getErrorMessage(error);
        return { status: 'error', message };
      }
    },
    [params, config, b2bProspectToken, dispatch],
  );

  const handleJoin = useCallback<NonNullable<JoinStepProps['onSubmit']>>(
    async ({ firstName, lastName }) => {
      try {
        if (!params || !config || !org || !b2bProspectToken) throw STATE_ERROR_MESSAGE;

        await administerCustomer(org.organisationCustomerId, params.publicKey, {
          RequestDelegateAddition: {
            b2bProspectToken,
            givenName: firstName,
            familyName: lastName,
          },
        });

        return { status: 'success', message: 'Request sent' };
      } catch (error) {
        const message = getErrorMessage(error);
        return { status: 'error', message };
      }
    },
    [b2bProspectToken, config, org, params],
  );

  return (
    <>
      {step === 'Search' && (
        <SearchStep
          kybResult={kybResult}
          onBack={() => (kybResult ? setKybResult(null) : dispatch(setCheckoutStep(Step.PaymentSelection)))}
          onSearch={handleSearch}
          onConnect={handleConnect}
        />
      )}
      {step === 'Create' && !!kybResult && (
        <CreateStep kybResult={kybResult} onBack={() => setStep('Search')} onSubmit={handleCreate} />
      )}
      {step === 'Join' && !!org && (
        <JoinStep businessName={org.businessName} onBack={() => setStep('Search')} onSubmit={handleJoin} />
      )}
    </>
  );
};
