import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

import styled from '@emotion/styled';
import { Link, Text } from '@limepayments/cosmic';

const Wrapper = styled.div`
  margin-top: 16px;
  .terms {
    color: rgb(var(--lp-colors-neutral-600));
  }
`;

const Disclaimer = () => {
  const { config } = useSelector((state: ReduxState) => ({
    config: state.config,
  }));

  return (
    <Wrapper>
      <Text variant="legal" className="terms" testId="payPlanTerms">
        *By continuing, you consent to your personal information being disclosed and used to help check and verify your
        identity. April collect and use the information you provide to administer your payment plan on behalf of the
        merchant. It is a condition of the payment plan that you provide this information. You can check the information
        we hold about you at any time. You can learn more by reading our{' '}
        <Link href={config?.privacyUrl} target="_blank" size="legal">
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link href={config?.tcUrl} target="_blank" size="legal">
          Terms of Use
        </Link>
        .
      </Text>
    </Wrapper>
  );
};

export default Disclaimer;
