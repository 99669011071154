const hexToRGB = (hex: string) => {
  // discard the starting #
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  return `${r},${g},${b}`;
};

export default hexToRGB;
