import '@limepayments/cosmic/build/css/reset.css';
import '@limepayments/cosmic/build/css/styles.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'redux/store';
import ResizeObserver from 'resize-observer-polyfill';

import App from './App';

// polyfills
window.ResizeObserver = window.ResizeObserver || ResizeObserver;

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
