import { CardPaymentSource, SavedCardPaymentSource } from 'lib/types';

import { SavedCardSource } from './createPaymentSource';
import { TokenCardSource } from './createPaymentToken';
import { Card, CardFunding } from './types';

export const VAULT_CARD: Card = {
  number: '{{cardNumber}}',
  cvc: '{{cardCvc}}',
  expiryDate: {
    expiryMonth: '{{expiryMonth}}',
    expiryYear: '{{expiryYear}}',
  },
};

export const toCardFunding = (funding: CardFunding): NonNullable<CardPaymentSource['funding']> =>
  funding === 'Credit' ? 'credit' : funding === 'Debit' ? 'debit' : funding === 'Prepaid' ? 'prepaid' : 'unknown';

export const toCardPaymentSource = ({
  last4,
  bin,
  brand,
  funding,
  expiryDate,
  country,
}: TokenCardSource): CardPaymentSource => ({
  last4,
  cardBin: bin,
  brand: brand === 'Unknown' ? '' : brand,
  funding: toCardFunding(funding),
  expMonth: Number(expiryDate.expiryMonth),
  expYear: Number(expiryDate.expiryYear),
  country: country ?? '',
});

export const toSavedCardPaymentSource = (
  cardPaymentSourceId: string,
  { last4, cardBin, brand, funding, expMonth, expYear, country }: SavedCardSource,
): SavedCardPaymentSource => ({
  cardPaymentSourceId,
  last4,
  cardBin,
  brand: brand === 'Unknown' ? '' : brand,
  funding: toCardFunding(funding),
  expMonth,
  expYear,
  country,
});
