import LogRocket from 'logrocket';

import { getCardinalHost } from './env';

const TIMEOUT = 20000; // 20s

type DdcSessionId = string;

type CardinalDdcMessage = {
  Status: boolean;
  SessionId?: DdcSessionId;
  MessageType: 'profile.completed';
};

const createDdcForm = (document: Document, host: string, bin: string, jwt: string) => {
  const createInput = (name: string, value: string) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  };

  const createForm = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${host}/V1/Cruise/Collect`;
    form.appendChild(createInput('Bin', bin));
    form.appendChild(createInput('JWT', jwt));
    return form;
  };

  const form = createForm();
  document.body.appendChild(form);

  return form;
};

type DdcCache = {
  [bin: string]: {
    promise: Promise<DdcSessionId | null>;
  };
};

const _ddcCache: DdcCache = {};

export const submitCardinalDdcForm = (bin: string, jwt: string): Promise<DdcSessionId | null> => {
  if (_ddcCache[bin]) return _ddcCache[bin].promise;

  const promise = new Promise<DdcSessionId | null>(async (resolve) => {
    const start = new Date().getTime();
    const host = await getCardinalHost();

    const iframe = document.createElement('iframe');
    iframe.width = '1px';
    iframe.height = '1px';
    iframe.style.cssText = 'display: none;';

    const removeNodes = () => {
      iframe.parentNode?.removeChild(iframe);
      try {
        iframe?.remove?.();
      } catch {}
    };

    const cleanup = () => {
      clearTimeout(timeout);
      window.removeEventListener('message', messageEventListener);
      removeNodes();
    };

    const onResolve = (sessionId: string | null) => {
      const duration = new Date().getTime() - start;
      console.log(`Cardinal DDC resolve (${`${duration}ms`})`);
      cleanup();
      resolve(sessionId);
    };

    const parseJson = <T>(json: string): T | null => {
      try {
        return JSON.parse(json) as T;
      } catch {
        return null;
      }
    };

    const messageEventListener = (event: MessageEvent<string>) => {
      if (event.source !== iframe.contentWindow) return;

      const message = parseJson<CardinalDdcMessage>(event.data);
      console.log('Cardinal DDC message', message);

      if (!message?.SessionId) {
        const message = 'Cardinal DDC SessionId undefined';
        LogRocket.captureMessage(message);
        console.warn(message);
      }

      onResolve(message?.SessionId ?? null);
    };
    window.addEventListener('message', messageEventListener);

    iframe.onerror = () => {
      const error = 'Cardinal DDC iframe error';
      console.error(error);
      LogRocket.captureMessage(error);
      onResolve(null); // fail silent
    };

    let form: HTMLFormElement | null = null;
    iframe.onload = () => {
      if (!iframe.contentDocument || form) return;

      form = createDdcForm(iframe.contentDocument, host, bin, jwt);

      console.log('Cardinal DDC submit');
      form.submit();
    };

    const timeout = setTimeout(() => {
      const message = 'Cardinal DDC timeout';
      LogRocket.captureMessage(message);
      console.warn(message);
      onResolve(null);
    }, TIMEOUT);

    document.body.appendChild(iframe);
  });

  _ddcCache[bin] = { promise };

  return promise;
};
