import { setDefaultHeaders } from 'app/api';
import Checkout from 'components/Checkout';
import ErrorMessage from 'components/ErrorMessage';
import { useError, useEventMessageCallback, useSendMessage } from 'lib/hooks';
import { EventMessage, EventName, toEventMessage } from 'lib/messages';
import { toParams } from 'lib/toParams';
import { toSearchParams } from 'lib/toSearchParams';
import { Step } from 'lib/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutStep, setInitParams, setParams } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

const App = () => {
  const [error, setError] = useState<string>('');
  const [waitForInit, setWaitForInit] = useState<boolean>(true);

  const { params } = useSelector((state: ReduxState) => ({
    params: state.params,
  }));

  const sendMessage = useSendMessage();
  const dispatch = useDispatch();
  const onError = useError();

  /** add a solid color on body so cannot see through the page to see spinner of checkoutjs */
  useEffect(() => {
    document.body.style.background = '#fff';
  }, []);

  const onInitMessage = useCallback(
    (message: EventMessage) => {
      dispatch(setInitParams(message.event.eventData));
      setWaitForInit(false);
    },
    [dispatch],
  );

  const onEventMessage = useCallback(
    (message: EventMessage) => {
      message.event.eventName === EventName.Init && onInitMessage(message);
    },
    [onInitMessage],
  );

  useEventMessageCallback(onEventMessage);

  useEffect(() => {
    setError('');
    try {
      const _params = toParams(toSearchParams(window.location.search));
      setDefaultHeaders(_params);
      dispatch(setParams(_params));
      setWaitForInit(_params.waitForInit || false);
      _params.paymentSourceOnly && dispatch(setCheckoutStep(Step.PaymentSource));
      _params.waitForInit && sendMessage(toEventMessage(EventName.Ready, _params.elementId));
    } catch (error) {
      setError(onError(error));
    }
  }, [dispatch, onError, sendMessage]);

  if (error) {
    return <ErrorMessage messageBody={error} />;
  }

  if (waitForInit || !params) {
    return <></>;
  }

  return <Checkout />;
};

export default App;
