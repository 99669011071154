import { KybResult } from 'app/api.april/customers/administerOrganisation';
import { Alert, AlertProps } from 'components/Alert';
import BackBtn from 'components/BackBtn';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';
import * as Yup from 'yup';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Heading, Icon, Text, TextField } from '@limepayments/cosmic';

const Address = (props: { address: KybResult['businessAddress'] }) => {
  const address = useMemo(() => {
    if (!props.address) return null;

    const { unitNumber, streetNumber, streetName, streetType, suburb, city, postcode, state, country } = props.address;

    return [
      `${unitNumber ? `${unitNumber} ` : ''}${streetNumber} ${streetName}${streetType ? ` ${streetType}` : ''}`,
      `${suburb ? `${suburb} ` : ''}${state} ${postcode}`,
      city,
      country,
    ]
      .filter(Boolean)
      .filter((value) => value !== '(none)');
  }, [props.address]);

  return <>{address?.length ? address.join(', ') : '-'}</>;
};

type FormValues = {
  abn: string;
};

export type SearchStepProps = {
  kybResult: KybResult | null;
  onBack?(): void;
  onSearch?(abn: string): Promise<AlertProps | void>;
  onConnect?(): void;
};

export const SearchStep = ({ kybResult, onBack, onSearch, onConnect }: SearchStepProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const handleSearch = useCallback(
    async ({ abn }: FormValues) => {
      setAlert(null);
      setIsLoading(true);

      const alert = await onSearch?.(abn);

      setAlert(alert ?? null);
      setIsLoading(false);
    },
    [onSearch],
  );

  const form = useFormik<FormValues>({
    initialValues: {
      abn: kybResult?.businessIdentifier.Abn.abn ?? '',
    },
    validationSchema: Yup.object({
      abn: Yup.string()
        .required('ABN is required')
        .matches(/^((\d{9})|(\d{11}))$/, 'ABN is invalid'),
    }),
    onSubmit: handleSearch,
  });

  const { touched, values, errors, handleChange, handleBlur } = form;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        fontFamily: 'var(--lp-fonts-body-2)',
        color: 'rgb(var(--lp-colors-neutral-700))',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <BackBtn onClick={onBack} />
        <Heading tagName="h2" variant="xs">
          Connect your business
        </Heading>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
            color: 'rgb(var(--lp-colors-neutral-600))',
            backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
            border: '1px solid rgb(var(--lp-colors-neutral-400))',
            borderRadius: 4,
          }}
        >
          <Text variant="body-3">
            To connect your business to your email address, search your ABN (Australian Business Number) or ACN
            (Australian Company Number) below.
            <br />
            <br />
            <i>This payment method is only eligible for businesses with an ABN or ACN.</i>
          </Text>
          <TextField
            name="abn"
            type="text"
            inputMode="numeric"
            label="ABN or ACN"
            fullWidth
            required
            value={values.abn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.abn ? errors.abn : ''}
            disabled={!!kybResult}
          />
          {!!kybResult && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                padding: 16,
                backgroundColor: 'rgb(var(--lp-colors-neutral-50))',
              }}
            >
              <div css={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <Icon name="Business" />
                <h3 css={{ fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase' }}>{kybResult.businessName}</h3>
              </div>
              <div css={{ height: 1, backgroundColor: 'rgb(var(--lp-colors-neutral-100))' }} />
              <table
                css={{
                  padding: 8,
                  fontSize: 12,
                  'th, td': { padding: 4, textAlign: 'left' },
                  th: { width: 0, paddingRight: 24, whiteSpace: 'nowrap' },
                }}
              >
                <tr>
                  <th>ACN</th>
                  <td>{kybResult.acn ?? '-'}</td>
                </tr>
                <tr>
                  <th>ABN</th>
                  <td>{kybResult.businessIdentifier.Abn.abn}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{kybResult.status}</td>
                </tr>
                <tr>
                  <th>Registered</th>
                  <td>{kybResult.dateRegistered}</td>
                </tr>
                <tr>
                  <th>Entity type</th>
                  <td>{kybResult.businessType}</td>
                </tr>
                <tr>
                  <th>Business address</th>
                  <td>
                    <Address address={kybResult.businessAddress} />
                  </td>
                </tr>
              </table>
            </div>
          )}
          {!!alert && <Alert {...alert} />}
          {!kybResult && (
            <Button
              size="large"
              variant="primary"
              className="lp-w-full"
              onClick={form.submitForm}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Search
            </Button>
          )}
          {!!kybResult && (
            <>
              <Button size="large" variant="primary" className="lp-w-full" onClick={onConnect}>
                Connect business
              </Button>
              <Button size="small" variant="ghost" className="lp-w-full" onClick={onBack}>
                This isn't my business
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
