import { toCurrency } from 'lib/currency';
import { usePaymentTotal } from 'lib/hooks';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Text, Tooltip } from '@limepayments/cosmic';

const styles = {
  wrapper: css({
    padding: '12px 16px',
    borderRadius: 4,
    color: 'rgb(var(--lp-colors-neutral-600))',
    backgroundColor: 'rgb(var(--lp-colors-neutral-50))',
  }),
  lineItem: css({ display: 'flex', justifyContent: 'space-between' }),
  infoIcon: css({ cursor: 'help', '& svg': { width: 18, height: 18 } }),
  surchargeLabel: css({ display: 'flex', alignItems: 'center', gap: 4 }),
};

const InfoIcon = () => (
  <span css={styles.infoIcon}>
    <Icon name="Info" />
  </span>
);

const SurchargeLabel = ({ tooltip }: { tooltip?: string }) => {
  return (
    <span css={styles.surchargeLabel}>
      <span>Surcharge</span>
      <Tooltip tooltip={tooltip} isPortal>
        <span style={{ visibility: tooltip ? 'visible' : 'hidden' }}>
          <InfoIcon />
        </span>
      </Tooltip>
    </span>
  );
};

export const PaymentTotal = () => {
  const { config, surchargeQuote } = useSelector((state: ReduxState) => ({
    params: state.params,
    config: state.config,
    surchargeQuote: state.surchargeQuote,
  }));

  const { subtotal, surcharge, total, initialPaymentTotal } = usePaymentTotal();

  const showSurcharge = useMemo(
    () => config?.surchargeQuoteRequired || (!!subtotal && !!surcharge),
    [config, subtotal, surcharge],
  );

  return (
    <div css={styles.wrapper} data-testid={`paymentTotal`}>
      {showSurcharge && (
        <>
          <Text variant="caption" css={styles.lineItem}>
            <span>Subtotal</span>
            <span data-testid={`subtotal`}>{!!subtotal ? toCurrency(subtotal) : '-'}</span>
          </Text>
          <Text variant="caption" css={styles.lineItem}>
            <SurchargeLabel tooltip={surchargeQuote?.summary} />
            <span data-testid={`surcharge`}>{!!surcharge ? toCurrency(surcharge) : 'TBA'}</span>
          </Text>
        </>
      )}
      {initialPaymentTotal === null && (
        <Text
          variant="body-2"
          isEmphasised
          css={css(styles.lineItem, {
            borderTop: showSurcharge ? '1px solid rgb(var(--lp-colors-neutral-300))' : 'none',
          })}
        >
          <span>Total</span>
          <span data-testid={`total`}>{toCurrency(total)}</span>
        </Text>
      )}
      {initialPaymentTotal !== null && (
        <>
          <Text variant="caption" css={styles.lineItem}>
            <span>Total</span>
            <span data-testid={`total`}>{toCurrency(total)}</span>
          </Text>
          <Text
            variant="body-2"
            isEmphasised
            css={css(styles.lineItem, {
              borderTop: '1px solid rgb(var(--lp-colors-neutral-300))',
            })}
          >
            <span>Pay today</span>
            <span data-testid={`initialPaymentTotal`}>{toCurrency(initialPaymentTotal)}</span>
          </Text>
        </>
      )}
    </div>
  );
};

export default PaymentTotal;
