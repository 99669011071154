import { ErrorResponse } from 'app/apiTypes';

export const STATE_ERROR_MESSAGE = 'Invalid checkout state';

export const CARD_ERROR_MESSAGE = 'Invalid card';

export const CREATE_PAYPLAN_ERROR_MESSAGE = 'Invalid payment plan';

export const PAYPLAN_INCOMPLETE_ERROR_MESSAGE = 'Plan creation incomplete or terms not accepted';

export const GET_PAYPLAN_DATA_ERROR_MESSAGE = 'Invalid amount';

export const GET_PAYPLAN_DATA_DATE_ERROR_MESSAGE = 'Invalid date';

export const CUSTOMER_TOKEN_ERROR_MESSAGE = 'Invalid customer token';

export const FORM_VALIDATION_ERROR_MESSAGE = 'Please fix invalid fields';

export const SURCHARGE_REQUIRED_ERROR_MESSAGE = 'Please wait, surcharge quote required';

export const PAYMENT_TOKEN_ERROR_MESSAGE = 'Invalid payment token';

export class ApiError extends Error {
  payload: ErrorResponse;

  constructor(message: string, payload: ErrorResponse) {
    super(message);

    this.name = 'ApiError';
    this.payload = payload;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

export const getErrorMessage = (error: any, getApiErrorMessage?: (error: ErrorResponse) => string): string => {
  const isText = (text: any): boolean => typeof text === 'string' && !!text.length;
  if (isText(error)) return error;
  if (isApiError(error) && isText(error.message)) return getApiErrorMessage?.(error.payload) || error.message;
  if (isText(error?.message)) return error.message;
  return 'Unknown error';
};
