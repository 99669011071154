import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';

export type Ubo = {
  givenName?: string;
  middleName?: string;
  familyName: string;
  address: {
    unitNumber?: string;
    streetNumber: string;
    streetName: string;
    streetType?: string;
    suburb?: string;
    city: string;
    postcode: string;
    state?: string;
    country: string;
  };
};

export type KybResult = {
  delegateEmailAddress: string;
  businessIdentifier: {
    Abn: {
      abn: string;
    };
  };
  acn?: string;
  businessName: string;
  status: string;
  dateRegistered: string;
  businessType: string;
  businessAddress: {
    unitNumber?: string;
    streetNumber: string;
    streetName: string;
    streetType?: string;
    suburb?: string;
    city: string;
    postcode: string;
    state?: string;
    country: string;
  };
  ubos: Ubo[];
};

export type AdministerOrganisationRequest =
  | {
      CreateOrganisationCustomerAndDelegate: {
        uboIndex: number;
        b2bProspectToken: string;
      };
    }
  | {
      PerformKyb: {
        businessIdentifier: {
          Abn: {
            abn: string;
          };
        };
        b2bProspectToken: string;
      };
    };

export type AdministerOrganisationResponse =
  | {
      CreateOrganisationCustomerAndDelegateResponse: {
        organisationCustomerId: string;
        customerDelegateUserId: string;
        customToken: string;
      };
    }
  | {
      KybExistingOrganisationCustomer: {
        organisationCustomerId: string;
        businessName: string;
        b2bProspectToken: string;
      };
    }
  | {
      KybNewOrganisationCustomer: {
        result: KybResult;
        b2bProspectToken: string;
      };
    };

export const administerOrganisation = async (
  token: string,
  payload: AdministerOrganisationRequest,
): Promise<AdministerOrganisationResponse> =>
  fetch(`${await getAprilApiHost()}/customers/enterprise/organisation`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<AdministerOrganisationResponse>(handleResponse);
