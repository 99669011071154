import CounterOfferStep from 'components/CounterOfferStep';
import ErrorMessage from 'components/ErrorMessage';
import { KybStep } from 'components/KybStep/KybStep';
import KycStep from 'components/KycStep';
import { OtpStep } from 'components/OtpStep/OtpStep';
import { PayToStep } from 'components/PayToStep';
import PaymentSelectionStep from 'components/PaymentSelectionStep';
import PaymentSourceStep from 'components/PaymentSourceStep';
import PlanReviewStep from 'components/PlanReviewStep';
import { SpinnerWrapper } from 'lib/commonStyles';
import { PAYPLAN_INCOMPLETE_ERROR_MESSAGE } from 'lib/errorMessages';
import hexToRGB from 'lib/hexToRGB';
import { useError, useMount, useSendMessage, useSubmitMessageCallback } from 'lib/hooks';
import { toResizeMessage } from 'lib/messages';
import { Step } from 'lib/types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { ReduxState } from 'redux/reduxTypes';

import { Global } from '@emotion/react';
import { Spinner } from '@limepayments/cosmic';

import { Container } from './styles';

export interface CheckoutType {}

const Checkout = (props: CheckoutType) => {
  const [error, setError] = useState<string>('');
  const [payPlanError, setPayPlanError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const didMountRef = useRef(true);
  const { checkoutStep, params } = useSelector((state: ReduxState) => ({
    checkoutStep: state.checkoutStep,
    params: state.params,
  }));

  const onMount = useMount();
  const onError = useError();
  const sendMessage = useSendMessage();

  const primaryColor = useMemo(() => (params?.primaryColor ? hexToRGB(params.primaryColor) : ''), [params]);

  useEffect(() => {
    if (didMountRef.current === false) {
      return;
    }

    didMountRef.current = false;
    const fetchOnMount = async () => {
      try {
        setLoading(true);
        await onMount();
        setError('');
      } catch (error) {
        setError(onError(error));
      } finally {
        setLoading(false);
      }
    };
    fetchOnMount();
  }, [onMount, onError]);

  const handleSubmitMessage = useCallback(() => {
    const canSubmit = [Step.PaymentSource, Step.PaymentSelection, Step.PlanReview].includes(checkoutStep);
    !canSubmit && onError(PAYPLAN_INCOMPLETE_ERROR_MESSAGE);
  }, [checkoutStep, onError]);

  useSubmitMessageCallback(handleSubmitMessage);

  /**
   * Container has padding: 16px,
   * so the totalHeight === height + padding-top + padding-bottom
   */
  const onResize = useCallback(
    (width: number | undefined, height: number | undefined) => {
      sendMessage(toResizeMessage((height || 0) + 32, params?.elementId || ''));
    },
    [sendMessage, params],
  );

  const { ref } = useResizeDetector({
    onResize,
    refreshMode: 'throttle',
    refreshRate: 150,
  });

  return (
    <Container ref={ref}>
      <Global
        styles={{
          body: {
            '--lp-colors-primary': primaryColor || '0,22,209',
            overflow: `${params?.scroll ? 'auto' : 'hidden'} !important`,
          },
        }}
      />
      <ErrorMessage messageBody={error} />
      {loading && (
        <SpinnerWrapper>
          <Spinner variant="simple" isVisible />
        </SpinnerWrapper>
      )}
      {checkoutStep === Step.PaymentSource && <PaymentSourceStep />}
      {checkoutStep === Step.PaymentSelection && <PaymentSelectionStep />}
      {checkoutStep === Step.Otp && <OtpStep />}
      {checkoutStep === Step.Kyb && <KybStep />}
      {checkoutStep === Step.Kyc && <KycStep setPayPlanError={setPayPlanError} />}
      {checkoutStep === Step.CounterOffer && <CounterOfferStep />}
      {checkoutStep === Step.PlanReview && <PlanReviewStep payPlanError={payPlanError} />}
      {checkoutStep === Step.PayTo && <PayToStep />}
    </Container>
  );
};

export default Checkout;
