import { getAuth, getIdTokenResult } from 'firebase/auth';
import LogRocket from 'logrocket';

import { Env, getCheckoutHost, getEnv } from './env';
import { defaultInitOptions } from './logrocket.lib';
import { UserClaims } from './types';

export { logRocketTrack } from './logrocket.lib';

const INIT_DELAY = 0; //5000; // 5s

export const getSessionUrl = () => new Promise<string>((resolve) => LogRocket.getSessionURL(resolve));

const getAppId = (env: Env): string | null => {
  if (env === 'dev') return null;
  return `gx6dpd/limepay-${env}`;
};

let timeout: NodeJS.Timeout | null = null;
export const logRocketInit = (onInit?: (sessionUrl: string) => void) => {
  if (timeout) return;

  timeout = setTimeout(async () => {
    const env = await getEnv();
    const rootHostname = await getCheckoutHost();
    const appId = getAppId(env);

    if (appId) {
      LogRocket.init(appId, {
        ...defaultInitOptions,
        release: '3.0.0',
        rootHostname,
      });
      onInit?.(await getSessionUrl());
    }
  }, INIT_DELAY);
};

export const logRocketIdentify = async () => {
  const auth = getAuth();
  if (!auth.currentUser) return;

  const idTokenResult = await getIdTokenResult(auth.currentUser);
  if (!idTokenResult) return;

  const claims = idTokenResult.claims as unknown as UserClaims;
  LogRocket.identify(claims.limepay.customerId, {
    email: claims.email,
  });
};
