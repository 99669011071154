import {
  CreatePayPlanOfferResponse,
  PayPlanParameters,
  PayToSendAgreementRequest,
  SurchargeQuote,
} from 'app/api.april';
import { Config, SendCustomerVerification } from 'app/apiTypes';
import { Params } from 'lib/toParams';
import {
  ApplePayPaymentSource,
  CardUsageScope,
  CurrentUser,
  GooglePayPaymentSource,
  PaymentType,
  SavedCardPaymentSource,
  SelectedPaymentSource,
  Step,
} from 'lib/types';

import { Vault } from '@april/lib-ui';

export type InitParams = {
  customerToken?: string;
};
/**
 * contactDetailsPhone: user can edit params.phone, so need a new state
 * same for contactDetailsEmail
 * customToken: from verifyCustomer API response, used for kyc
 * defaultSchedule: if eligibility api is denied, we need to show default schedules on PlanReview
 * vgsCardCvc: saved card form
 */

export type ReduxState = {
  config: Config | null;
  initParams: InitParams | null;
  params: Params | null;
  paymentType: PaymentType;
  getVaultCard?: () => Vault;
  getVaultCvc?: () => Vault;
  error: string;
  customerId: string;
  emailVerificationCode: string;
  phoneVerificationCode: string;
  waitForInit: boolean;
  currentUser?: CurrentUser;
  emailVerification: SendCustomerVerification['emailVerification'];
  phoneVerification: SendCustomerVerification['phoneVerification'];
  payPlanEligibility: CreatePayPlanOfferResponse | null;
  cardPaymentSources: SavedCardPaymentSource[];
  selectedCardPaymentSource: SavedCardPaymentSource | null;
  applePayPaymentSource: ApplePayPaymentSource | null;
  googlePayPaymentSource: GooglePayPaymentSource | null;
  checkoutStep: Step;
  contactDetailsPhone: string;
  contactDetailsEmail: string;
  customToken: string;
  payPlanVariants: PayPlanParameters[] | null;
  selectedPayPlanVariant: PayPlanParameters | null;
  selectedPaymentSource: SelectedPaymentSource | null;
  surchargeQuote: SurchargeQuote | null;
  payToAgreementRequest: PayToSendAgreementRequest | null;
  payToToken: string | null;
  b2bCardUsageScope: CardUsageScope | null;
  b2bProspectToken: string | null;
};

export const initialState: ReduxState = {
  config: null,
  initParams: null,
  params: null,
  paymentType: 'paycard',
  error: '',
  customerId: '',
  emailVerificationCode: '',
  phoneVerificationCode: '',
  waitForInit: true,
  emailVerification: {
    customerId: '',
    emailAddress: '',
    createdAt: '',
  },
  phoneVerification: {
    customerId: '',
    phoneNumber: '',
    createdAt: '',
  },
  payPlanEligibility: null,
  cardPaymentSources: [],
  selectedCardPaymentSource: null,
  applePayPaymentSource: null,
  googlePayPaymentSource: null,
  checkoutStep: Step.PaymentSelection,
  contactDetailsPhone: '',
  contactDetailsEmail: '',
  customToken: '',
  payPlanVariants: null,
  selectedPayPlanVariant: null,
  selectedPaymentSource: null,
  surchargeQuote: null,
  payToAgreementRequest: null,
  payToToken: null,
  b2bCardUsageScope: null,
  b2bProspectToken: null,
};

export type ActionType = {
  type: string;
  payload: any;
};

export type DispatchType = (args: ActionType) => ActionType;
