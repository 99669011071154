import { GetCustomerResponse } from '../app/apiTypes';

export type PayPlanVariant =
  | 'payplan_fortnight_25_25_25_25'
  | 'payplan_4_weekly_payments'
  | 'payplan_8_weekly_payments'
  | 'payplan_26_fortnightly_payments'
  | 'payplan_52_weekly_payments'
  | 'payplan_2_monthly_payments'
  | 'payplan_3_monthly_payments'
  | 'payplan_4_monthly_payments'
  | 'payplan_5_monthly_payments'
  | 'payplan_6_monthly_payments'
  | 'payplan_7_monthly_payments'
  | 'payplan_8_monthly_payments'
  | 'payplan_9_monthly_payments'
  | 'payplan_10_monthly_payments'
  | 'payplan_11_monthly_payments'
  | 'payplan_12_monthly_payments'
  | 'payplan_fortnight_0_100'
  | 'payplan_4_week_0_100'
  | 'payplan_7_week_0_100'
  | 'payplan_30_days_0_100';

export enum Step {
  PaymentSource = 'PaymentSource',
  PaymentSelection = 'PaymentSelection',
  Otp = 'Otp',
  Kyb = 'Kyb',
  Kyc = 'Kyc',
  CounterOffer = 'CounterOffer',
  PlanReview = 'PlanReview',
  PayTo = 'PayTo',
}

export type PaymentType = 'paycard' | 'payplan'; // DEPRECIATED, use "PayType" instead

export type PayType = 'PayInFull' | 'PayPlan';

export type PaymentMethodType = 'Card' | 'NetworkToken' | 'DirectDebit' | 'PayTo';

export enum MerchantCountry {
  AU = 'AU',
  NZ = 'NZ',
}

export enum Currency {
  AUD = 'AUD',
  NZD = 'NZD',
}

export const ALLOW_CURRENCIES = [Currency.AUD, Currency.NZD];

export const ALLOW_VERSIONS = ['3.0.0'];

export enum CardUsageScope {
  delegate = 'ScopeDelegate',
  organisation = 'ScopeOrganisation',
}

export type CardPaymentSourceBrand =
  | ''
  | 'American Express'
  | 'MasterCard'
  | 'Visa'
  | 'Diners Club'
  | 'Discover'
  | 'UnionPay'
  | 'Maestro'
  | 'Elo'
  | 'Mir'
  | 'Hiper'
  | 'Hipercard';

export type CardPaymentSourceFunding = 'credit' | 'debit' | 'chargecard' | 'prepaid' | 'deferreddebit' | 'unknown';

export type CardPaymentSource = {
  last4: string;
  cardBin: string;
  brand?: CardPaymentSourceBrand;
  funding?: CardPaymentSourceFunding;
  expMonth: number;
  expYear: number;
  country: string;
};

export type SavedCardPaymentSource = {
  cardPaymentSourceId: string;
  isDeleted?: boolean;
} & CardPaymentSource;

export type PaymentSource = {
  cardPaymentSource?: CardPaymentSource | SavedCardPaymentSource;
};

export type PaymentData = {
  amount: number;
  currency: Currency;
  paymentType: PaymentType; // DEPRECIATED, use "PayType" instead - included for backwards compatibility only
  payType: PayType;
  paymentMethodType: PaymentMethodType;
  paymentSource?: PaymentSource;
};

export type ApplePayPaymentSource = ApplePayJS.ApplePayPaymentToken;

export type GooglePayPaymentSource = google.payments.api.PaymentData['paymentMethodData'];

export type UserClaims = {
  email: string;
  limepay: {
    customerAccountId: string;
    customerId: string;
    merchantId: string;
  };
};

export type CurrentUser = GetCustomerResponse;

export enum SelectedPaymentSource {
  applePay = 'applePay',
  googlePay = 'googlePay',
  newCard = 'newCard',
  savedCard = 'savedCard',
  directDebit = 'directDebit',
  payTo = 'payTo',
}
