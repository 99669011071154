import { Config } from 'app/apiTypes';
import { Params } from 'lib/toParams';

const isB2B = (params: Params, config: Config) => {
  if (
    (params.isB2B === true && !config.supportedCustomerTypes.includes('organisationcustomertype')) ||
    (params.isB2B === false && !config.supportedCustomerTypes.includes('consumercustomertype'))
  ) {
    throw Error(`Customer type not supported: ${params.isB2B ? 'B2B' : 'B2C'}`);
  }

  return (
    params.isB2B || config.supportedCustomerTypes.every((CustomerType) => CustomerType === 'organisationcustomertype')
  );
};

export default isB2B;
