import { KybResult } from 'app/api.april/customers/administerOrganisation';
import { Alert, AlertProps } from 'components/Alert';
import BackBtn from 'components/BackBtn';
import Disclaimer from 'components/PayPlanSelectionStep/Disclaimer';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Heading, Icon, Select } from '@limepayments/cosmic';
import { OptionType } from '@limepayments/cosmic/build/Select/types';

export type CreateStepProps = {
  kybResult: KybResult;
  onBack?(): void;
  onSubmit?({ uboIndex }: { uboIndex: number }): Promise<AlertProps | void>;
};

export const CreateStep = ({ kybResult, onBack, onSubmit }: CreateStepProps) => {
  const [uboIndex, setUboIndex] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const uboOptions: OptionType[] = useMemo(() => {
    return kybResult.ubos.map(({ givenName, middleName, familyName }, index) => {
      const value = String(index);
      const text = [givenName, middleName, familyName].filter((item) => !!item).join(' ');
      return { value, text };
    });
  }, [kybResult]);

  const handleSubmit = useCallback(async () => {
    setAlert(null);
    setIsLoading(true);

    const index = Number(uboIndex);
    const alert = await onSubmit?.({ uboIndex: index });

    setAlert(alert ?? null);
    setIsLoading(false);
  }, [onSubmit, uboIndex]);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        fontFamily: 'var(--lp-fonts-body-2)',
        color: 'rgb(var(--lp-colors-neutral-700))',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <BackBtn onClick={onBack} />
        <Heading tagName="h2" variant="xs">
          Customer information
        </Heading>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
            color: 'rgb(var(--lp-colors-neutral-600))',
            backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
            border: '1px solid rgb(var(--lp-colors-neutral-400))',
            borderRadius: 4,
          }}
        >
          <div css={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <Icon name="Business" />
            <h3 css={{ fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase' }}>{kybResult.businessName}</h3>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
            fontSize: 14,
            color: 'rgb(var(--lp-colors-neutral-600))',
            backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
            border: '1px solid rgb(var(--lp-colors-neutral-400))',
            borderRadius: 4,
          }}
        >
          <Select
            label="Select UBO"
            helperText="Select an Ultimate Beneficial Owner (UBO) or Director."
            options={uboOptions}
            value={uboIndex}
            onChange={(uboIndex) => setUboIndex(uboIndex)}
          />
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              padding: 16,
              backgroundColor: 'rgb(var(--lp-colors-neutral-50))',
            }}
          >
            <div>
              <b>To be eligible for this payment method:</b>
            </div>
            <div>&bull; You must be an Ultimate Beneficial Owner (UBO) or Company Director of the business</div>
            <div>&bull; Provide your AU driver licence or passport number (one-off process)</div>
            <div>&bull; Meet our credit score requirement</div>
          </div>
          <Disclaimer />
          {!!alert && <Alert {...alert} />}
          <Button
            size="large"
            variant="primary"
            className="lp-w-full"
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={!uboIndex || isLoading}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
