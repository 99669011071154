import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';
import { Currency } from 'lib/types';

export type PayToAliasType = 'Phone' | 'Email' | 'Abn' | 'OrganisationIdentifier';

export type PayToLookupAliasResponse = {
  displayName: string;
};

export const payToLookupAlias = async (
  token: string,
  aliasType: PayToAliasType,
  aliasValue: string,
): Promise<PayToLookupAliasResponse> =>
  fetch(`${await getAprilApiHost()}/payto/alias?aliasType=${aliasType}&aliasValue=${encodeURIComponent(aliasValue)}`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<PayToLookupAliasResponse>(handleResponse);

export type PayToSendAgreementRequest = {
  customerName: string;
  customerAccIdentifier:
    | {
        Alias: {
          aliasType: PayToAliasType;
          value: string;
        };
      }
    | { Bban: { value: string } };
  terms:
    | {
        AgreementRequestTermsOneOff: {
          amount: {
            amount: number;
            currency: Currency;
          };
        };
      }
    | {
        AgreementRequestTermsOpen: {};
      };
};

export type PayToSendAgreementResponse = {
  token: string;
};

export const payToSendAgreement = async (
  token: string,
  payload: PayToSendAgreementRequest,
): Promise<PayToSendAgreementResponse> =>
  fetch(`${await getAprilApiHost()}/payto/agreement`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<PayToSendAgreementResponse>(handleResponse);

export type PayToGetAgreementStateResponse = {
  status: 'Pending' | 'Created' | 'Active' | 'Suspended' | 'Cancelled' | 'Declined' | 'Failed' | 'Expired';
};

export const payToGetAgreementState = async (
  token: string,
  payToToken: string,
): Promise<PayToGetAgreementStateResponse> =>
  fetch(`${await getAprilApiHost()}/payto/agreement?token=${payToToken}`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<PayToGetAgreementStateResponse>(handleResponse);
