import { MerchantCountry } from 'lib/types';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

/**
 * all the methods in this file are pretty hacky.
 */

export const getCountryCode = (country: MerchantCountry) =>
  country === MerchantCountry.AU ? '+61' : country === MerchantCountry.NZ ? '+64' : '';

/**
 * convert 04xx to +614xx (AU) or +644xx (NZ)
 * and validate if it is valid
 */
export const convertToE164Format = (phone: string, country: MerchantCountry) => {
  if (!phone) {
    return {
      _phone: '',
      _valid: false,
    };
  }

  let _phone = phone;
  if (_phone.charAt(0) === '0') {
    const countryCode = getCountryCode(country);
    _phone = countryCode + phone.slice(1);
  }
  /**
   * isValidPhoneNumber() function may one day become outdated on a website
   * that isn't actively maintained anymore
   */
  const valid = isPossiblePhoneNumber(_phone);

  return {
    _valid: valid,
    _phone: valid ? _phone : '',
  };
};
