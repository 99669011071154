import ErrorMessage from 'components/ErrorMessage';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

import { Button, Icon, Text } from '@limepayments/cosmic';

import CardBrand, { CardBrandProps } from './CardBrand';
import PaymentTotal from './PaymentTotal';
import * as s from './styles';

export interface PaymentMethodProps {
  brand: CardBrandProps['brand'];
  label: ReactNode;
  isMounted?: boolean;
  isOpen: boolean;
  hidePaymentTotal?: boolean;
  submitDisabled?: boolean;
  submitLabel?: ReactNode;
  error?: string;
  testId?: string;
  children: ReactNode;
  onToogle(): void;
  onSubmit?(): void;
}

export const PaymentMethod = ({
  brand,
  label,
  isMounted,
  isOpen,
  hidePaymentTotal,
  submitDisabled,
  submitLabel,
  error,
  testId,
  children,
  onToogle,
  onSubmit,
}: PaymentMethodProps) => {
  const { params } = useSelector((state: ReduxState) => ({
    params: state.params,
  }));

  const showPaymentTotal = hidePaymentTotal !== true && !params?.paymentSourceOnly;

  return (
    <s.PaymentMethodWrapper isOpen={isOpen} data-testid={`paymentMethod-${testId ?? ''}`}>
      <s.PaymentMethodButton onClick={onToogle}>
        <CardBrand brand={brand} />
        <div>
          <Text tagName="span" className="neutral-600" variant="body-2" testId="paymentMethodLabel">
            {label}
          </Text>
        </div>
        <s.ChevronDown className={`${isOpen ? 'up' : ''}`}>
          <Icon name="ChevronDown" className="chevronDown" />
        </s.ChevronDown>
      </s.PaymentMethodButton>
      <s.PaymentMethodDetailsWrapper show={isOpen}>
        {(isMounted || isOpen) && (
          <s.PaymentMethodDetails>
            {children}
            {showPaymentTotal && <PaymentTotal />}
            <ErrorMessage messageBody={error ?? ''} />
            {!!onSubmit && (
              <Button
                onClick={onSubmit}
                size="large"
                variant="primary"
                className="lp-w-full"
                disabled={submitDisabled}
                testId="submitBtn"
              >
                {submitLabel ?? 'Complete purchase'}
              </Button>
            )}
          </s.PaymentMethodDetails>
        )}
      </s.PaymentMethodDetailsWrapper>
    </s.PaymentMethodWrapper>
  );
};

export default PaymentMethod;
