import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

const redactedKeys = [
  // https://github.com/LimePayments/limepay-api/blob/master/modules/services-model/src/main/scala/limepay/data/rep/semiauto.scala#L16-L46
  'address',
  'accountId',
  'accountName',
  'bankAccount',
  'cardStatementName',
  'creditcard',
  'dateofbirth',
  'debitcard',
  'dob',
  'fax',
  'familyName',
  'givenName',
  'lastVerifCode',
  'lastName',
  'licence',
  'license',
  'maiden',
  'middleName',
  'passport',
  'password',
  'phone',
  'pwd',
  'secret',
  'ssn',
  'stripe',
  'surName',
  'taxfile',
  'taxId',
  'token',
];

const redactedUrlParams = [
  'customerFirstName',
  'firstName',
  'fn',
  'customerMiddleName',
  'middleName',
  'mn',
  'customerLastName',
  'lastName',
  'ln',
  'phone',
  'customerResidentialAddress',
  'address',
  'addr',
  'addressIdentifier',
  'addrId',
  'dateOfBirth',
  'dob',
  'customerToken',
  'customToken',
  'token',
];

const redactedHeaders = ['authorization', 'limepay-publickey', 'limepay-secretkey', 'limepay-token'];

const fuzzySanitizer = LogrocketFuzzySanitizer.setup(redactedKeys);

export const urlSanitizer = (url: string) =>
  redactedUrlParams.reduce(
    (url, param) => url.replace(new RegExp(`${param}=([^&]*)`, 'g'), `${param}=**redacted**`),
    url,
  );

export const headersSanitizer = (headers: { [key: string]: string | undefined }) =>
  redactedHeaders.reduce((headers, header) => {
    if (headers[header]) {
      headers[header] = '**redacted**';
    }
    return headers;
  }, headers);

interface IRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}

export const requestSanitizer = (request: IRequest): IRequest => {
  request.url = urlSanitizer(request.url);
  request.headers = headersSanitizer(request.headers);
  return fuzzySanitizer.requestSanitizer(request as any);
};

interface IResponse {
  reqId: string;
  status?: number;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

export const responseSanitizer = (response: IResponse): IResponse => {
  response.headers = headersSanitizer(response.headers);
  return fuzzySanitizer.responseSanitizer(response as any);
};

type IOptions = Parameters<typeof LogRocket.init>[1];

export const defaultInitOptions: IOptions = {
  dom: {
    inputSanitizer: true,
  },
  browser: {
    urlSanitizer,
  },
  network: {
    requestSanitizer,
    responseSanitizer,
  },
};

export type LogRocketSessionData = Partial<{
  merchantId: string;
  marketplaceId: string;
  sessionCorrelationId: string;
  checkoutVersion: string;
  platform: string;
  platformVersion: string;
  platformPluginVersion: string;
  requestId: string;
  tracer: string;
  statusCode: number;
  errorCode: string;
  message: string;
}>;

export const logRocketTrack = (data: LogRocketSessionData) => LogRocket.track('session', data);
