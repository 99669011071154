import { sessionId } from 'app/api';
import BackBtn from 'components/BackBtn';
import { SpinnerWrapper } from 'lib/commonStyles';
import { useError, usePayPlanEligibilityCheck } from 'lib/hooks';
import { Step } from 'lib/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutStep } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

import { Spinner } from '@limepayments/cosmic';

declare global {
  interface Window {
    LimepayIdentity: any;
  }
}

type KycStepType = {
  setPayPlanError(error: string): void;
};

const KycStep = ({ setPayPlanError }: KycStepType) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { params, currentUser, initParams, customToken } = useSelector((state: ReduxState) => ({
    params: state.params,
    currentUser: state.currentUser,
    initParams: state.initParams,
    customToken: state.customToken,
  }));

  const dispatch = useDispatch();
  const onPayPlanEligibilityCheck = usePayPlanEligibilityCheck();
  const onError = useError();

  useEffect(() => {
    /**
     * customToken is returned from verification codes for new customer
     * initParams.customerToken is for existing customer.
     * we will do kyc for both new customer and existing customer.
     */
    const token = currentUser ? initParams?.customerToken || '' : customToken;
    if (!params || !token) {
      return;
    }

    let componentDidUnmount = false;
    window.LimepayIdentity.render(
      {
        sessionId,
        elementId: 'identityCont',
        publicKey: params.publicKey,
        customToken: token,
        dateOfBirth: '',
        firstName: params.customerFirstName ?? '',
        middleName: params.customerMiddleName ?? '',
        lastName: params.customerLastName ?? '',
        address: params.customerResidentialAddress ?? '',
        primaryColour: params.primaryColor,
        isB2B: params.isB2B,
      },
      () => {
        if (componentDidUnmount) return;

        onError('Kyc denied or attempts exhausted');
      },
      async () => {
        if (componentDidUnmount) return;

        try {
          setLoading(true);
          setPayPlanError('');
          await onPayPlanEligibilityCheck();
        } catch (e) {
          setPayPlanError(onError(e));
          /* navigate to PlanReviewStep, and display the error there */
          dispatch(setCheckoutStep(Step.PlanReview));
        } finally {
          setLoading(false);
        }
      },
    );

    return () => {
      componentDidUnmount = true;
    };
  }, [currentUser, customToken, dispatch, initParams, onPayPlanEligibilityCheck, onError, params, setPayPlanError]);

  return (
    <>
      {loading && (
        <SpinnerWrapper>
          <Spinner variant="simple" isVisible />
        </SpinnerWrapper>
      )}
      <BackBtn onClick={() => dispatch(setCheckoutStep(Step.PaymentSelection))} />
      <div style={{ paddingTop: 16 }} id="identityCont" />
    </>
  );
};

export default KycStep;
