import { defaultHeaders, handleResponse } from 'app/api';
import { ErrorResponse } from 'app/apiTypes';
import { getAprilApiHost } from 'lib/env';
import { ApplePayPaymentSource, GooglePayPaymentSource } from 'lib/types';
import { handleVaultSubmitError, toCardError } from 'lib/vault';
import { VgsCardError } from 'lib/vgs';

import { Vault, handleVaultSubmitResponse } from '@april/lib-ui';

import { Amount, BankAccountPaymentSource, Card, CardBrand, CardFunding, SurchargeRequirement } from './types';

export type TokenCardSource = {
  expiryDate: {
    expiryMonth: string;
    expiryYear: string;
  };
  last4: string;
  brand: CardBrand;
  bin: string;
  funding: CardFunding;
  country?: string;
};

export type CreateManualCardToken = {
  CreateManualCardToken: {
    amount: Amount;
    card: Card;
    threeDsSessionId: string | null;
    surchargeRequirement: SurchargeRequirement | null;
  };
};

export type CreateSavedPaymentSourceToken = {
  CreateSavedPaymentSourceToken: {
    amount: Amount;
    paymentSourceId: string;
    cvc?: Card['cvc'];
    threeDsSessionId: string | null;
    surchargeRequirement: SurchargeRequirement | null;
  };
};

export type CreateApplePayToken = {
  CreateApplePayToken: {
    amount: Amount;
    applePay: ApplePayPaymentSource;
    surchargeRequirement: SurchargeRequirement | null;
  };
};

export type CreateGooglePayToken = {
  CreateGooglePayToken: {
    amount: Amount;
    googlePay: GooglePayPaymentSource;
    surchargeRequirement: SurchargeRequirement | null;
  };
};

export type CreateBankAccountTokenRequest = {
  CreateBankAccountToken: {
    amount: Amount;
    surchargeRequirement: SurchargeRequirement | null;
  } & BankAccountPaymentSource;
};

export type CreatePayToToken = {
  CreatePayToToken: {
    amount: Amount;
    agreementToken: string;
    surchargeRequirement: SurchargeRequirement | null;
  };
};

export type AnyCreatePaymentTokenRequest =
  | CreateManualCardToken
  | CreateSavedPaymentSourceToken
  | CreateApplePayToken
  | CreateGooglePayToken
  | CreateBankAccountTokenRequest
  | CreatePayToToken;

export type ManualCardToken = {
  ManualCardToken: {
    paymentTokenId: string;
    amount: Amount;
  } & TokenCardSource;
};

export type SavedPaymentSourceToken = {
  SavedPaymentSourceToken: {
    paymentTokenId: string;
    amount: Amount;
    paymentSourceId: string;
    savedPaymentSource: {
      TokenSavedPaymentSourceCard: TokenCardSource;
    };
  };
};

export type NetworkToken = {
  NetworkToken: {
    paymentTokenId: string;
    amount: Amount;
    networkType: 'ApplePay' | 'GooglePay' | 'Unknown';
    brand: CardBrand;
    funding: CardFunding;
  };
};

export type BankAccountToken = {
  BankAccountToken: {
    paymentTokenId: string;
    amount: Amount;
  } & BankAccountPaymentSource;
};

export type PayToToken = {
  PayToToken: {
    paymentTokenId: string;
    amount: Amount;
    surchargeAmount: string;
    agreementUid: string;
    accountIdentifierHint: string;
  };
};

export type AnyCreatePaymentTokenResponse =
  | ManualCardToken
  | SavedPaymentSourceToken
  | NetworkToken
  | BankAccountToken
  | PayToToken;

export const createPaymentToken = async <T = AnyCreatePaymentTokenResponse>(
  token: string,
  payload: AnyCreatePaymentTokenRequest,
): Promise<T> =>
  fetch(`${await getAprilApiHost()}/tokens`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<T>((res) => handleResponse(res, ({ message, detail }) => detail || message));

export const vaultCreatePaymentToken = async <T = AnyCreatePaymentTokenResponse>(
  vault: Vault,
  token: string,
  payload: AnyCreatePaymentTokenRequest,
): Promise<{
  response?: T;
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const response = await vault
    .submit({
      path: '/tokens',
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleVaultSubmitError)
    .then((res) => handleVaultSubmitResponse<T, ErrorResponse>(res, ({ message, detail }) => detail || message));

  return { response };
};
