import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

import CounterOffer from './CounterOffer';

const CounterOfferStep = () => {
  const { payPlanEligibility, params } = useSelector((state: ReduxState) => ({
    payPlanEligibility: state.payPlanEligibility,
    params: state.params,
  }));

  const isPayInFull = useMemo(
    () => payPlanEligibility?.offerDetails.payPlanParameters?.initialPayment === params?.payplanAmount,
    [params, payPlanEligibility],
  );

  return (
    <>
      {isPayInFull && <CounterOffer payInFullAmount={params?.paycardAmount} isPayInFull />}
      {!isPayInFull && (
        <CounterOffer isPayInFull={false} payPlanParameters={payPlanEligibility?.offerDetails.payPlanParameters} />
      )}
    </>
  );
};

export default CounterOfferStep;
