import { toCurrency } from 'lib/currency';
import React, { ChangeEvent } from 'react';

import { Heading, Icon, Text } from '@limepayments/cosmic';

import { LpRadio, PayOptionWrapper, RadioIconWrapper } from './styles';

export type PayInFullProps = {
  isActive: boolean;
  amount: number;
  surcharge: number;
  showSelection?: boolean;
  handleChangeSelection?(e: ChangeEvent<HTMLInputElement>): void;
};

export const PayInFull = ({
  isActive,
  amount,
  surcharge,
  showSelection = true,
  handleChangeSelection,
}: PayInFullProps) => {
  return (
    <PayOptionWrapper tabIndex={0} isActive={isActive}>
      <LpRadio isActive={isActive}>
        <div>
          <Heading className="pay-option-heading" variant="xs" testId="payCardHeading">
            Pay {toCurrency(amount + surcharge)} today
          </Heading>
          <Text className="pay-option-text" variant="body-3" testId="payCardText">
            Pay now in full
          </Text>
        </div>
        {showSelection && (
          <RadioIconWrapper className="icon">
            {isActive && <Icon className="tick-icon" name="Tick" />}
            <input
              type="radio"
              value="paycard"
              className="radio-input"
              onChange={handleChangeSelection}
              data-testid="payCardRadio"
              name="paymentOptionRadio"
            />
          </RadioIconWrapper>
        )}
      </LpRadio>
    </PayOptionWrapper>
  );
};
