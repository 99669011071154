import { Alert, AlertProps } from 'components/Alert';
import BackBtn from 'components/BackBtn';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Heading, Icon, Text, TextField } from '@limepayments/cosmic';

type FormValues = {
  firstName: string;
  lastName: string;
};

export type JoinStepProps = {
  businessName: string;
  onBack?(): void;
  onSubmit?(values: FormValues): Promise<AlertProps | void>;
};

export const JoinStep = ({ businessName, onBack, onSubmit }: JoinStepProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      setAlert(null);
      setIsLoading(true);

      const alert = await onSubmit?.(values);

      setAlert(alert ?? null);
      setIsLoading(false);
    },
    [onSubmit],
  );

  const form = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      lastName: Yup.string().required('Last name is required'),
    }),
    onSubmit: handleSubmit,
  });

  const { touched, values, errors, handleChange, handleBlur } = form;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        fontFamily: 'var(--lp-fonts-body-2)',
        color: 'rgb(var(--lp-colors-neutral-700))',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <BackBtn onClick={onBack} />
        <Heading tagName="h2" variant="xs">
          Join business account
        </Heading>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
            fontSize: 14,
            color: 'rgb(var(--lp-colors-neutral-600))',
            backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
            border: '1px solid rgb(var(--lp-colors-neutral-400))',
            borderRadius: 4,
          }}
        >
          <Text variant="body-3">
            Only people with access can make purchases on behalf of this business. Request to join from the
            administrator below.
          </Text>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              padding: 16,
              color: 'rgb(var(--lp-colors-neutral-600))',
              backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
              border: '1px solid rgb(var(--lp-colors-neutral-400))',
              borderRadius: 4,
            }}
          >
            <div css={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <Icon name="Business" />
              <h3 css={{ fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase' }}>{businessName}</h3>
            </div>
          </div>
          <TextField
            name="firstName"
            type="text"
            label="First name"
            fullWidth
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName ? errors.firstName : ''}
          />
          <TextField
            name="lastName"
            type="text"
            label="Last name"
            fullWidth
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName ? errors.lastName : ''}
            required
          />
          {!!alert && <Alert {...alert} />}
          <Button
            size="large"
            variant="primary"
            className="lp-w-full"
            onClick={form.submitForm}
            isLoading={isLoading}
            disabled={isLoading || alert?.status === 'success'}
          >
            Request to join
          </Button>
        </div>
      </div>
    </div>
  );
};
