import reducer from 'redux/checkoutSlice';

import { configureStore } from '@reduxjs/toolkit';

/**
 * setVgsCard and setVgsCardCvc have none-serialized payload
 * need to make them as exceptions, as they need .submit()
 */

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['checkout/setVaultCard', 'checkout/setVaultCvc'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: ['getVaultCard', 'getVaultCvc'],
      },
    }),
});

export default store;
