import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

import styled from '@emotion/styled';
import { Checkbox, Link } from '@limepayments/cosmic';

const Wrapper = styled.div`
  position: relative;
  label {
    margin-bottom: 0;
  }
`;

const Divider = styled.div`
  background: linear-gradient(61.77deg, #131522 2.17%, #2b1b51 93.98%);
  opacity: 0.1;
  height: 1px;
  width: 100%;
  position: relative;
  margin-top: 16px;
`;

type TermsType = {
  isTermsAgreed: boolean;
  handleChangeTerms(e: ChangeEvent<HTMLInputElement>): void;
};

const Terms = ({ isTermsAgreed, handleChangeTerms }: TermsType) => {
  const { config } = useSelector((state: ReduxState) => ({
    config: state.config,
  }));

  return (
    <Wrapper>
      <Checkbox
        id="termsAgreedCheckbox"
        testId="termsAgreedCheckbox"
        checked={isTermsAgreed}
        variant="legal"
        label={
          <span>
            I've read and agree with the{' '}
            <Link href={config?.tcUrl} target="_blank" size="legal">
              Payment Plan Terms
            </Link>{' '}
            and consent to the collection, use and disclosure of my personal information in accordance with the{' '}
            <Link href={config?.privacyUrl} target="_blank" size="legal">
              April Privacy Policy
            </Link>
          </span>
        }
        onChange={handleChangeTerms}
      />
      <Divider />
    </Wrapper>
  );
};

export default Terms;
