import ErrorMessage from 'components/ErrorMessage';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';

import PlanReview from './PlanReview';
import { PlanReviewStepType } from './types';

const PlanReviewStep = ({ payPlanError }: PlanReviewStepType) => {
  const { payPlanEligibility, selectedPayPlanVariant, params } = useSelector((state: ReduxState) => ({
    payPlanEligibility: state.payPlanEligibility,
    selectedPayPlanVariant: state.selectedPayPlanVariant,
    params: state.params,
  }));

  const payPlanParameters = useMemo(() => {
    /**
     * when there is any error to call customer eligibility api,
     * we display the error message on PlanReview page.
     * So we cannot get schedule from customerEligibility.offerPayPlan.payPlanData.proposedSchedule
     * in that case, we show default schedule, i.e. every 14 days from today
     */
    if (!payPlanEligibility?.offerDetails.payPlanParameters) {
      return selectedPayPlanVariant;
    }
    return payPlanEligibility.offerDetails.payPlanParameters;
  }, [selectedPayPlanVariant, payPlanEligibility]);

  const isCounterOffer = useMemo(() => !!payPlanEligibility?.offerDetails.isCounterOffer, [payPlanEligibility]);

  const isPayInFullCounterOffer = useMemo(
    () =>
      isCounterOffer && payPlanEligibility?.offerDetails.payPlanParameters?.initialPayment === params?.payplanAmount,
    [isCounterOffer, params?.payplanAmount, payPlanEligibility?.offerDetails.payPlanParameters?.initialPayment],
  );

  if (!payPlanParameters) return <ErrorMessage messageBody="Invalid plan parameters" />;

  return (
    <PlanReview
      payPlanError={payPlanError}
      payPlanParameters={payPlanParameters}
      isCounterOffer={isCounterOffer}
      isPayInFullCounterOffer={isPayInFullCounterOffer}
    />
  );
};

export default PlanReviewStep;
