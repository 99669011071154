import { defaultHeaders } from 'app/api';
import { ErrorResponse } from 'app/apiTypes';
import { CardUsageScope } from 'lib/types';
import { handleVaultSubmitError, toCardError } from 'lib/vault';
import { VgsCardError } from 'lib/vgs';

import { Vault, handleVaultSubmitResponse } from '@april/lib-ui';

import { Card, CardBrand, CardFunding } from './types';

export type SavedCardSource = {
  country: string;
  last4: string;
  brand: CardBrand;
  funding: CardFunding;
  cardBin: string;
  expMonth: number;
  expYear: number;
};

export type CreateSavedPaymentSource = {
  CreateSavedPaymentSource: {
    sourceMethod: {
      CardMethod: Card;
    };
    usageScope?: CardUsageScope;
  };
};

export type CreateTemporaryCardSource = {
  CreateTemporaryCardSource: Card;
};

export type AnyCreatePaymentSourceRequest = CreateSavedPaymentSource | CreateTemporaryCardSource;

export type CreatePaymentSourceResponse = {
  paymentSourceId: string;
  customerId?: string;
  merchantId: string;
  sourceMethod: {
    CardMethodResp: SavedCardSource;
  };
  usageScope?: CardUsageScope;
  createdAt: string;
  updatedAt: string;
};

export const vaultCreatePaymentSource = async (
  vault: Vault,
  token: string,
  payload: AnyCreatePaymentSourceRequest,
): Promise<{
  response?: CreatePaymentSourceResponse;
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const response = await vault
    .submit({
      path: '/sources',
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleVaultSubmitError)
    .then((res) =>
      handleVaultSubmitResponse<CreatePaymentSourceResponse, ErrorResponse>(
        res,
        ({ message, detail }) => detail || message,
      ),
    );

  return { response };
};
