import { PayPlanParameters, ProposedSchedule } from 'app/api.april';
import ErrorMessage from 'components/ErrorMessage';
import dayjs from 'dayjs';
import { toCurrency } from 'lib/currency';
import React, { HTMLProps, ReactNode, useMemo } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/react';

const FrequencyLabel: { [key: string]: string } = {
  Weekly: 'week',
  Fortnightly: 'fortnight',
  EveryFourWeeks: 'month',
  EveryThirtyDays: 'month',
};

const StepLine = () => <div css={{ flex: 1, width: 1, background: 'rgb(var(--lp-colors-neutral-400))' }} />;

const StepIndicator = ({ isCollapsed }: { isCollapsed?: boolean }) =>
  isCollapsed ? (
    <div css={{ height: 16, borderRight: '3px dotted rgb(var(--lp-colors-neutral-400))', margin: '4px 0' }} />
  ) : (
    <div css={{ width: 11, height: 11, background: 'rgb(var(--lp-colors-neutral-400))', borderRadius: '50%' }} />
  );

const StepSeparator = ({ isCollapsed }: { isCollapsed?: boolean }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 11,
      marginRight: 16,
    }}
    style={{ height: isCollapsed ? 48 : 20 }}
  >
    <StepLine />
  </div>
);

export function Step({
  amount,
  at,
  label,
  isFirst,
  isLast,
  isCollapsed,
  ...props
}: {
  amount: number;
  at?: string;
  label?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isCollapsed?: boolean;
} & HTMLProps<HTMLDivElement>) {
  const atLabel = useMemo(() => (at ? dayjs(at).format('D MMM') : ''), [at]);

  return (
    <>
      {!isFirst && <StepSeparator isCollapsed={isCollapsed} />}
      <div css={{ display: 'flex' }} {...props}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 11,
            marginRight: 16,
          }}
        >
          {isFirst ? <div css={{ flex: 1 }} /> : <StepLine />}
          <StepIndicator isCollapsed={!isFirst && !isLast && isCollapsed} />
          {isLast ? <div css={{ flex: 1 }} /> : <StepLine />}
        </div>
        <div>
          <div
            data-testid="amount"
            css={{
              fontSize: 12,
              fontWeight: 700,
            }}
          >
            {toCurrency(amount)}*
          </div>
          <div
            data-testid="label"
            css={{
              fontSize: 10,
              textTransform: 'uppercase',
            }}
          >
            {label ?? atLabel}
          </div>
        </div>
      </div>
    </>
  );
}

function Stepper({
  schedule,
  amountDue,
  frequencyLabel,
  paymentCount,
}: {
  schedule: ProposedSchedule;
  amountDue: number;
  frequencyLabel: ReactNode;
  paymentCount: number;
}) {
  const isCollapsed = paymentCount > 4;

  return (
    <div data-testid="stepper">
      <Step data-testid="step-0" amount={amountDue} label="Today" isCollapsed={isCollapsed} isFirst />
      {isCollapsed ? (
        <Step
          data-testid="step-1"
          amount={schedule[0].amount}
          label={`${schedule.length - 2} x ${frequencyLabel ? `${frequencyLabel}ly ` : ''}payments`}
          isCollapsed
        />
      ) : (
        schedule.map((installment, index) => {
          if (index === 0 || index === schedule.length - 1) return null;

          return <Step data-testid={`step-${index}`} key={index} amount={installment.amount} at={installment.at} />;
        })
      )}
      <Step
        data-testid={`step-${isCollapsed ? '2' : schedule.length - 1}`}
        amount={schedule[schedule.length - 1].amount}
        at={schedule[schedule.length - 1].at}
        isCollapsed={isCollapsed}
        isLast
      />
    </div>
  );
}

const RadioButton = ({ isActive }: { isActive?: boolean }) => (
  <div
    className={`${isActive ? 'isActive' : ''}`}
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      border: '1px solid rgb(var(--lp-colors-neutral-400))',
      borderRadius: '50%',
      '&.isActive:after': {
        display: 'block',
        content: '""',
        width: 12,
        height: 12,
        background: 'rgb(var(--lp-colors-primary))',
        borderRadius: '50%',
      },
    }}
  />
);

const Heading = ({
  isActive,
  heading,
  paymentCount,
}: {
  isActive?: boolean;
  heading: ReactNode;
  paymentCount: number;
}) => (
  <div
    data-testid="heading"
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
    }}
  >
    <div data-testid="label" css={{ fontSize: 16 }} style={{ fontWeight: isActive ? '700' : '400' }}>
      {heading}
    </div>
    <div
      data-testid="payments"
      css={{
        fontSize: 12,
        fontWeight: 700,
        padding: '4px 8px',
        borderRadius: 4,
        transition: 'all 0.3s ease',
      }}
      style={{
        background: isActive ? 'rgb(var(--lp-colors-neutral-200))' : 'rgb(var(--lp-colors-neutral-100))',
      }}
    >
      {paymentCount} {paymentCount > 1 ? 'payments' : 'payment'}
    </div>
  </div>
);

export type PayPlanVariantProps = {
  isActive: boolean;
  payPlanParameters: PayPlanParameters;
  surcharge: number;
  isCounterOffer?: boolean;
  onClick?(): void;
};

export const PayPlanVariant = ({
  isActive,
  payPlanParameters,
  surcharge,
  isCounterOffer,
  onClick,
}: PayPlanVariantProps) => {
  const schedule = useMemo(() => payPlanParameters.proposedSchedule ?? [], [payPlanParameters]);
  const amountDue = useMemo(() => (schedule[0]?.amount ?? 0) + surcharge, [schedule, surcharge]);
  const paymentCount = useMemo(() => schedule.filter(({ amount }) => !!amount).length, [schedule]);

  const frequencyLabel = useMemo(() => {
    if (!schedule[0]?.amount) return null;

    return FrequencyLabel[payPlanParameters.instalmentFrequency] ?? null;
  }, [payPlanParameters, schedule]);

  const heading = useMemo(() => {
    const showAmountDue = isCounterOffer || !frequencyLabel;

    return `${toCurrency(showAmountDue ? amountDue : schedule[0]?.amount ?? 0)}* ${
      showAmountDue ? 'today' : `/${frequencyLabel}`
    }`;
  }, [amountDue, frequencyLabel, isCounterOffer, schedule]);

  if (!schedule.length) return <ErrorMessage messageBody="Invalid plan schedule" />;

  return (
    <div
      data-testid={payPlanParameters.payPlanVariant}
      css={{
        display: 'flex',
        gap: 8,
        fontFamily: 'var(--lp-fonts-body-2)',
        fontSize: 16,
        padding: 16,
        color: 'rgb(var(--lp-colors-neutral-600))',
        border: '1px solid rgb(var(--lp-colors-neutral-300))',
        borderRadius: 4,
        transition: 'all 0.3s ease',
        '&:hover, &:active': {
          border: `1px solid rgb(var(--lp-colors-neutral-${!isActive && onClick ? '700' : '300'}))`,
        },
      }}
      style={{
        cursor: !isActive && onClick ? 'pointer' : 'auto',
        background: `rgb(var(--lp-colors-neutral-${isActive ? '50' : 'white'}))`,
      }}
      onClick={onClick}
    >
      <RadioButton isActive={isActive} />
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <Heading isActive={isActive} heading={heading} paymentCount={paymentCount} />
        {isActive && (
          <Stepper
            schedule={schedule}
            amountDue={amountDue}
            frequencyLabel={frequencyLabel}
            paymentCount={paymentCount}
          />
        )}
      </div>
    </div>
  );
};
