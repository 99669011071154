import { payToLookupAlias } from 'app/api.april';
import payId from 'assets/pay-id.png';
import { FormikConfig, FormikProps, useFormik } from 'formik';
import { STATE_ERROR_MESSAGE, getErrorMessage } from 'lib/errorMessages';
import React, { FocusEvent, InputHTMLAttributes, forwardRef, useCallback, useEffect, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';
import * as Yup from 'yup';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon, Link, Spinner, Text, TextField } from '@limepayments/cosmic';

export type IdentifierType = 'Alias' | 'Bban';

export type AliasType = 'Phone' | 'Email' | 'Abn' | 'OrganisationIdentifier';

export const AliasLabel = {
  Phone: 'Phone',
  Email: 'Email',
  Abn: 'ABN/ACN',
  OrganisationIdentifier: 'Organisation ID',
};

export interface PayToFormValues {
  customerName: string;
  identifierType: IdentifierType;
  aliasType: AliasType;
  aliasValue: string;
  bsb: string;
  accountNumber: string;
}

export const usePayToForm = ({ onSubmit }: { onSubmit: FormikConfig<PayToFormValues>['onSubmit'] }) => {
  const form = useFormik<PayToFormValues>({
    initialValues: {
      customerName: '',
      identifierType: 'Alias',
      aliasType: 'Phone',
      aliasValue: '',
      bsb: '',
      accountNumber: '',
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required('Name is required'),
      identifierType: Yup.mixed().oneOf(['Alias', 'Bban']),
      aliasType: Yup.mixed().oneOf(['Phone', 'Email', 'Abn', 'OrganisationIdentifier']),
      aliasValue: Yup.string().when(['identifierType', 'aliasType'], ([identifierType, aliasType], schema) => {
        if (identifierType !== 'Alias') return schema;

        if (aliasType === 'Phone')
          return schema
            .test('Phone', 'Phone is invalid', (value) => isPossiblePhoneNumber(value ?? ''))
            .required('Phone is required');
        if (aliasType === 'Email') return schema.email('Email address is invalid').required('Email is required');
        if (aliasType === 'Abn')
          return (
            schema
              // regex from: https://go.split.cash/api-docs/pay-to/v1#tag/Agreements/paths/~1payto~1agreements/post
              .matches(/^((\d{9})|(\d{11}))$/, 'ABN is invalid')
              .required('ABN is required')
          );
        if (aliasType === 'OrganisationIdentifier')
          return (
            schema
              // regex from: https://go.split.cash/api-docs/pay-to/v1#tag/Agreements/paths/~1payto~1agreements/post
              .matches(/^[!-@[-~][ -@[-~]{0,254}[!-@[-~]$/, 'Organisation ID is invalid') //
              .required('Organisation ID is required')
          );

        return schema;
      }),
      bsb: Yup.string().when('identifierType', {
        is: 'Bban',
        then: (schema) =>
          schema
            .required('BSB is required')
            .matches(/^[0-9]*$/, 'BSB must be 6 digits')
            .min(6, 'BSB must be 6 digits')
            .max(6, 'BSB must be 6 digits'),
      }),
      accountNumber: Yup.string().when('identifierType', {
        is: 'Bban',
        then: (schema) =>
          schema
            .required('Account number is required')
            .matches(/^[0-9]*$/, 'Account number must be 6-9 digits')
            .min(6, 'Account number must be 6-9 digits')
            .max(9, 'Account number must be 6-9 digits'),
      }),
    }),
    onSubmit,
  });

  return form;
};

export const SpinnerIcon = () => (
  <div css={{ '& > div': { backgroundColor: 'none' }, img: { margin: 0 }, p: { display: 'none' } }}>
    <Spinner variant="simple" isVisible />
  </div>
);

const PhoneInputComponent = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
  <input
    ref={ref}
    css={{
      display: 'block',
      width: '100%',
      height: '100%',
      fontFamily: 'var(--lp-fonts-body-2)',
      outline: 'none',
    }}
    {...props}
  />
));

export interface PayIdFormProps {
  form: FormikProps<PayToFormValues>;
}

export const PayIdForm = ({ form }: PayIdFormProps) => {
  const [displayName, setDisplayName] = useState('');
  const [aliasError, setAliasError] = useState('');
  const [loading, setLoading] = useState(false);

  const { params, config } = useSelector((state: ReduxState) => ({
    params: state.params,
    config: state.config,
  }));

  const { touched, values, errors, handleChange, handleBlur, resetForm, setFieldValue } = form;

  const error = aliasError || (touched.aliasValue ? errors.aliasValue : '');

  useEffect(() => {
    setDisplayName('');
    setAliasError('');
    resetForm();
    setFieldValue('aliasType', values.aliasType);
  }, [values.aliasType, resetForm, setFieldValue]);

  const onLookupAlias = useCallback(async () => {
    setDisplayName('');
    setAliasError('');
    setLoading(true);

    try {
      if (!params) throw Error(STATE_ERROR_MESSAGE);

      const { displayName } = await payToLookupAlias(params.publicKey, values.aliasType, values.aliasValue)
        // fail silent
        .catch(() => ({ displayName: '' }));

      setDisplayName(displayName);
    } catch (error) {
      setAliasError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, [params, values.aliasType, values.aliasValue]);

  const handleAliasBlur = useCallback(
    async (event: FocusEvent<HTMLElement, Element>) => {
      setAliasError('');
      handleBlur(event);
      if (!errors.aliasValue) onLookupAlias();
    },
    [errors.aliasValue, handleBlur, onLookupAlias],
  );

  return (
    <div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'var(--lp-fonts-body-2)',
          fontSize: 14,
          border: '1px solid rgb(var(--lp-colors-neutral-200))',
          borderRadius: 8,
        }}
        style={{ borderColor: error ? 'rgb(var(--lp-colors-error-600))' : undefined }}
      >
        <div
          css={{ padding: 8, borderRight: '1px solid rgb(var(--lp-colors-neutral-200))' }}
          style={{ borderColor: error ? 'rgb(var(--lp-colors-error-600))' : undefined }}
        >
          <select
            css={{ fontFamily: 'var(--lp-fonts-body-2)', outline: 'none' }}
            name="aliasType"
            value={values.aliasType}
            onChange={handleChange}
          >
            <option value="Phone">{AliasLabel.Phone}</option>
            <option value="Email">{AliasLabel.Email}</option>
            <option value="Abn">{AliasLabel.Abn}</option>
            <option value="OrganisationIdentifier">{AliasLabel.OrganisationIdentifier}</option>
          </select>
        </div>
        <div css={{ flex: 1, padding: 8 }}>
          <div css={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <div css={{ flex: 1, '.PhoneInputCountry': { display: 'none' } }}>
              {values.aliasType === 'Phone' ? (
                <PhoneInput
                  name="aliasValue"
                  inputComponent={PhoneInputComponent}
                  defaultCountry={config?.merchantTradingCountry}
                  international
                  autoComplete="off"
                  placeholder={AliasLabel[values.aliasType]}
                  value={values.aliasValue}
                  onChange={(value) => setFieldValue('aliasValue', value ?? '')}
                  onBlur={handleAliasBlur}
                />
              ) : (
                <input
                  css={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'var(--lp-fonts-body-2)',
                    outline: 'none',
                  }}
                  name="aliasValue"
                  type="string"
                  placeholder={AliasLabel[values.aliasType]}
                  value={values.aliasValue}
                  onChange={handleChange}
                  onBlur={handleAliasBlur}
                />
              )}
            </div>
            <div css={{ maxWidth: 22 }}>{loading ? <SpinnerIcon /> : <img src={payId} alt="PayID" />}</div>
          </div>
        </div>
      </div>
      {!!error && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            color: 'rgb(var(--lp-colors-error-600))',
            marginTop: 4,
          }}
        >
          <div>
            <Icon name="Error" />
          </div>
          <Text variant="body-3">{error}</Text>
        </div>
      )}
      {!!displayName && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            marginTop: 4,
          }}
        >
          <Icon
            css={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              background: 'rgb(var(--lp-colors-success-200))',
              fill: 'rgb(var(--lp-colors-neutral-800))',
            }}
            name="Tick"
          />
          <Text variant="body-3">PayID for {displayName}</Text>
        </div>
      )}
    </div>
  );
};

export interface BankAccountProps {
  form: FormikProps<PayToFormValues>;
}

export const BankAccountForm = ({ form }: BankAccountProps) => {
  const { touched, values, errors, handleChange, handleBlur } = form;

  return (
    <div css={{ display: 'flex', gap: 16 }}>
      <div css={{ flex: 1 }}>
        <TextField
          name="bsb"
          type="text"
          inputMode="numeric"
          label="BSB"
          fullWidth
          required
          minLength={6}
          maxLength={6}
          value={values.bsb}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.bsb ? errors.bsb : ''}
        />
      </div>
      <div css={{ flex: 1 }}>
        <TextField
          name="accountNumber"
          type="text"
          inputMode="numeric"
          label="Account no."
          fullWidth
          required
          minLength={6}
          maxLength={9}
          value={values.accountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.accountNumber ? errors.accountNumber : ''}
        />
      </div>
    </div>
  );
};

export interface PayToFormProps {
  form: FormikProps<PayToFormValues>;
}

export const PayToForm = ({ form }: PayToFormProps) => {
  const { touched, values, errors, handleChange, handleBlur, resetForm, setFieldValue } = form;

  const showPayId = values.identifierType === 'Alias';

  const handleTogglePayId = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      resetForm();
      setFieldValue('identifierType', values.identifierType === 'Alias' ? 'Bban' : 'Alias');
    },
    [values.identifierType, resetForm, setFieldValue],
  );

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <TextField
        name="customerName"
        type="text"
        label="Name"
        fullWidth
        required
        value={values.customerName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.customerName ? errors.customerName : ''}
      />
      {showPayId ? <PayIdForm form={form} /> : <BankAccountForm form={form} />}
      <div css={{ zIndex: 1 }}>
        <Link size="small" onClick={handleTogglePayId}>
          {showPayId ? 'Use a BSB and account number instead' : 'Use a PayID instead'}
        </Link>
      </div>
    </div>
  );
};
