import LogRocket from 'logrocket';

import { VaultCardType, VaultError, VaultFieldStyles, VaultSubmitResponse, VaultValidationResult } from '@april/lib-ui';

import { getAprilHost } from './env';
import { CARD_ERROR_MESSAGE, getErrorMessage } from './errorMessages';
import { CardPaymentSourceBrand } from './types';
import { VgsCardError } from './vgs';

const TIMEOUT = 10000; // 10s

export const getVaultHost = async () => `https://vault.${await getAprilHost()}`;
export const getVaultApiHost = async () => `https://api-vault.${await getAprilHost()}`;

export const fieldStyles: VaultFieldStyles = {
  fontSize: '16px',
  '&::placeholder': {
    color: '#555C7C',
  },
  '&.isInvalid': { color: '#D3354A' },
  '&.isValid': { color: '#4F8A10' },
};

export const startVaultTimeout = () =>
  setTimeout(() => LogRocket.captureMessage(`Vault Error: vault timeout (${TIMEOUT}ms)`), TIMEOUT);

export const onVaultError = ({ errorMessage, field }: VaultError) =>
  LogRocket.captureMessage(`Vault Error: ${errorMessage}`, {
    extra: {
      fieldType: field.type,
    },
  });

export const handleVaultSubmitError = ({ error, response }: VaultSubmitResponse) => {
  if (error || !response) {
    LogRocket.captureMessage(`Vault Submit Error: ${getErrorMessage(error)}`);
    throw Error('There was a temporary network error, please try again.');
  }
  return { response };
};

export const toCardError = (errors: VaultValidationResult['errors']): VgsCardError => ({
  formError: CARD_ERROR_MESSAGE,
  cardNumberError: errors.cardNumber,
  expiryDateError: errors.expiryDate,
  cardCvcError: errors.cardCvc,
});

export const VaultCardTypeToBrand: { [type: string]: CardPaymentSourceBrand } = {
  'american-express': 'American Express',
  'diners-club': 'Diners Club',
  discover: 'Discover',
  elo: 'Elo',
  hiper: 'Hiper',
  hipercard: 'Hipercard',
  jcb: '',
  maestro: 'Maestro',
  mastercard: 'MasterCard',
  mir: 'Mir',
  unionpay: 'UnionPay',
  visa: 'Visa',
};

export const toCardPaymentSourceBrand = (cardType: VaultCardType): CardPaymentSourceBrand =>
  VaultCardTypeToBrand.hasOwnProperty(cardType) ? VaultCardTypeToBrand[cardType] : '';
