import { defaultHeaders, handleResponse } from 'app/api';
import { getAprilApiHost } from 'lib/env';

export type AdministerCustomerRequest = {
  RequestDelegateAddition: {
    b2bProspectToken: string;
    givenName?: string;
    familyName: string;
  };
};

export type AdministerCustomerResponse = {
  RequestDelegateAdditionResponse: {
    metadata: {};
  };
};

export const administerCustomer = async (
  customerId: string,
  token: string,
  payload: AdministerCustomerRequest,
): Promise<AdministerCustomerResponse> =>
  fetch(`${await getAprilApiHost()}/customers/enterprise/${customerId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    }),
  }).then<AdministerCustomerResponse>(handleResponse);
