import { ReactComponent as Amex } from 'assets/amex.svg';
import { ReactComponent as ApplePay } from 'assets/apple-pay-mark.svg';
import { ReactComponent as GooglePay } from 'assets/google-pay-mark.svg';
import { ReactComponent as Mastercard } from 'assets/mastercard.svg';
import { ReactComponent as PayPlan } from 'assets/pay-plan.svg';
import payTo from 'assets/pay-to.png';
import { ReactComponent as Visa } from 'assets/visa.svg';
import { CardPaymentSourceBrand } from 'lib/types';
import React from 'react';

import styled from '@emotion/styled';
import { Icon } from '@limepayments/cosmic';

export type CardBrandProps = {
  brand: CardPaymentSourceBrand | 'card' | 'bank' | 'google-pay' | 'apple-pay' | 'payto' | 'pay-plan';
  isOpen?: boolean;
};

const Wrapper = styled.div`
  height: 24px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 8px;

  svg {
    width: auto;
    height: 24px;
    color: rgb(var(--lp-colors-neutral-600));
  }

  .tick {
    border-radius: 50%;
    background: rgb(var(--lp-colors-success-200));
    fill: rgb(var(--lp-colors-neutral-800));
  }
`;

/**
 * the brand names are 'Visa', 'MasterCard' and 'American Express'
 */

const CardBrand = ({ brand, isOpen }: CardBrandProps) => {
  if (isOpen) {
    return (
      <Wrapper data-testid="tick">
        <Icon className="tick" name="Tick" />
      </Wrapper>
    );
  }

  if (brand === 'Visa') {
    return (
      <Wrapper data-testid={brand}>
        <Visa />
      </Wrapper>
    );
  }

  if (brand === 'MasterCard') {
    return (
      <Wrapper data-testid={brand}>
        <Mastercard />
      </Wrapper>
    );
  }

  if (brand === 'American Express') {
    return (
      <Wrapper ddata-testid={brand}>
        <Amex />
      </Wrapper>
    );
  }

  if (brand === 'google-pay') {
    return (
      <Wrapper data-testid={brand}>
        <GooglePay />
      </Wrapper>
    );
  }

  if (brand === 'apple-pay') {
    return (
      <Wrapper data-testid={brand}>
        <ApplePay />
      </Wrapper>
    );
  }

  if (brand === 'bank') {
    return (
      <Wrapper data-testid={brand}>
        <Icon name="Bank" />
      </Wrapper>
    );
  }

  if (brand === 'payto') {
    return (
      <Wrapper data-testid={brand}>
        <img src={payTo} alt="PayTo" />
      </Wrapper>
    );
  }

  if (brand === 'pay-plan') {
    return (
      <Wrapper data-testid={brand}>
        <PayPlan />
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid="card">
      <Icon name="Card" />
    </Wrapper>
  );
};

export default CardBrand;
