import { defaultHeaders, getCheckoutConfig, getCustomer, queryCardPaymentSources, verifyCustomer } from 'app/api';
import {
  Amount,
  AnyCreatePaymentSourceRequest,
  BankAccountToken,
  ManualCardToken,
  NetworkToken,
  PayToToken,
  PaymentSurchargeRequest,
  SavedPaymentSourceToken,
  SurchargeRequirement,
  VAULT_CARD,
  calculatePayPlanParameters,
  calculateSurcharge,
  createPayPlanOffer,
  createPaymentToken,
  toCardPaymentSource,
  toSavedCardPaymentSource,
  vaultCreatePayPlan,
  vaultCreatePaymentSource,
  vaultCreatePaymentToken,
} from 'app/api.april';
import { ErrorResponse } from 'app/apiTypes';
import { fbSignInWithCustomToken, getCurrentUserToken, initializeFB } from 'app/identity';
import { DirectDebitFormValues, toBankAccountPaymentSource } from 'components/PaymentSource/DirectDebitForm';
import checkPayPlanAmountEligibility from 'lib/checkPayPlanAmountEligibility';
import {
  CARD_ERROR_MESSAGE,
  CUSTOMER_TOKEN_ERROR_MESSAGE,
  PAYMENT_TOKEN_ERROR_MESSAGE,
  STATE_ERROR_MESSAGE,
  getErrorMessage,
} from 'lib/errorMessages';
import isB2B from 'lib/isB2B';
import { EventMessage, toErrorMessage } from 'lib/messages';
import {
  ApplePayPaymentSource,
  CardUsageScope,
  GooglePayPaymentSource,
  PaymentMethodType,
  PaymentSource,
  SavedCardPaymentSource,
  SelectedPaymentSource,
  Step,
  UserClaims,
} from 'lib/types';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setB2bCardUsageScope,
  setCardPaymentSources,
  setCheckoutStep,
  setConfig,
  setCurrentUser,
  setCustomerId,
  setPayPlanEligibility,
  setPayPlanVariants,
  setPaymentType,
  setSelectedCardPaymentSource,
  setSelectedPayPlanVariant,
} from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

import { Vault, VaultCardType } from '@april/lib-ui';

import { submitCardinalDdcForm } from './cardinal';
import { loadKycScript } from './loadKycScript';
import { logRocketIdentify, logRocketInit, logRocketTrack } from './logrocket';
import { AnyMessage, EventName } from './messages';
import { toCardPaymentSourceBrand } from './vault';
import { VgsCardError } from './vgs';

export const useInterval = (callback: () => void, interval: number | null) => {
  const callbackRef = useRef<() => void>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (interval !== null) {
      const id = setInterval(() => callbackRef.current?.(), interval);
      return () => clearInterval(id);
    }
  }, [interval]);
};

export const useMessageCallback = (callback: (data: MessageEvent['data']) => void) => {
  const onMessage = useCallback(({ data }: MessageEvent) => callback(data), [callback]);

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);
};

export const useSubmitMessageCallback = (callback: () => void) => {
  const onMessage = useCallback(
    (data: MessageEvent['data']) => {
      if (data === EventName.Submit) callback();
    },
    [callback],
  );
  useMessageCallback(onMessage);
};

export const useEventMessageCallback = (callback: (message: EventMessage) => void) => {
  const onMessage = useCallback(
    (data: MessageEvent['data']) => {
      let message: any;

      try {
        message = JSON.parse(data);
      } catch (error) {}

      if (message?.service === 'Limepay') {
        message.event && callback(message as EventMessage);
      }
    },
    [callback],
  );
  useMessageCallback(onMessage);
};

export const useSendMessage = () => {
  const sendMessage = useCallback(
    (message: AnyMessage): void => window.parent.postMessage(JSON.stringify(message), '*'),
    [],
  );
  return sendMessage;
};

export const useError = () => {
  const sendMessage = useSendMessage();
  const { params } = useSelector((state: ReduxState) => ({
    params: state.params,
  }));

  const onError = useCallback(
    (error: unknown, getApiErrorMessage?: (error: ErrorResponse) => string) => {
      const message = getErrorMessage(error, getApiErrorMessage);
      sendMessage(toErrorMessage(message, params?.elementId || ''));
      return message;
    },
    [sendMessage, params?.elementId],
  );

  return onError;
};

export const useQuerySavedCards = () => {
  const dispatch = useDispatch();

  const { params, config, customerId } = useSelector((state: ReduxState) => ({
    params: state.params,
    config: state.config,
    customerId: state.customerId,
  }));

  const onQuerySavedCards = useCallback(async () => {
    if (
      !params ||
      !config ||
      params.hideSavedCards ||
      !config.displayCustomerSavedPaymentMethodsInCheckout ||
      !customerId
    ) {
      return;
    }

    const token = await getCurrentUserToken();
    if (!token) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);
    const cardPaymentSources = await queryCardPaymentSources(config.apiBaseUri, customerId, token);
    dispatch(setCardPaymentSources(cardPaymentSources));
    /** display the first saved card only, so just dispatch it here */
    if (cardPaymentSources.length > 0) {
      const cardPaymentSource = cardPaymentSources[0];
      dispatch(setSelectedCardPaymentSource(cardPaymentSource));
    }
  }, [params, config, customerId, dispatch]);

  return onQuerySavedCards;
};

export const useMount = () => {
  const dispatch = useDispatch();
  const { params, initParams } = useSelector(({ params, initParams }: ReduxState) => ({
    params,
    initParams,
  }));

  const onMount = useCallback(async () => {
    if (!params) {
      return;
    }

    logRocketInit(async (sessionUrl) => {
      defaultHeaders['Logrocket-Session-Url'] = sessionUrl;
      logRocketIdentify();
      const config = await getCheckoutConfig(params.publicKey); // track config request
      logRocketTrack({
        merchantId: config.merchantId,
        marketplaceId: config.marketplaceId,
        sessionCorrelationId: defaultHeaders['Session-Correlation-Id'],
        checkoutVersion: defaultHeaders['Checkout-Version'],
        platform: defaultHeaders['Platform'],
        platformVersion: defaultHeaders['Platform-Version'],
        platformPluginVersion: defaultHeaders['Platform-Plugin-Version'],
      });
    });

    const config = await getCheckoutConfig(params.publicKey);

    if (!params.paymentSourceOnly) {
      const { parameters } = await calculatePayPlanParameters(params.publicKey, {
        amount: {
          minorCurrencyUnits: params.payplanAmount,
          currency: params.currency,
        },
        payPlanVariants: config.payPlanVariants,
      });

      if (parameters.length) {
        dispatch(setPayPlanVariants(parameters));
        parameters.length === 1 && dispatch(setSelectedPayPlanVariant(parameters[0]));
      }
    }

    config.isB2B = isB2B(params, config);
    await loadKycScript();
    dispatch(setConfig(config));

    // hide B2B card usage scope
    if (config.isB2B && !config.displayCustomerSavedPaymentMethodsInCheckout) {
      dispatch(setB2bCardUsageScope(CardUsageScope.delegate));
    }

    if (params.paymentType === 'payplan') {
      const { payPlanAmountEligibility } = checkPayPlanAmountEligibility(params, config);
      !payPlanAmountEligibility && dispatch(setPaymentType('paycard'));
    }

    await initializeFB(config.authApiKey, config.authDomain);
    if (initParams?.customerToken) {
      const userCredential = await fbSignInWithCustomToken(config.tenantId, initParams?.customerToken);
      const idTokenResult = await userCredential.user?.getIdTokenResult();

      if (!idTokenResult) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      const claims = idTokenResult.claims as unknown as UserClaims;
      const customerId = claims.limepay.customerId;
      const currentUser = await getCustomer(config.apiBaseUri, customerId, idTokenResult.token);

      dispatch(setCustomerId(customerId));
      dispatch(setCurrentUser(currentUser));
    }
  }, [params, dispatch, initParams]);

  return onMount;
};

export const usePaymentSourceSubmit = () => {
  const {
    params,
    config,
    currentUser,
    b2bCardUsageScope,
    selectedCardPaymentSource,
    selectedPaymentSource,
    getVaultCard,
  } = useSelector((state: ReduxState) => ({
    config: state.config,
    params: state.params,
    currentUser: state.currentUser,
    b2bCardUsageScope: state.b2bCardUsageScope,
    selectedCardPaymentSource: state.selectedCardPaymentSource,
    selectedPaymentSource: state.selectedPaymentSource,
    getVaultCard: state.getVaultCard,
  }));

  const onPaymentSourceSubmit = useCallback(async (): Promise<PaymentSource | undefined> => {
    if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

    if (selectedPaymentSource === SelectedPaymentSource.savedCard && selectedCardPaymentSource) {
      return { cardPaymentSource: selectedCardPaymentSource };
    } else if (selectedPaymentSource === SelectedPaymentSource.newCard) {
      if (!getVaultCard) throw Error(STATE_ERROR_MESSAGE);

      let cardPaymentSource: SavedCardPaymentSource;

      if (currentUser) {
        const customerIdToken = await getCurrentUserToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        if (config.isB2B && b2bCardUsageScope) {
          const { cardError, response } = await vaultCreatePaymentSource(getVaultCard(), customerIdToken, {
            CreateSavedPaymentSource: {
              sourceMethod: {
                CardMethod: VAULT_CARD,
              },
              usageScope: b2bCardUsageScope,
            },
          });

          if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

          cardPaymentSource = toSavedCardPaymentSource(response.paymentSourceId, response.sourceMethod.CardMethodResp);
        } else {
          const { cardError, response } = await vaultCreatePaymentSource(getVaultCard(), customerIdToken, {
            CreateSavedPaymentSource: {
              sourceMethod: {
                CardMethod: VAULT_CARD,
              },
            },
          });

          if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

          cardPaymentSource = toSavedCardPaymentSource(response.paymentSourceId, response.sourceMethod.CardMethodResp);
        }
      } else {
        // anonymous
        const { cardError, response } = await vaultCreatePaymentSource(getVaultCard(), params.publicKey, {
          CreateTemporaryCardSource: {
            ...VAULT_CARD,
          },
        });

        if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

        cardPaymentSource = toSavedCardPaymentSource(response.paymentSourceId, response.sourceMethod.CardMethodResp);
      }

      return { cardPaymentSource };
    }
  }, [config, selectedCardPaymentSource, currentUser, params, b2bCardUsageScope, selectedPaymentSource, getVaultCard]);

  return onPaymentSourceSubmit;
};

export const useCalculateSurcharge = () => {
  const { config, params, paymentType, selectedPayPlanVariant } = useSelector((state: ReduxState) => ({
    config: state.config,
    params: state.params,
    paymentType: state.paymentType,
    selectedPayPlanVariant: state.selectedPayPlanVariant,
  }));

  const onCalculateSurcharge = useCallback(
    async (vault: Vault | null, method: PaymentSurchargeRequest['PaymentSurchargeRequest']['payment']['method']) => {
      if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

      const response = await calculateSurcharge(vault, config.merchantId, params.publicKey, {
        PaymentSurchargeRequest: {
          payment: {
            form:
              paymentType === 'payplan' && selectedPayPlanVariant
                ? { PayPlan: { variant: selectedPayPlanVariant.payPlanVariant } }
                : { PayInFull: {} },
            customerType: config.isB2B ? 'Organisation' : 'Consumer',
            amount: {
              amount: paymentType === 'paycard' ? params.paycardAmount : params.payplanAmount,
              currency: params.currency,
            },
            method,
          },
        },
      });

      return response;
    },
    [params, config, paymentType, selectedPayPlanVariant],
  );

  return onCalculateSurcharge;
};

export const useApplePaySubmit = () => {
  const { config, params, surchargeQuote } = useSelector((state: ReduxState) => ({
    config: state.config,
    params: state.params,
    surchargeQuote: state.surchargeQuote,
  }));

  const onApplePaySubmit = useCallback(
    async (
      applePayPaymentSource: ApplePayPaymentSource,
    ): Promise<{ paymentTokenId: string; paymentMethodType: PaymentMethodType }> => {
      if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

      const surchargeRequirement: SurchargeRequirement | null = surchargeQuote
        ? { SurchargeQuoted: { surchargeQuote } }
        : null;

      const { NetworkToken } = await createPaymentToken<NetworkToken>(params.publicKey, {
        CreateApplePayToken: {
          amount: {
            minorCurrencyUnits: params.paycardAmount,
            currency: params.currency,
          },
          applePay: applePayPaymentSource,
          surchargeRequirement,
        },
      });

      return {
        paymentTokenId: NetworkToken.paymentTokenId,
        paymentMethodType: 'NetworkToken',
      };
    },
    [params, config, surchargeQuote],
  );

  return onApplePaySubmit;
};

export const useGooglePaySubmit = () => {
  const { config, params, surchargeQuote } = useSelector((state: ReduxState) => ({
    config: state.config,
    params: state.params,
    surchargeQuote: state.surchargeQuote,
  }));

  const onGooglePaySubmit = useCallback(
    async (
      googlePayPaymentSource: GooglePayPaymentSource,
    ): Promise<{ paymentTokenId: string; paymentMethodType: PaymentMethodType }> => {
      if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

      const surchargeRequirement: SurchargeRequirement | null = surchargeQuote
        ? { SurchargeQuoted: { surchargeQuote } }
        : null;

      const response = await createPaymentToken(params.publicKey, {
        CreateGooglePayToken: {
          amount: {
            minorCurrencyUnits: params.paycardAmount,
            currency: params.currency,
          },
          googlePay: googlePayPaymentSource,
          surchargeRequirement,
        },
      });

      const paymentTokenId =
        'NetworkToken' in response
          ? response.NetworkToken.paymentTokenId
          : 'ManualCardToken' in response
          ? response.ManualCardToken.paymentTokenId
          : null;

      if (!paymentTokenId) throw Error(PAYMENT_TOKEN_ERROR_MESSAGE);

      return {
        paymentTokenId,
        paymentMethodType: 'NetworkToken' in response ? 'NetworkToken' : 'Card',
      };
    },
    [params, config, surchargeQuote],
  );

  return onGooglePaySubmit;
};

export const useDirectDebitSubmit = () => {
  const { params, surchargeQuote } = useSelector((state: ReduxState) => ({
    params: state.params,
    surchargeQuote: state.surchargeQuote,
  }));

  const onDirectDebitSubmit = useCallback(
    async (values: DirectDebitFormValues) => {
      if (!params) throw Error(STATE_ERROR_MESSAGE);

      const bankAccountPaymentSource = toBankAccountPaymentSource(values);
      const surchargeRequirement: SurchargeRequirement | null = surchargeQuote
        ? { SurchargeQuoted: { surchargeQuote } }
        : null;

      const {
        BankAccountToken: { paymentTokenId },
      } = await createPaymentToken<BankAccountToken>(params.publicKey, {
        CreateBankAccountToken: {
          amount: {
            minorCurrencyUnits: params.paycardAmount,
            currency: params.currency,
          },
          surchargeRequirement,
          ...bankAccountPaymentSource,
        },
      });

      return { paymentTokenId };
    },
    [params, surchargeQuote],
  );

  return onDirectDebitSubmit;
};

export const usePayToSubmit = () => {
  const { params, surchargeQuote } = useSelector((state: ReduxState) => ({
    params: state.params,
    surchargeQuote: state.surchargeQuote,
  }));

  const onPayToSubmit = useCallback(
    async (agreementToken: string) => {
      if (!params) throw Error(STATE_ERROR_MESSAGE);

      const surchargeRequirement: SurchargeRequirement | null = surchargeQuote
        ? { SurchargeQuoted: { surchargeQuote } }
        : null;

      const {
        PayToToken: { paymentTokenId },
      } = await createPaymentToken<PayToToken>(params.publicKey, {
        CreatePayToToken: {
          amount: {
            minorCurrencyUnits: params.paycardAmount,
            currency: params.currency,
          },
          agreementToken,
          surchargeRequirement,
        },
      });

      return { paymentTokenId };
    },
    [params, surchargeQuote],
  );

  return onPayToSubmit;
};

export const useCardinalDdcSubmit = () => {
  const { config } = useSelector((state: ReduxState) => ({
    config: state.config,
  }));

  const onCardinalDdcSubmit = useCallback(
    async (bin?: string | null) => {
      if (!config?.cardinalDdcJwt || !bin) return null;

      return submitCardinalDdcForm(bin, config.cardinalDdcJwt);
    },
    [config],
  );

  return onCardinalDdcSubmit;
};

export const useCardTypeErrorCheck = () => {
  const { config } = useSelector((state: ReduxState) => ({
    config: state.config,
  }));

  const onCardTypeErrorCheck = useCallback(
    (cardType?: VaultCardType | null): string | null => {
      if (!cardType || !config?.allowedCardBrands) return null;

      const cardBrand = toCardPaymentSourceBrand(cardType);

      if (cardBrand && !config.allowedCardBrands.includes(cardBrand)) return `${cardBrand} not supported`;

      return null;
    },
    [config],
  );

  return onCardTypeErrorCheck;
};

export const usePayCardSubmit = () => {
  const {
    config,
    params,
    selectedCardPaymentSource,
    currentUser,
    b2bCardUsageScope,
    selectedPaymentSource,
    getVaultCard,
    getVaultCvc,
    applePayPaymentSource,
    googlePayPaymentSource,
    surchargeQuote,
  } = useSelector((state: ReduxState) => ({
    config: state.config,
    params: state.params,
    selectedCardPaymentSource: state.selectedCardPaymentSource,
    currentUser: state.currentUser,
    b2bCardUsageScope: state.b2bCardUsageScope,
    selectedPaymentSource: state.selectedPaymentSource,
    getVaultCard: state.getVaultCard,
    getVaultCvc: state.getVaultCvc,
    applePayPaymentSource: state.applePayPaymentSource,
    googlePayPaymentSource: state.googlePayPaymentSource,
    surchargeQuote: state.surchargeQuote,
  }));

  const onApplePaySubmit = useApplePaySubmit();
  const onGooglePaySubmit = useGooglePaySubmit();
  const onCardinalDdcSubmit = useCardinalDdcSubmit();
  const onCardTypeErrorCheck = useCardTypeErrorCheck();

  const onPayCardSubmit = useCallback(async (): Promise<{
    paymentTokenId?: string;
    paymentMethodType?: PaymentMethodType;
    cardPaymentSource?: PaymentSource['cardPaymentSource'];
    cardError?: VgsCardError;
  }> => {
    if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

    let cardPaymentSource: PaymentSource['cardPaymentSource'];

    const amount: Amount = {
      minorCurrencyUnits: params.paycardAmount,
      currency: params.currency,
    };

    const surchargeRequirement: SurchargeRequirement | null = surchargeQuote
      ? { SurchargeQuoted: { surchargeQuote } }
      : null;

    if (selectedPaymentSource === SelectedPaymentSource.savedCard && selectedCardPaymentSource) {
      if (!getVaultCvc) throw Error(STATE_ERROR_MESSAGE);

      const customerIdToken = await getCurrentUserToken();
      if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      const threeDsSessionId = await onCardinalDdcSubmit(selectedCardPaymentSource.cardBin);

      const { cardError, response } = await vaultCreatePaymentToken<SavedPaymentSourceToken>(
        getVaultCvc(),
        customerIdToken,
        {
          CreateSavedPaymentSourceToken: {
            amount,
            paymentSourceId: selectedCardPaymentSource.cardPaymentSourceId,
            cvc: VAULT_CARD.cvc,
            threeDsSessionId,
            surchargeRequirement,
          },
        },
      );

      if (cardError) return { cardError };
      if (!response) throw Error(CARD_ERROR_MESSAGE);

      return {
        paymentTokenId: response.SavedPaymentSourceToken.paymentTokenId,
        paymentMethodType: 'Card',
        cardPaymentSource: selectedCardPaymentSource,
      };
    } else if (selectedPaymentSource === SelectedPaymentSource.newCard) {
      if (!getVaultCard) throw Error(STATE_ERROR_MESSAGE);

      const cardTypeError = onCardTypeErrorCheck(getVaultCard().fields.cardNumber?.state.cardType);
      if (cardTypeError) throw Error(cardTypeError);

      const threeDsSessionId = await onCardinalDdcSubmit(getVaultCard().fields.cardNumber?.state.bin);

      const { cardError, response } = await vaultCreatePaymentToken<ManualCardToken>(getVaultCard(), params.publicKey, {
        CreateManualCardToken: {
          amount,
          card: VAULT_CARD,
          threeDsSessionId,
          surchargeRequirement,
        },
      });

      if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

      cardPaymentSource = toCardPaymentSource(response.ManualCardToken);

      if (currentUser) {
        try {
          const customerIdToken = await getCurrentUserToken();
          if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

          if (config.isB2B && b2bCardUsageScope) {
            const { cardError, response } = await vaultCreatePaymentSource(getVaultCard(), customerIdToken, {
              CreateSavedPaymentSource: {
                sourceMethod: {
                  CardMethod: VAULT_CARD,
                },
                usageScope: b2bCardUsageScope,
              },
            });

            if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

            cardPaymentSource = toSavedCardPaymentSource(
              response.paymentSourceId,
              response.sourceMethod.CardMethodResp,
            );
          } else {
            const { cardError, response } = await vaultCreatePaymentSource(getVaultCard(), customerIdToken, {
              CreateSavedPaymentSource: {
                sourceMethod: {
                  CardMethod: VAULT_CARD,
                },
              },
            });

            if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

            cardPaymentSource = toSavedCardPaymentSource(
              response.paymentSourceId,
              response.sourceMethod.CardMethodResp,
            );
          }
        } catch (error) {
          // fail to save card silently for pay card
          console.error(error);
        }
      }

      return {
        paymentTokenId: response.ManualCardToken.paymentTokenId,
        paymentMethodType: 'Card',
        cardPaymentSource,
      };
    } else if (selectedPaymentSource === SelectedPaymentSource.applePay && applePayPaymentSource) {
      return await onApplePaySubmit(applePayPaymentSource);
    } else if (selectedPaymentSource === SelectedPaymentSource.googlePay && googlePayPaymentSource) {
      return await onGooglePaySubmit(googlePayPaymentSource);
    }

    throw Error(PAYMENT_TOKEN_ERROR_MESSAGE);
  }, [
    b2bCardUsageScope,
    selectedCardPaymentSource,
    config,
    currentUser,
    params,
    selectedPaymentSource,
    getVaultCard,
    getVaultCvc,
    applePayPaymentSource,
    googlePayPaymentSource,
    onApplePaySubmit,
    onGooglePaySubmit,
    onCardinalDdcSubmit,
    onCardTypeErrorCheck,
    surchargeQuote,
  ]);

  return onPayCardSubmit;
};

export const useSubmitVerificationCodes = () => {
  const { config, params, customerId, emailVerification, phoneVerification } = useSelector((state: ReduxState) => ({
    config: state.config,
    params: state.params,
    customerId: state.customerId,
    emailVerification: state.emailVerification,
    phoneVerification: state.phoneVerification,
  }));

  const onSubmitVerificationCodes = useCallback(
    async (emailCode: string, mobileCode: string): Promise<{ customerError?: string; customToken?: string }> => {
      if (!config || !params) throw Error(STATE_ERROR_MESSAGE);

      const { error: verifyCustomerError, customToken } = await verifyCustomer(config.apiBaseUri, params.publicKey, {
        customerId,
        emailVerification,
        phoneVerification,
        emailVerificationCode: emailVerification ? emailCode : undefined,
        phoneVerificationCode: mobileCode,
      });

      if (!customToken) return { customerError: verifyCustomerError };

      const userCredential = await fbSignInWithCustomToken(config.tenantId, customToken);
      if (!userCredential) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      return { customToken };
    },
    [config, params, customerId, emailVerification, phoneVerification],
  );

  return onSubmitVerificationCodes;
};

export const usePayPlanEligibilityCheck = () => {
  const { params, selectedPayPlanVariant } = useSelector((state: ReduxState) => ({
    params: state.params,
    selectedPayPlanVariant: state.selectedPayPlanVariant,
  }));

  const dispatch = useDispatch();

  const onPayPlanEligibilityCheck = useCallback(async () => {
    if (!params || !selectedPayPlanVariant) throw Error(STATE_ERROR_MESSAGE);

    const customerIdToken = await getCurrentUserToken();
    if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

    const payPlanEligibility = await createPayPlanOffer(customerIdToken, {
      amount: {
        minorCurrencyUnits: params.payplanAmount,
        currency: params.currency,
      },
      initialPayment: selectedPayPlanVariant.initialPayment,
      payPlanVariant: selectedPayPlanVariant.payPlanVariant,
    });

    dispatch(setPayPlanEligibility(payPlanEligibility));

    const isCounterOffer = payPlanEligibility.offerDetails.isCounterOffer;
    dispatch(setCheckoutStep(isCounterOffer ? Step.CounterOffer : Step.PlanReview));
  }, [params, selectedPayPlanVariant, dispatch]);

  return onPayPlanEligibilityCheck;
};

export const useCreatePayPlanSubmit = () => {
  const {
    config,
    selectedCardPaymentSource,
    payPlanEligibility,
    b2bCardUsageScope,
    selectedPaymentSource,
    surchargeQuote,
    getVaultCard,
    getVaultCvc,
  } = useSelector((state: ReduxState) => ({
    config: state.config,
    selectedCardPaymentSource: state.selectedCardPaymentSource,
    customerId: state.customerId,
    payPlanEligibility: state.payPlanEligibility,
    b2bCardUsageScope: state.b2bCardUsageScope,
    selectedPaymentSource: state.selectedPaymentSource,
    surchargeQuote: state.surchargeQuote,
    getVaultCard: state.getVaultCard,
    getVaultCvc: state.getVaultCvc,
  }));

  const onCardinalDdcSubmit = useCardinalDdcSubmit();
  const onCardTypeErrorCheck = useCardTypeErrorCheck();

  const onCreatePayPlanSubmit = useCallback(
    async (
      termsAndConditionsAccepted: boolean,
    ): Promise<{
      paymentTokenId: string | undefined;
      cardPaymentSource?: SavedCardPaymentSource;
    }> => {
      const onCreatePaymentSource = async (payload: AnyCreatePaymentSourceRequest) => {
        if (!getVaultCard) throw Error(STATE_ERROR_MESSAGE);

        const cardTypeError = onCardTypeErrorCheck(getVaultCard().fields.cardNumber?.state.cardType);
        if (cardTypeError) throw Error(cardTypeError);

        const customerIdToken = await getCurrentUserToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        const { cardError, response } = await vaultCreatePaymentSource(getVaultCard(), customerIdToken, payload);

        if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

        return response;
      };

      const onCreateConsumerPaymentSource = async () => {
        const response = await onCreatePaymentSource({
          CreateSavedPaymentSource: {
            sourceMethod: {
              CardMethod: VAULT_CARD,
            },
          },
        });

        return response;
      };

      const onCreateOrganisationPaymentSource = async () => {
        if (!b2bCardUsageScope) throw Error(STATE_ERROR_MESSAGE);

        const response = await onCreatePaymentSource({
          CreateSavedPaymentSource: {
            sourceMethod: {
              CardMethod: VAULT_CARD,
            },
            usageScope: b2bCardUsageScope,
          },
        });

        return response;
      };

      const onCreatePayPlan = async (vault: Vault, cardPaymentSource: SavedCardPaymentSource) => {
        if (!payPlanEligibility) throw Error(STATE_ERROR_MESSAGE);

        const customerIdToken = await getCurrentUserToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        const threeDsSessionId = await onCardinalDdcSubmit(cardPaymentSource.cardBin);
        const surchargeRequirement: SurchargeRequirement | null = surchargeQuote
          ? { SurchargeQuoted: { surchargeQuote } }
          : null;

        const { response, cardError } = await vaultCreatePayPlan(vault, customerIdToken, {
          termsAndConditionsAccepted,
          offerDetails: payPlanEligibility.offerDetails,
          offerVerificationToken: payPlanEligibility.offerVerificationToken,
          paymentSource: {
            Card: {
              paymentSourceId: cardPaymentSource.cardPaymentSourceId,
              cvc: VAULT_CARD.cvc,
            },
          },
          threeDsSessionId,
          surchargeRequirement,
        });

        if (cardError || !response) throw Error(cardError?.formError || CARD_ERROR_MESSAGE);

        return {
          paymentTokenId: response.paymentTokenId,
          cardPaymentSource,
        };
      };

      const onNewCard = async () => {
        if (!config || !getVaultCard) throw Error(STATE_ERROR_MESSAGE);

        const response = config.isB2B
          ? await onCreateOrganisationPaymentSource()
          : await onCreateConsumerPaymentSource();

        return onCreatePayPlan(
          getVaultCard(),
          toSavedCardPaymentSource(response.paymentSourceId, response.sourceMethod.CardMethodResp),
        );
      };

      const onSavedCard = async () => {
        if (!getVaultCvc || !selectedCardPaymentSource) throw Error(STATE_ERROR_MESSAGE);

        return onCreatePayPlan(getVaultCvc(), selectedCardPaymentSource);
      };

      if (selectedPaymentSource === SelectedPaymentSource.newCard) {
        return onNewCard();
      } else if (selectedPaymentSource === SelectedPaymentSource.savedCard) {
        return onSavedCard();
      }

      throw Error(STATE_ERROR_MESSAGE);
    },
    [
      config,
      payPlanEligibility,
      selectedPaymentSource,
      b2bCardUsageScope,
      selectedCardPaymentSource,
      surchargeQuote,
      getVaultCard,
      getVaultCvc,
      onCardinalDdcSubmit,
      onCardTypeErrorCheck,
    ],
  );

  return onCreatePayPlanSubmit;
};

export const useShowB2bSaveCard = () => {
  const { config, currentUser, customToken } = useSelector((state: ReduxState) => ({
    config: state.config,
    currentUser: state.currentUser,
    customToken: state.customToken,
  }));

  const showB2bSaveCard = useMemo(
    () => !!config?.isB2B && config.displayCustomerSavedPaymentMethodsInCheckout && Boolean(currentUser || customToken),
    [config, currentUser, customToken],
  );

  return showB2bSaveCard;
};

export const usePaymentTotal = () => {
  const { params, paymentType, surchargeQuote, payPlanEligibility, selectedPayPlanVariant } = useSelector(
    (state: ReduxState) => ({
      params: state.params,
      paymentType: state.paymentType,
      surchargeQuote: state.surchargeQuote,
      payPlanEligibility: state.payPlanEligibility,
      selectedPayPlanVariant: state.selectedPayPlanVariant,
    }),
  );

  const initialPayment = useMemo(() => {
    if (paymentType !== 'payplan') return null;

    return (
      payPlanEligibility?.offerDetails.payPlanParameters?.initialPayment ??
      selectedPayPlanVariant?.initialPayment ??
      null
    );
  }, [paymentType, payPlanEligibility, selectedPayPlanVariant]);

  const { surcharge, total, initialPaymentTotal } = useMemo(() => {
    const {
      paycardAmount = 0,
      paycardSurchargeAmount = 0,
      payplanAmount = 0,
      payplanSurchargeAmount = 0,
    } = params ?? {};

    const subtotalAmount = paymentType === 'paycard' ? paycardAmount : payplanAmount;
    const subtotalSurcharge = paymentType === 'paycard' ? paycardSurchargeAmount : payplanSurchargeAmount;
    const surchargeQuoteAmount = surchargeQuote?.surchargeAmount ?? 0;

    return {
      surcharge: subtotalSurcharge + surchargeQuoteAmount,
      total: subtotalAmount + surchargeQuoteAmount,
      initialPaymentTotal: initialPayment !== null ? initialPayment + surchargeQuoteAmount : null,
    };
  }, [params, paymentType, surchargeQuote, initialPayment]);

  const subtotal = useMemo(() => {
    return numeral(total).subtract(surcharge).value() ?? 0;
  }, [total, surcharge]);

  const paymentTotal = useMemo(() => {
    return { subtotal, surcharge, total, initialPaymentTotal };
  }, [subtotal, surcharge, total, initialPaymentTotal]);

  return paymentTotal;
};
