import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--lp-colors-neutral-white));
  opacity: 0.9;
  z-index: 9999;
`;
