import { Alert, AlertProps } from 'components/Alert';
import BackBtn from 'components/BackBtn';
import { useFormik } from 'formik';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Heading, Link, Text, TextField } from '@limepayments/cosmic';

const useTimer = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (!timer) return;

    const timeout = setTimeout(() => setTimer(timer - 1), 1000);

    return () => clearTimeout(timeout);
  }, [timer]);

  return useMemo(() => ({ timer, startTimer: setTimer }), [timer]);
};

type FormValues = {
  phoneCode: string;
  emailCode: string;
};

export type VerifyStepProps = {
  contactDetails: { email: string; phone: string };
  onBack?(): void;
  onSubmit?(values: FormValues): Promise<AlertProps | void>;
  onResendCodes?(): Promise<AlertProps | void>;
};

export const VerifyStep = ({ contactDetails, onBack, onSubmit, onResendCodes }: VerifyStepProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const { email, phone } = contactDetails;

  const text = useMemo(() => {
    if (phone && email) return `Please enter the verification codes sent to ***${phone.slice(-3)} and ${email}.`;
    if (phone) return `Please enter the verification codes sent to ***${phone.slice(-3)}.`;
    if (email) return `Please enter the verification codes sent to ${email}.`;
  }, [email, phone]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      setAlert(null);
      setIsLoading(true);

      const alert = await onSubmit?.(values);

      setAlert(alert ?? null);
      setIsLoading(false);
    },
    [onSubmit],
  );

  const form = useFormik<FormValues>({
    initialValues: {
      phoneCode: '',
      emailCode: '',
    },
    validationSchema: Yup.object({
      phoneCode: phone
        ? Yup.string()
            .required('Mobile code is required')
            .matches(/^\d{6}$/, 'Mobile code must be 6 digits')
        : Yup.string().notRequired(),
      emailCode: email
        ? Yup.string()
            .required('Email code is required')
            .matches(/^\d{6}$/, 'Email code must be 6 digits')
        : Yup.string().notRequired(),
    }),
    onSubmit: handleSubmit,
  });

  const { touched, values, errors, handleChange, handleBlur, setFieldValue } = form;

  const { timer, startTimer } = useTimer();

  const handleResendCodes = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      setFieldValue('phoneCode', '', false);
      setFieldValue('emailCode', '', false);
      setAlert(null);
      setResending(true);

      const alert = await onResendCodes?.();

      setAlert(alert ?? null);
      setResending(false);
      startTimer(6);
    },
    [onResendCodes, setFieldValue, startTimer],
  );

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        fontFamily: 'var(--lp-fonts-body-2)',
        color: 'rgb(var(--lp-colors-neutral-700))',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <BackBtn onClick={onBack} />
        <Heading tagName="h2" variant="xs">
          Verification codes
        </Heading>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
            fontSize: 14,
            color: 'rgb(var(--lp-colors-neutral-600))',
            backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
            border: '1px solid rgb(var(--lp-colors-neutral-400))',
            borderRadius: 4,
          }}
        >
          <Text variant="body-3">{text}</Text>
          {!!phone && (
            <TextField
              testId="phoneCode"
              name="phoneCode"
              type="tel"
              inputMode="numeric"
              label="Mobile code"
              fullWidth
              value={values.phoneCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.phoneCode ? errors.phoneCode : ''}
              maxLength={6}
              minLength={6}
              required
            />
          )}
          {!!email && (
            <TextField
              testId="emailCode"
              name="emailCode"
              type="tel"
              inputMode="numeric"
              label="Email code"
              fullWidth
              value={values.emailCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.emailCode ? errors.emailCode : ''}
              maxLength={6}
              minLength={6}
              required
            />
          )}
          <div css={{ zIndex: 1 }}>
            <Text variant="body-3">
              {timer ? (
                <>Codes resent, you can retry in {timer}s</>
              ) : (
                <>
                  Didn't receive codes?{' '}
                  <span css={{ color: 'rgb(var(--lp-colors-medium-blue-600))' }}>
                    {resending ? `Resending...` : <Link onClick={handleResendCodes}>Resend codes</Link>}
                  </span>
                </>
              )}
            </Text>
          </div>
          {!!alert && <Alert {...alert} />}
          <Button
            testId="submitBtn"
            size="large"
            variant="primary"
            className="lp-w-full"
            onClick={form.submitForm}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Verify codes
          </Button>
        </div>
      </div>
    </div>
  );
};
