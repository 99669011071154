import BackBtn from 'components/BackBtn';
import { PayInFull } from 'components/PayPlanSelection/PayInFull';
import { PayPlanVariant } from 'components/PayPlanSelection/PayPlanVariant';
import { Step } from 'lib/types';
import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutStep } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

import { Button, Heading, Text } from '@limepayments/cosmic';

import { PAY_IN_FULL_DESCRIPTION, PAY_PLAN_DESCRIPTION } from './Constants';
import * as s from './CounterOffer.styles';
import { CounterOfferType } from './types';

const CounterOffer = ({ payInFullAmount, isPayInFull, payPlanParameters }: CounterOfferType) => {
  const { params, surchargeQuote } = useSelector((state: ReduxState) => ({
    params: state.params,
    surchargeQuote: state.surchargeQuote,
  }));

  const dispatch = useDispatch();

  const handleClickCounterOffer = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setCheckoutStep(Step.PlanReview));
  };

  return (
    <s.Wrapper>
      <BackBtn onClick={() => dispatch(setCheckoutStep(Step.PaymentSelection))} />
      <Heading variant="xs" className="heading" testId="counterOfferHeading">
        Confirm the update to your payment
      </Heading>
      <Text className="description" variant="caption" testId="counterOfferDescription">
        {isPayInFull ? PAY_IN_FULL_DESCRIPTION : PAY_PLAN_DESCRIPTION}
      </Text>
      {isPayInFull && (
        <PayInFull
          amount={params?.payplanAmount ?? 0}
          surcharge={surchargeQuote?.surchargeAmount ?? 0}
          isActive
          showSelection={false}
        />
      )}
      {!isPayInFull && !!payPlanParameters && (
        <PayPlanVariant
          payPlanParameters={payPlanParameters}
          surcharge={surchargeQuote?.surchargeAmount ?? 0}
          isActive
          isCounterOffer
        />
      )}
      <Button
        onClick={handleClickCounterOffer}
        variant="primary"
        size="large"
        testId="confirmPlanBtn"
        className="lp-w-full confirmPlanBtn"
      >
        Confirm payment
      </Button>
    </s.Wrapper>
  );
};

export default CounterOffer;
