import { createOrGetCustomer, sendCustomerVerification } from 'app/api';
import { authenticateCustomerOtp } from 'app/api.april/auth/authenticateCustomerOtp';
import { triggerCustomerOtp } from 'app/api.april/auth/triggerCustomerOtp';
import { fbSignInWithCustomToken } from 'app/identity';
import { CUSTOMER_TOKEN_ERROR_MESSAGE, STATE_ERROR_MESSAGE, getErrorMessage } from 'lib/errorMessages';
import { useSubmitVerificationCodes } from 'lib/hooks';
import { Step } from 'lib/types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  setB2bProspectToken,
  setCheckoutStep,
  setCustomToken,
  setCustomerId,
  setVerification,
} from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

import { ContactDetailsStep, ContactDetailsStepProps } from './ContactDetailsStep';
import { VerifyStep, VerifyStepProps } from './VerifyStep';

export const OtpStep = () => {
  const [contactDetails, setContactDetails] = useState<VerifyStepProps['contactDetails']>({ email: '', phone: '' });
  const [step, setStep] = useState<'ContactDetails' | 'Verify'>('ContactDetails');

  const dispatch = useDispatch();
  const onSubmitVerificationCodes = useSubmitVerificationCodes();

  const { params, config, customerId } = useSelector((state: ReduxState) => ({
    params: state.params,
    config: state.config,
    customerId: state.customerId,
  }));

  const handleGetCustomerId = useCallback(
    async (emailAddress: string, phoneNumber: string) => {
      if (customerId) return customerId;

      if (!config || !params) throw Error(STATE_ERROR_MESSAGE);

      const _customerId = await createOrGetCustomer(config.apiBaseUri, params.publicKey, { emailAddress, phoneNumber });
      dispatch(setCustomerId(_customerId));

      return _customerId;
    },
    [config, customerId, dispatch, params],
  );

  const handleSendCustomerVerification = useCallback(
    async (emailAddress: string, phoneNumber: string) => {
      if (!config || !params) throw Error(STATE_ERROR_MESSAGE);

      const customerId = await handleGetCustomerId(emailAddress, phoneNumber);

      const { emailVerification, phoneVerification } = await sendCustomerVerification(
        config.apiBaseUri,
        params.publicKey,
        customerId,
        {
          emailAddress,
          phoneNumber,
          mode: 'Phone',
        },
      );

      dispatch(setVerification({ emailVerification, phoneVerification }));
    },
    [config, dispatch, handleGetCustomerId, params],
  );

  const handleContactDetailsSubmit = useCallback<NonNullable<ContactDetailsStepProps['onSubmit']>>(
    async ({ email, phone }) => {
      setContactDetails({ email, phone });

      try {
        if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

        if (config.isB2B) {
          // B2B
          await triggerCustomerOtp(params.publicKey, {
            TriggerB2bProspectOtp: {
              emailAddress: email,
            },
          });
        } else {
          // B2C
          await handleSendCustomerVerification(email, phone);
        }

        setStep('Verify');
      } catch (error) {
        const message = getErrorMessage(error);
        return { status: 'error', message };
      }
    },
    [config, handleSendCustomerVerification, params],
  );

  const handleResendCodesSubmit = useCallback<NonNullable<VerifyStepProps['onResendCodes']>>(async () => {
    try {
      if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

      const { email, phone } = contactDetails;

      if (config.isB2B) {
        // B2B
        await triggerCustomerOtp(params.publicKey, {
          TriggerB2bProspectOtp: {
            emailAddress: email,
          },
        });
      } else {
        // B2C
        await handleSendCustomerVerification(email, phone);
      }

      return { status: 'success', message: 'Codes resent!' };
    } catch (error) {
      const message = getErrorMessage(error);
      return { status: 'error', message };
    }
  }, [config, contactDetails, handleSendCustomerVerification, params]);

  const handleVerifySubmit = useCallback<NonNullable<VerifyStepProps['onSubmit']>>(
    async ({ emailCode, phoneCode }) => {
      try {
        if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

        if (config.isB2B) {
          // B2B
          const response = await authenticateCustomerOtp(params.publicKey, {
            AuthenticateB2bProspectOtp: {
              emailAddress: contactDetails.email,
              emailVerificationCode: emailCode,
            },
          });

          if ('B2bProspectOtpResultNew' in response) {
            dispatch(setB2bProspectToken(response.B2bProspectOtpResultNew.b2bProspectToken));
            dispatch(setCheckoutStep(Step.Kyb));
          }
          if ('B2bProspectOtpResultExisting' in response) {
            const { customToken } = response.B2bProspectOtpResultExisting;

            const userCredential = await fbSignInWithCustomToken(config.tenantId, customToken);
            if (!userCredential) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

            dispatch(setCustomToken(customToken));
            dispatch(setCheckoutStep(Step.Kyc));
          }
        } else {
          // B2C
          const { customerError, customToken } = await onSubmitVerificationCodes(emailCode, phoneCode);

          if (customerError || !customToken) throw Error(customerError || CUSTOMER_TOKEN_ERROR_MESSAGE);

          dispatch(setCustomToken(customToken));
          dispatch(setCheckoutStep(Step.Kyc));
        }
      } catch (error) {
        const message = getErrorMessage(error);
        return { status: 'error', message };
      }
    },
    [config, contactDetails, dispatch, onSubmitVerificationCodes, params],
  );

  return (
    <>
      {step === 'ContactDetails' && (
        <ContactDetailsStep
          onBack={() => dispatch(setCheckoutStep(Step.PaymentSelection))}
          onSubmit={handleContactDetailsSubmit}
        />
      )}
      {step === 'Verify' && (
        <VerifyStep
          contactDetails={contactDetails}
          onBack={() => setStep('ContactDetails')}
          onSubmit={handleVerifySubmit}
          onResendCodes={handleResendCodesSubmit}
        />
      )}
    </>
  );
};
