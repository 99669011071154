import BackBtn from 'components/BackBtn';
import ErrorMessage from 'components/ErrorMessage';
import { PayInFull } from 'components/PayPlanSelection/PayInFull';
import { PayPlanVariant } from 'components/PayPlanSelection/PayPlanVariant';
import PaymentSource from 'components/PaymentSource';
import { SpinnerWrapper } from 'lib/commonStyles';
import { STATE_ERROR_MESSAGE, SURCHARGE_REQUIRED_ERROR_MESSAGE } from 'lib/errorMessages';
import {
  useCreatePayPlanSubmit,
  useError,
  useQuerySavedCards,
  useSendMessage,
  useShowB2bSaveCard,
  useSubmitMessageCallback,
} from 'lib/hooks';
import { EventName, toEventMessage, toPaymentTokenMessage } from 'lib/messages';
import { SelectedPaymentSource, Step } from 'lib/types';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCheckoutStep, setSelectedPaymentSource, setSurchargeQuote } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Heading, Spinner } from '@limepayments/cosmic';

import Terms from './Terms';
import { PlanReviewType } from './types';

/**
 * the btn is disabled if one of following requirements is met:
 * - payPlanError is not empty
 * - isTermsAgreed is false
 * - both isCardReady and isCvcReady are false, which is determined if vgs form and saved card form are ready
 * - loading is true
 */

const PlanReview = ({ payPlanParameters, payPlanError, isCounterOffer, isPayInFullCounterOffer }: PlanReviewType) => {
  const [errorMessage, setErrorMessage] = useState<string>(() => payPlanError);
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCardReady, setIsCardReady] = useState<boolean>(false);
  const [isCvcReady, setIsCvcReady] = useState<boolean>(false);
  const [b2bSaveCardError, setB2bSaveCardError] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(true);
  const {
    params,
    config,
    payPlanEligibility,
    cardPaymentSources,
    b2bCardUsageScope,
    selectedPaymentSource,
    surchargeQuote,
  } = useSelector((state: ReduxState) => ({
    params: state.params,
    config: state.config,
    payPlanEligibility: state.payPlanEligibility,
    cardPaymentSources: state.cardPaymentSources,
    b2bCardUsageScope: state.b2bCardUsageScope,
    selectedPaymentSource: state.selectedPaymentSource,
    surchargeQuote: state.surchargeQuote,
  }));

  const dispatch = useDispatch();
  const showB2bSaveCard = useShowB2bSaveCard();
  const onCreatePayPlanSubmit = useCreatePayPlanSubmit();
  const onError = useError();
  const sendMessage = useSendMessage();
  const onQuerySavedCards = useQuerySavedCards();

  const handleBack = useCallback(() => {
    dispatch(setSelectedPaymentSource(null));
    dispatch(setSurchargeQuote(null));
    dispatch(setCheckoutStep(Step.PaymentSelection));
  }, [dispatch]);

  const handleChangeTerms = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      checked && sendMessage(toEventMessage(EventName.PaymentPlanTermsAccepted, params?.elementId || ''));
      setIsTermsAgreed(checked);
    },
    [params, sendMessage],
  );

  const handlePayPlanSubmit = useCallback(async () => {
    try {
      if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

      const offerPayPlan = payPlanEligibility?.offerDetails.payPlanParameters || null;
      if (!offerPayPlan) throw Error("Sorry, we weren't able to determine your eligibility");

      if (!selectedPaymentSource) {
        throw Error('Please select a payment source');
      }
      if (selectedPaymentSource === SelectedPaymentSource.newCard && !isCardReady) {
        throw Error('Please enter valid credit card details');
      }
      if (selectedPaymentSource === SelectedPaymentSource.savedCard && !isCvcReady) {
        throw Error('Please enter valid CVC details');
      }
      if (loading) throw Error('The request is being sent');
      if (!isTermsAgreed) throw Error('Terms not accepted');
      if (showB2bSaveCard && SelectedPaymentSource.newCard === selectedPaymentSource) {
        if (!b2bCardUsageScope) {
          setB2bSaveCardError(true);
          return;
        }
        setB2bSaveCardError(false);
      }
      if (config.surchargeQuoteRequired && !surchargeQuote) {
        throw Error(SURCHARGE_REQUIRED_ERROR_MESSAGE);
      }

      setLoading(true);
      const { paymentTokenId, cardPaymentSource } = await onCreatePayPlanSubmit(isTermsAgreed);
      paymentTokenId &&
        sendMessage(
          toPaymentTokenMessage(
            paymentTokenId,
            {
              amount: params.payplanAmount,
              currency: params.currency,
              paymentType: 'payplan',
              payType: 'PayPlan',
              paymentMethodType: 'Card',
              paymentSource: cardPaymentSource ? { cardPaymentSource } : undefined,
            },
            params.elementId,
          ),
        );
    } catch (e) {
      setErrorMessage(onError(e));
    } finally {
      setLoading(false);
    }
  }, [
    config,
    b2bCardUsageScope,
    payPlanEligibility,
    isCvcReady,
    isTermsAgreed,
    isCardReady,
    loading,
    onCreatePayPlanSubmit,
    onError,
    params,
    selectedPaymentSource,
    sendMessage,
    showB2bSaveCard,
    surchargeQuote,
  ]);

  useSubmitMessageCallback(handlePayPlanSubmit);

  useEffect(() => {
    const paymentSourceEnabled =
      (!config?.surchargeQuoteRequired || !!surchargeQuote) &&
      ((selectedPaymentSource === SelectedPaymentSource.newCard && isCardReady) ||
        (selectedPaymentSource === SelectedPaymentSource.savedCard && isCvcReady));
    setDisabledSubmitBtn(!!payPlanError || !isTermsAgreed || !paymentSourceEnabled || loading);
  }, [config, isCvcReady, isTermsAgreed, isCardReady, loading, payPlanError, selectedPaymentSource, surchargeQuote]);

  useEffect(() => {
    const fetchSavedCards = async () => {
      try {
        setLoading(true);
        await onQuerySavedCards();
      } catch (e) {
        onError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedCards();
  }, [onError, onQuerySavedCards]);

  useEffect(() => {
    payPlanError && setErrorMessage(payPlanError);
  }, [payPlanError]);

  if (!params) {
    return <></>;
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: 16, position: 'relative' }}>
      {loading && (
        <SpinnerWrapper>
          <Spinner variant="simple" isVisible />
        </SpinnerWrapper>
      )}
      <ErrorMessage messageBody={errorMessage} />
      <BackBtn onClick={handleBack} />
      <Heading css={{ color: 'rgb(var(--lp-colors-neutral-600))' }} variant="xxs" testId="planReviewHeading">
        Confirm your plan
      </Heading>
      {isPayInFullCounterOffer && (
        <PayInFull
          amount={params.payplanAmount}
          surcharge={surchargeQuote?.surchargeAmount ?? 0}
          isActive
          showSelection={false}
        />
      )}
      {!isPayInFullCounterOffer && (
        <PayPlanVariant
          isActive
          payPlanParameters={payPlanParameters}
          surcharge={surchargeQuote?.surchargeAmount ?? 0}
          isCounterOffer={isCounterOffer}
        />
      )}
      <Terms isTermsAgreed={isTermsAgreed} handleChangeTerms={handleChangeTerms} />
      <PaymentSource
        isActive={true}
        savedCards={cardPaymentSources}
        applePayPaymentSource={null}
        googlePayPaymentSource={null}
        directDebitForm={null}
        payToForm={null}
        showB2bSaveCard={showB2bSaveCard}
        b2bSaveCardError={b2bSaveCardError}
        setIsCardReady={setIsCardReady}
        setIsCvcReady={setIsCvcReady}
        submitDisabled={disabledSubmitBtn}
        onChange={(paymentSource) => dispatch(setSelectedPaymentSource(paymentSource))}
        onSubmit={params.showPayPlanSubmit ? handlePayPlanSubmit : undefined}
      />
    </div>
  );
};

export default PlanReview;
