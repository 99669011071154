import { SavedCardPaymentSource } from 'lib/types';

const getCard = (savedCards: Array<SavedCardPaymentSource> | undefined): SavedCardPaymentSource | null => {
  if (!savedCards || savedCards.length === 0) {
    return null;
  }

  return savedCards[0];
};

export default getCard;
