import PayPlanSelection, { PayPlanSelectionProps } from 'components/PayPlanSelection';
import { Step } from 'lib/types';
import { useCallback, useMemo } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutStep } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Heading } from '@limepayments/cosmic';

import Disclaimer from './Disclaimer';
import * as s from './styles';

export interface PayPlanSelectionStepType {
  onChange?: PayPlanSelectionProps['onChange'];
}

const PayPlanSelectionStep = ({ onChange }: PayPlanSelectionStepType) => {
  const { currentUser, selectedPayPlanVariant } = useSelector((state: ReduxState) => ({
    currentUser: state.currentUser,
    selectedPayPlanVariant: state.selectedPayPlanVariant,
  }));

  const isVerified = useMemo(() => Boolean(currentUser?.isVerified), [currentUser]);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    dispatch(setCheckoutStep(isVerified ? Step.Kyc : Step.Otp));
  }, [isVerified, dispatch]);

  return (
    <s.Wrapper>
      <Heading css={{ marginBottom: 8, color: 'rgb(var(--lp-colors-neutral-600))' }} tagName="h3" variant="xxs">
        Select your payment plan
      </Heading>
      <PayPlanSelection onChange={onChange} />
      <Disclaimer />
      <Button
        onClick={handleSubmit}
        size="large"
        variant="primary"
        className="lp-w-full submitBtn"
        disabled={!selectedPayPlanVariant}
        testId="submitBtn"
      >
        Continue
      </Button>
    </s.Wrapper>
  );
};

export default PayPlanSelectionStep;
