import { PayToGetAgreementStateResponse, payToGetAgreementState, payToSendAgreement } from 'app/api.april';
import payTo from 'assets/pay-to.png';
import { Alert } from 'components/Alert';
import BackBtn from 'components/BackBtn';
import { SpinnerIcon } from 'components/PaymentSource/PayToForm';
import { STATE_ERROR_MESSAGE, getErrorMessage } from 'lib/errorMessages';
import { useError, useInterval, usePayToSubmit, useSendMessage } from 'lib/hooks';
import { toPaymentTokenMessage } from 'lib/messages';
import { Step } from 'lib/types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutStep, setPayToToken } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Heading, Icon, Link, Text } from '@limepayments/cosmic';

const POLL_INTERVAL = 5000; // 5s

export const PayToStep = () => {
  const [status, setStatus] = useState<PayToGetAgreementStateResponse['status'] | null>(null);
  const [pollInterval, setPollInterval] = useState<number | null>(POLL_INTERVAL);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState('');

  const { params, payToAgreementRequest, payToToken } = useSelector((state: ReduxState) => ({
    params: state.params,
    payToAgreementRequest: state.payToAgreementRequest,
    payToToken: state.payToToken,
  }));

  const dispatch = useDispatch();
  const sendMessage = useSendMessage();
  const onPayToSubmit = usePayToSubmit();
  const onError = useError();

  const loading = useMemo(() => !error && ['Pending', 'Created', ''].includes(status ?? ''), [status, error]);

  const onPollAgreementState = useCallback(async () => {
    setPollInterval(null);
    setError('');

    try {
      if (!params || !payToToken) throw Error(STATE_ERROR_MESSAGE);

      const { status } = await payToGetAgreementState(params.publicKey, payToToken);

      setStatus(status);

      if (!resending && ['Created', 'Pending'].includes(status)) {
        setPollInterval(POLL_INTERVAL);
      }
      if (['Active'].includes(status)) {
        const { paymentTokenId } = await onPayToSubmit(payToToken);

        paymentTokenId &&
          sendMessage(
            toPaymentTokenMessage(
              paymentTokenId,
              {
                amount: params.paycardAmount,
                currency: params.currency,
                paymentType: 'paycard',
                payType: 'PayInFull',
                paymentMethodType: 'PayTo',
              },
              params.elementId,
            ),
          );
      }
    } catch (error) {
      setError(onError(error));
    }
  }, [params, payToToken, resending, onPayToSubmit, sendMessage, onError]);

  useInterval(onPollAgreementState, pollInterval);

  const handleResendClick = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      setPollInterval(null);
      setResending(true);
      setStatus(null);
      setError('');

      try {
        if (!params || !payToAgreementRequest) throw Error(STATE_ERROR_MESSAGE);

        const { token } = await payToSendAgreement(params.publicKey, payToAgreementRequest);

        dispatch(setPayToToken(token));
        setPollInterval(POLL_INTERVAL);
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setResending(false);
      }
    },
    [params, payToAgreementRequest, dispatch],
  );

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        fontFamily: 'var(--lp-fonts-body-2)',
        color: 'rgb(var(--lp-colors-neutral-700))',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <BackBtn onClick={() => dispatch(setCheckoutStep(Step.PaymentSelection))} />
        <div css={{ display: 'flex', gap: 8 }}>
          {loading && <SpinnerIcon />}
          <img css={{ maxWidth: 70 }} src={payTo} alt="PayTo" />
        </div>
        <Heading variant="xs">Authorise PayTo agreement from your banking app</Heading>
      </div>
      <div>
        <Text variant="body-3">1. Login to your online banking</Text>
        <Text variant="body-3">2. Review the PayTo agreement</Text>
        <Text variant="body-3">3. Authorise the agreement</Text>
        <Text variant="body-3">4. Return to this page</Text>
      </div>
      <div
        css={{
          display: 'flex',
          padding: '16px 8px',
          borderRadius: 4,
          color: 'rgb(var(--lp-colors-neutral-600))',
          backgroundColor: 'rgb(var(--lp-colors-neutral-50))',
          border: '1px solid rgb(var(--lp-colors-neutral-400))',
          boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.08)',
        }}
      >
        <Icon name="Clock" />
        <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, marginLeft: 8 }}>
          <div css={{ fontSize: 12, lineHeight: '16px' }}>The authorisation request will timeout in 15 minutes.</div>
        </div>
      </div>
      {status && !['Created', 'Pending', 'Active'].includes(status) && (
        <Alert status="error">
          <div css={{ fontSize: 14, fontWeight: 700, lineHeight: '20px', marginBottom: 4 }}>
            Authorisation request {status.toLowerCase()}
          </div>
          Resend request and authorise the PayTo agreement to complete the payment.
        </Alert>
      )}
      {!!error && <Alert status="error">{error}</Alert>}
      <div>
        <Text variant="body-3">
          Didn't receive the request?{' '}
          <span css={{ color: 'rgb(var(--lp-colors-medium-blue-600))' }}>
            {resending ? `Resending...` : <Link onClick={handleResendClick}>Resend request</Link>}
          </span>
        </Text>
      </div>
    </div>
  );
};
