import { BankAccountPaymentSource } from 'app/api.april';
import { Alert } from 'components/Alert';
import { FormikConfig, FormikProps, useFormik } from 'formik';
import { Currency } from 'lib/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reduxTypes';
import * as Yup from 'yup';

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Checkbox, CheckboxGroup, Link, TextField } from '@limepayments/cosmic';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
    color: 'rgb(var(--lp-colors-neutral-600))',
    backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
    borderRadius: 4,
  }),
  row: css({ display: 'flex', gap: 16 }),
  col: css({ flex: 1 }),
  checkboxWrapper: css({
    position: 'relative',
    zIndex: 1,
    '& label': {
      marginBottom: 0,
    },
  }),
  alertHeading: css({ fontSize: 14, fontWeight: 700, lineHeight: '20px', marginBottom: 4 }),
};

export type DirectDebitFormValues = {
  accountName: string;
  bsb: string;
  accountNumber: string;
  emailAddress: string;
  agreementAccepted: boolean;
};

export const toBankAccountPaymentSource = (formValues: DirectDebitFormValues): BankAccountPaymentSource => ({
  emailAddress: formValues.emailAddress,
  agreementAccepted: formValues.agreementAccepted,
  bankAccount: {
    accountName: formValues.accountName,
    identifier: {
      AuBankAccountIdentifier: {
        bsb: formValues.bsb,
        accountNumber: formValues.accountNumber,
      },
    },
    currency: Currency.AUD,
  },
});

export const useDirectDebitForm = ({ onSubmit }: { onSubmit: FormikConfig<DirectDebitFormValues>['onSubmit'] }) => {
  const { params } = useSelector((state: ReduxState) => {
    return {
      params: state.params,
    };
  });

  const form = useFormik<DirectDebitFormValues>({
    initialValues: {
      accountName: '',
      bsb: '',
      accountNumber: '',
      emailAddress: params?.email ?? '',
      agreementAccepted: false,
    },
    validationSchema: Yup.object({
      accountName: Yup.string().required('Account name is required'),
      bsb: Yup.string()
        .required('BSB is required')
        .matches(/^[0-9]*$/, 'BSB must be 6 digits')
        .min(6, 'BSB must be 6 digits')
        .max(6, 'BSB must be 6 digits'),
      accountNumber: Yup.string()
        .required('Account number is required')
        .matches(/^[0-9]*$/, 'Account number must be 6-9 digits')
        .min(6, 'Account number must be 6-9 digits')
        .max(9, 'Account number must be 6-9 digits'),
      emailAddress: Yup.string().email('Email address is invalid').required('Email address is required'),
      agreementAccepted: Yup.boolean().isTrue(),
    }),
    onSubmit,
    validateOnMount: true,
  });

  return form;
};

export type DirectDebitFormProps = {
  form: FormikProps<DirectDebitFormValues>;
};

export const DirectDebitForm = ({ form }: DirectDebitFormProps) => {
  const { config } = useSelector((state: ReduxState) => ({
    config: state.config,
  }));

  const { touched, values, errors, handleChange, handleBlur } = form;

  return (
    <div css={styles.wrapper} data-testid="directDebitForm">
      <TextField
        name="accountName"
        type="text"
        label="Account name"
        fullWidth
        required
        value={values.accountName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.accountName ? errors.accountName : ''}
      />
      <div css={styles.row}>
        <div css={styles.col}>
          <TextField
            name="bsb"
            type="text"
            inputMode="numeric"
            label="BSB"
            fullWidth
            required
            minLength={6}
            maxLength={6}
            value={values.bsb}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bsb ? errors.bsb : ''}
          />
        </div>
        <div css={styles.col}>
          <TextField
            name="accountNumber"
            type="text"
            inputMode="numeric"
            label="Account no."
            fullWidth
            required
            minLength={6}
            maxLength={9}
            value={values.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.accountNumber ? errors.accountNumber : ''}
          />
        </div>
      </div>
      <TextField
        name="emailAddress"
        type="email"
        label="Email address"
        fullWidth
        required
        value={values.emailAddress}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.emailAddress ? errors.emailAddress : ''}
      />
      <div css={styles.checkboxWrapper}>
        <CheckboxGroup>
          <Checkbox
            id="agreementAccepted"
            variant="body-3"
            label={
              <>
                I confirm that I have read and understood the{' '}
                <Link href={config?.tcUrl ?? ''} size="small" target="_blank">
                  Terms of Use
                </Link>{' '}
                of the payment services. I accept and electronically sign the Direct Debit Request and Direct Debit
                Request Service Agreement.
              </>
            }
            checked={values.agreementAccepted}
            onChange={handleChange}
          />
        </CheckboxGroup>
      </div>
      {!!form.submitCount && !!errors.agreementAccepted && (
        <Alert status="error">Please read and agree to the Direct Debit agreement</Alert>
      )}
      {(!!values.bsb || !!values.accountNumber) && (
        <Alert status="warning">
          <div css={styles.alertHeading}>Please check your bank details carefully</div>
          Direct debit may take a few business days to process. If your BSB or account number is incorrect or if your
          account has insufficient funds, your payment will fail and your order will not be processed.
        </Alert>
      )}
    </div>
  );
};

export default DirectDebitForm;
