import { Config } from 'app/apiTypes';
import { Params } from 'lib/toParams';

const checkPayPlanAmountEligibility = (params: Params | null, config: Config | null) => {
  if (!params || !config) {
    return {
      minPayPlanAmount: 1 << 16,
      maxPayPlanAmount: 0,
      minPayPlanAmountEligibility: false,
      maxPayPlanAmountEligibility: false,
      payPlanAmountEligibility: false,
    };
  }

  const { payplanAmount: amount } = params;
  const { minPayPlanAmount, maxPayPlanAmountUnconfirmedCustomers, maxPayPlanAmountConfirmedCustomers } = config;
  const maxPayPlanAmount = Math.max(maxPayPlanAmountUnconfirmedCustomers, maxPayPlanAmountConfirmedCustomers);
  const minPayPlanAmountEligibility = amount >= minPayPlanAmount;
  const maxPayPlanAmountEligibility = amount <= maxPayPlanAmount;
  const payPlanAmountEligibility = minPayPlanAmountEligibility && maxPayPlanAmountEligibility;

  return {
    minPayPlanAmount,
    maxPayPlanAmount,
    minPayPlanAmountEligibility,
    maxPayPlanAmountEligibility,
    payPlanAmountEligibility,
  };
};

export default checkPayPlanAmountEligibility;
