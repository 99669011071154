import { defaultHeaders, handleResponse } from 'app/api';
import { ErrorResponse } from 'app/apiTypes';
import { getAprilApiHost } from 'lib/env';
import { ApplePayPaymentSource, Currency, GooglePayPaymentSource, PayPlanVariant } from 'lib/types';
import { handleVaultSubmitError } from 'lib/vault';

import { Vault, handleVaultSubmitResponse } from '@april/lib-ui';

import { SurchargeQuote } from './types';

export type PaymentSurchargeRequest = {
  PaymentSurchargeRequest: {
    payment: {
      form: { PayInFull: {} } | { PayPlan: { variant: PayPlanVariant } };
      customerType: 'Consumer' | 'Organisation';
      amount: {
        amount: number;
        currency: Currency;
      };
      method:
        | { Card: { cardNumber: string } }
        | { SavedPaymentSource: { paymentSourceId: string } }
        | { ApplePay: { applePay: ApplePayPaymentSource } }
        | { GooglePay: { googlePay: GooglePayPaymentSource } }
        | { DirectDebit: {} }
        | { PayTo: {} };
    };
  };
};

export type PaymentSurchargeResponse = {
  PaymentSurchargeResponse: {
    surchargeQuote: SurchargeQuote;
  };
};

export const vaultCalculateSurcharge = async (
  vault: Vault,
  merchantId: string,
  token: string,
  payload: PaymentSurchargeRequest,
): Promise<PaymentSurchargeResponse> => {
  const response = await vault
    .submit({
      path: `/tokens/${merchantId}/surchargequote`,
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleVaultSubmitError)
    .then((res) =>
      handleVaultSubmitResponse<PaymentSurchargeResponse, ErrorResponse>(
        res,
        ({ message, detail }) => detail || message,
      ),
    );

  return response;
};

export const calculateSurcharge = async (
  vault: Vault | null,
  merchantId: string,
  token: string,
  payload: PaymentSurchargeRequest,
): Promise<PaymentSurchargeResponse> => {
  return vault
    ? vaultCalculateSurcharge(vault, merchantId, token, payload)
    : fetch(`${await getAprilApiHost()}/tokens/${merchantId}/surchargequote`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          ...defaultHeaders,
          Authorization: `Bearer ${token}`,
        }),
      }).then<PaymentSurchargeResponse>((res) => handleResponse(res, ({ message, detail }) => detail || message));
};
