import React, { MouseEvent } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon, Link } from '@limepayments/cosmic';

const BackBtn = ({ onClick }: { onClick?(): void }) => {
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClick?.();
  };

  return (
    <div css={{ display: 'flex', gap: 4, alignItems: 'center', fontSize: 14 }}>
      <Icon name="ArrowLeft" />
      <Link onClick={handleClick}>Back</Link>
    </div>
  );
};

export default BackBtn;
